import React from 'react';
import { serverFile} from '../../config/axios';

const TableAfectada = ({data=[], deleteObject,updateObject}) => {
    const cabecera = ["#", "MODULO", "PISO", "DESCRIPCION","AREA DIRECTA_m²","AREA INDIRECTA_m²","AREA_TOTAL_m²","USO",
    ,"MATERIAL PREDOMINANTE" ,"FOTO","Acciones"];


    function verificarFotos(objeto) {
        return (typeof objeto.foto_frente?.path === 'string' && objeto.foto_frente?.path.length > 0) || 
               (typeof objeto.foto_lado_derecho?.path === 'string' && objeto.foto_lado_derecho?.path.length > 0) || 
               (typeof objeto.foto_lado_izquierdo?.path === 'string' && objeto.foto_lado_izquierdo?.path.length > 0) || 
               (typeof objeto.foto_lado_fondo?.path === 'string' && objeto.foto_lado_fondo?.path.length > 0);
    }
    

    console.log(data) 
    return (
        <>
            <table className="table table-bordered table-condensed table-hover table-striped" id="dataTableInvitado">
                <thead>
                <tr>
                    { cabecera.map((item,i)=>(
                        <th key={i}>{item}</th>
                    ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((obj, key) => (
                
                            <tr key={key}>
                                <td key={`td_${key}_1`}>{key+1}</td>
                                <td key={`td_${key}_2`}>{obj.modulo}</td>
                                <td key={`td_${key}_3`}>{obj.piso}</td>
                                <td key={`td_${key}_3`}>{obj.descripcion}</td>
                                <td key={`td_${key}_3`}>{obj.directa}</td>
                                <td key={`td_${key}_3`}>{obj.indirecta}</td>
                                <td key={`td_${key}_3`}>{obj.total}</td>
                                <td key={`td_${key}_3`}>{obj.uso}</td>
                                <td key={`td_${key}_3`}>{obj.material_predominante}</td>

                                <td key={`td_${key}_3`} title='Ver archivo'>
                                    {  verificarFotos(obj) ? <a target='_blank' href={serverFile+obj?.fotovivienda?.path}>
                                        <i class="fa fa-picture-o" aria-hidden="true"></i>
                                    </a>: null}
                                    
                               
                                </td>

                               
                                <td key={`td_${key}_4`} className="acciones-1bot pull-center">
                                    <div className="btn-group">
                                    <button className="btn btn-xs btn-default mright-5" type="button">
                                            <i className="fa fa-edit fa-lg" onClick={() => updateObject(obj.id)} />
                                        </button>
                                        <button className="btn btn-xs btn-default" type="button">
                                        <i
                                            className="fa fa-trash-o fa-lg"
                                            onClick={() => deleteObject(obj.id)}
                                        />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                    ))
                }
                
                </tbody>
            </table>

        </>
    );
};

export default TableAfectada;
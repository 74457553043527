import OrdenServicio from "../sigespred/_ddp_ordenservicio/OrdenServicioList";
import { OrdenServicioAdd } from "../sigespred/_ddp_ordenservicio/OrdenServicioAdd";
import { OrdenServicioEdit } from "../sigespred/_ddp_ordenservicio/OrdenServicioEdit";


const RouterModOrdenServicio = [

    {path: "/orden-list", element: <OrdenServicio/>},
    {path: "/orden-add", element: <OrdenServicioAdd/>},
    {path: "/orden-edit/:id", element: <OrdenServicioEdit/>},
]

export default RouterModOrdenServicio;
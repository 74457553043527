import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";

import {
    Form,
    FormGroup,
    Row6,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import SingleUpload from "../../components/uploader/SingleUpload";
import { FilesGestionPredial } from "../../config/parameters";
import { list_uso_dt, generarListaMeses } from './DTValidadores'

import moment from 'moment';

const MAddEditInvitado = ({ object, closeventana, usevalue }) => {
    const [invitado, setInvitado] = useState({});
    const [lblaction, setlblaction] = useState("Agregar");
    const listameses = generarListaMeses()

    useEffect(() => {
        function initialLoad () {
            var idDate = moment().format("HHmmss");
            if (object.id) {

                setInvitado({
                    ...object,
                    update: true
                });
                setlblaction('Actualizar')
                // setInvitado(object)

            } else {
                setInvitado({
                    ...invitado,
                    id: idDate,
                    update: false
                });
            }

        }
        initialLoad();
    }, []);

    const closeModal = () => {
        closeventana(false);
    }

    const addUpdateValor = (e) => {
        debugger
        e.preventDefault();
        usevalue(invitado);

    }

    const handleInputChange = (e) => {
        setInvitado({
            ...invitado,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }

    return (
        <>
            <div className={"form-horizontal"}>
                <div>
                    <div id="lightCustomModal_background" className="popup_background backblq"></div>
                    <div id="lightCustomModal_wrapper" className="popup_wrapper bloqueador">
                        <div style={{ transform: 'scale(1)', alignContent: 'left' }}
                            className="custom-popup light  popup_content popup_content_visible bloqueador2"
                            id="lightCustomModal"
                            data-popup-initialized="true" aria-hidden="false" role="dialog" aria-labelledby="open_20531909"
                            tabIndex="-1">
                            <a onClick={closeModal} className="btn  m-right-sm lightCustomModal_close pull-right">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                            <div className=" " style={{ width: '500px' }}>
                                <div className="modal-header">
                                    <h4>{lblaction}</h4>
                                </div>
                                <Form onSubmit={addUpdateValor}>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Uso
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.usoDt || ""}
                                                onChange={handleInputChange}
                                                name={"usoDt"}>
                                                <ComboOptions data={list_uso_dt} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Area Directa m2
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.areaDirectaM2 || ""}
                                                onChange={handleInputChange}
                                                name={"areaDirectaM2"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Area Indirecta M2
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.areaIndirectaM2 || ""}
                                                onChange={handleInputChange}
                                                name={"areaIndirectaM2"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Area Total M2
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                disabled={true}
                                                value={(parseFloat(invitado?.areaDirectaM2) + parseFloat(invitado?.areaIndirectaM2)) || ""}
                                                onChange={handleInputChange}
                                                name={"areaTotalM2"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span>Fech. Elab. Plano CBC
                                        </label>
                                        <div className="col-lg-8">


                                            <Select value={invitado?.fechaElaboracionPlanoCbc || ""}
                                                onChange={handleInputChange}
                                                name={"fechaElaboracionPlanoCbc"}>
                                                <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span>codigoPlanoCbc
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.codigoPlanoCbc || ""}
                                                onChange={handleInputChange}
                                                name={"codigoPlanoCbc"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <FormGroup label={"Foto Panoramico"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_panoramico"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>

                                    <FormGroup label={"Foto Frontal"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_frontal"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>

                                    <FormGroup label={"Foto lado izquierdo"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_izquierdo"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>
                                    <FormGroup label={"Foto lado derecha"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_derecho"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>


                                    <FormGroup label={"Foto fondo"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_fondo"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>









                                    <div className="modal-footer">
                                        <button id="btnguardar" type="submit"
                                            className="btn btn-danger btn-sm btn-control">{lblaction}
                                        </button>

                                        <a onClick={closeModal} className="btn btn-default btn-sm btn-control">Cerrar</a>
                                    </div>

                                </Form>

                            </div>


                        </div>
                        <div className="popup_align bloqueador3">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MAddEditInvitado;
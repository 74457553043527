import Trabajadores from "../sigespred/m002_gestion_trabajadores/m002_01_trabajadores/Trabajadores";
import TrabajadorAdd from "../sigespred/m002_gestion_trabajadores/m002_01_trabajadores/TrabajadorAdd";
import TrabajadorDel from "../sigespred/m002_gestion_trabajadores/m002_01_trabajadores/TrabajadorDel";
import TrabajadorChangePass from "../sigespred/m002_gestion_trabajadores/m002_01_trabajadores/TrabajadorChangePass";
import TrabajadorEdit from "../sigespred/m002_gestion_trabajadores/m002_01_trabajadores/TrabajadorEdit";
import Brigadas from "../sigespred/m002_gestion_trabajadores/m002_02_equipos/Brigadas";
import BrigadaAdd from "../sigespred/m002_gestion_trabajadores/m002_02_equipos/BrigadaAdd";

const RouteMod02GestionTrabajadores = [
    {path: "/list-trabajadores", element: <Trabajadores/>},
    {path: "/trabajador-add", element: <TrabajadorAdd/>},
    {path: "/trabajador-del/:id", element: <TrabajadorDel/>},
    {path: "/trabajador-edit/:id", element: <TrabajadorEdit/>},
    {path: "/trabajador-psw/:id", element: <TrabajadorChangePass/>},
    {path: "/brigada-list", element: <Brigadas/>},
    {path: "/equipo-gestion/:idequipo", element: <BrigadaAdd/>}
]

export default RouteMod02GestionTrabajadores;
import Partida from "../sigespred/_ddp_partidas/Partida";
import PartidaAdd  from "../sigespred/_ddp_partidas/PartidaAdd";
import PartidaDel from "../sigespred/_ddp_partidas/PartidaDel";
import PartidaEdit from "../sigespred/_ddp_partidas/PartidaEdit";
import PartidaRespuesta from "../sigespred/_ddp_partidas/PartidaRespuesta";
import { PartidaUpload } from "../sigespred/_ddp_partidas/PartidaUpload";

const RouteModPartidas = [

    {path: "/partidas", element: <Partida/>},
    {path: "/partida-add", element: <PartidaAdd/>},
    {path: "/partida-edit/:id", element: <PartidaEdit/>},
    {path: "/partida-del/:id", element: <PartidaDel/>},
    {path: "/partida-respuesta/:id", element: <PartidaRespuesta/>},
    {path: "/partida-upload", element: <PartidaUpload/>},
]

export default RouteModPartidas;
import Acta from "../sigespred/_ddp_acta/Acta";
import Acuerdo from "../sigespred/_ddp_acta/Acuerdo";
import ActaAdd from "../sigespred/_ddp_acta/ActaAdd";
import ActaEdit from "../sigespred/_ddp_acta/ActaEdit";
import ActaDel from "../sigespred/_ddp_acta/ActaDel";

const RouteModActas = [
    {path: "/acta-list", element: <Acta/>},
    {path: "/acuerdo-list", element: <Acuerdo/>},
    {path: "/acta-add", element: <ActaAdd/>},
    {path: "/acta-edit/:id", element: <ActaEdit/>},
    {path: "/acta-del/:id", element: <ActaDel/>}
]

export default RouteModActas;
import { getDefaultNormalizer } from '@testing-library/react'
import React from 'react'
import DocInternoAdd from '../sigespred/_ddp_documentos_internos/DocInternoAdd'
import DocInternoEdit from '../sigespred/_ddp_documentos_internos/DocInternoEdit'
import DocInternoDel from '../sigespred/_ddp_documentos_internos/DocInternoDel'
import DocInternos from '../sigespred/_ddp_documentos_internos/DocInternos'
import DocInternoRespuesta from '../sigespred/_ddp_documentos_internos/DocInternoRespuesta'

const RouteModDocumentosInternos =  [
    {path: "/docinternos", element: <DocInternos/>},
    {path: "/docinternos-add", element: <DocInternoAdd/>},
    {path: "/docinternos-edit/:id", element: <DocInternoEdit/>},
    {path: "/docinternos-del/:id", element: <DocInternoDel/>},
    {path: "/docinternos-respuesta/:id", element: <DocInternoRespuesta/>}
]
export default RouteModDocumentosInternos

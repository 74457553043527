import GestionPredial from "../sigespred/gestion_predial/GestionPredial";
import GestionPredials from "../sigespred/_ddp_gestion_predial/GestionPredials";
import GestionPredialAdd from "../sigespred/_ddp_gestion_predial/GestionPredialAdd";
import GestionPredialEdit from "../sigespred/_ddp_gestion_predial/GestionPredialEdit";
import GestionPredialPoligono from "../sigespred/_ddp_gestion_predial/GestionPredialPoligono";
import GestionPredialPoligonoList from "../sigespred/_ddp_gestion_predial/GestionPredialPoligonoList";
import BaseGrafica from "../sigespred/base_grafica/BaseGrafica";
import BaseGrafica2 from "../sigespred/base_grafica/BaseGrafica2";
import Configuraciones from "../sigespred/base_grafica/Configuraciones";
import Brigadas from "../sigespred/base_grafica/Brigadas";
import Proyectos from "../sigespred/m003_gestion_proyectos/Proyectos";

const RouteGestionPredial = [
    {path: "/gestionpredial", element: <GestionPredials/>},
    {path: "/gestionpredial-add", element: <GestionPredialAdd/>},
    {path: "/gestionpredial-edit/:id", element: <GestionPredialEdit/>},
    {path: "/gestion-predial/:codproyecto", element: <GestionPredial/>},
    {path: "/base-grafica-ubicacion/:codproyecto", element: <BaseGrafica/>},
    {path: "/base-grafica-adjuntos/:codproyecto", element: <BaseGrafica2/>},
    {path: "/configuraciones-proyecto/:codproyecto", element: <Configuraciones/>},
    {path: "/brigadas-proyecto/:codproyecto", element: <Brigadas/>},
    {path: "/gestionpredial-valida/:id/:ti/:idpoligono?", element: <GestionPredialPoligono/>},
    {path: "/gestionpredial-validalist/:id", element: <GestionPredialPoligonoList/>},
    {path: "/gestionpredial-list", element: <Proyectos/>},

]

export default RouteGestionPredial;


import Solicitudes from "../components/administracion/solicitudes/Solicitudes";
import SolicitudAdd from "../components/administracion/solicitudes/SolicitudAdd";
import SolicitudEdit from "../components/administracion/solicitudes/SolicitudEdit";
import SolicitudDel from "../components/administracion/solicitudes/SolicitudDel";
import SolicitudAddPred from "../components/administracion/solicitudes/SolicitudAddPred";

const RouteMod04PeticionGestionPredial = [
    {path: "/solicitudes1", element: <Solicitudes/>},
    {path: "/solicitud-add1", element: <SolicitudAdd/>},
    {path: "/solicitud-edit1/:id", element: <SolicitudEdit/>},
    {path: "/solicitud-del1/:codsolicitud", element: <SolicitudDel/>},
    {path: "/solicitud-add-pred1/:id", element: <SolicitudAddPred/>}
]
export default RouteMod04PeticionGestionPredial;
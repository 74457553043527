import React, { useState, useEffect } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr'
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {
    Form,
    FormGroup,
    Row6,
    RowForm,
    Select,
    Input,
    FormFooter
} from "../../components/forms";
import { useForm } from "../../hooks/useForm"
import { useAsync } from "react-async-hook";
import * as helperGets from "../../components/helpers/LoadMaestros";
import * as PARAMS from "../../config/parameters";
import ComboOptions from "../../components/helpers/ComboOptions";
import SingleUpload from "../../components/uploader/SingleUpload";
import { FilesGestionPredial } from "../../config/parameters";
import PredioLinks from "./PredioLinks";
import { useDispatch } from 'react-redux';
import { actualizar } from '../../actions/_ddp_variable/Actions';

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoGen (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function saveDatoGen (id, body) {
    const { data } = await Axios.put(`/predio/${id}`, body);
    return data;
}

const PredioEditGen = ({ }) => {
    const { id, codpred } = useParams();
    const dispatch = useDispatch();
    const dataPredio = { predioid: id, codigopredio: codpred };
    const setIdPredioAccion = (variable) => dispatch(actualizar(variable));
    setIdPredioAccion(dataPredio);

    const [predioGen, setPredioGen, handleInputChange, reset] = useForm({}, ["cuc"]);

    const listaProyectos = useAsync(helperGets.helperGetListProyectos, []);
    const [listaTramos, setListaTramos] = useState(null);
    const listaTipoPredio = useAsync(helperGets.helperGetListDetalle, [PARAMS.LISTASIDS.TIPOPRED]);
    const listaDepartmento = useAsync(helperGets.helperGetListDepartamento, []);
    const listaProvincia = useAsync(helperGets.helperGetListProvincia, []);
    const listaDistrito = useAsync(helperGets.helperGetListDistrito, []);
    const listaSistemaCoordenadas = useAsync(helperGets.helperGetListDetalle, [PARAMS.LISTASIDS.SISTCORDENADAS]);
    const [dataProvincia, setDataProvincia] = useState(null);
    const [dataDisttrito, setDataDisttrito] = useState(null);

    useEffect(() => {
        const init = async () => {

            let datoGeneral = await getDatoGen(id);
            setPredioGen(datoGeneral);
            cargarFiltroTramo(datoGeneral.gestionpredialid);
        };
        init();
    }, []);

    const cargarFiltroTramo = async (idProyecto) => {
        if (idProyecto) {
            let dataTramos = await helperGets.helperGetListTramos(idProyecto);
            setListaTramos(dataTramos);
        }
    }

    const registrar = async e => {
        e.preventDefault();
        $('#btnguardar').button('loading');

        try {
            await saveDatoGen(predioGen.id, predioGen);
            toastr.success(`Los datos del predio: ${codpred}`, 'Se actualizarón correctamente.', { position: 'top-center' })

        }
        catch (e) {
            toastr.error('Se encontrarón errores al intentar realizar el registro de datos', JSON.stringify(e), { position: 'top-right' })
        }

        $('#btnguardar').button('reset');
    }

    function handleChangeDepartmento (e) {
        debugger;
        setValuesProvincia(e.target.value);
    }

    function setValuesProvincia (value) {
        if (!listaProvincia.loading) {
            let data = listaProvincia.result;
            let provList = data[Object.keys(data)[0]].filter(o => o.id_dpto === value);
            setDataProvincia({ data: provList });
            setDataDisttrito(null);
        }
    }

    function handleChangeProvincia (e) {
        setValuesDistrito(e.target.value);
    }

    async function handleChangeDistrito (e) {

        let { gestionpredialid, tipopredioid, tramoid, codigoubigeo = e.target.value } = predioGen
        try {

            let result = null;
            console.log(e.target.value)
            if (!e.target.value) {
                throw {
                    error: "Se encontró un error",
                    message: "No falta parametros para generar correctamente el código del Predio.",
                    status: 405
                };
            }
            //result = await getcodePredio({ gestionpredialid, tipopredioid, tramoid, codigoubigeo });
            // setPredio({ ...predio, codigopredio: result.codpredio })


        }
        catch (e) {

            toastr.error('Generación de código del predio', "Se encontró un error: " + e.message);

        }
    }

    function setValuesDistrito (value) {
        if (!listaDistrito.loading) {
            let data = listaDistrito.result;
            let distList = data[Object.keys(data)[0]].filter(o => o.id_prov === value);
            setDataDisttrito({ data: distList });
        }
    }


    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / DATOS GENERALES"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >
                <PredioLinks active="1"></PredioLinks>
                <Form onSubmit={registrar}>
                    <div className="mtop-35"></div>
                    <RowForm>
                        <Row6>
                            <FormGroup label={"Código de Predio (DDP)"}>
                                <Input value={predioGen.codigopredio || ""} readonly={true}
                                    name={"codigopredio"} placeholder={"Ingrese el código de referencia catastral"}
                                    type={"text"}>
                                </Input>
                            </FormGroup>
                            <FormGroup label={"Proyecto"}>
                                <Select readonly={true} value={predioGen.gestionpredialid || ""}
                                    name={"gestionpredialid"}>
                                    {listaProyectos.result ?
                                        <ComboOptions data={listaProyectos.result} valorkey="id" valornombre="denominacion" />
                                        : "Cargando..."}
                                </Select>
                            </FormGroup>
                            <FormGroup label={"Sector / Tramo"}>
                                <Select value={predioGen.tramoid || ""} readonly={true}
                                    name={"tramoid"}>
                                    <ComboOptions data={listaTramos} valorkey="id" valornombre="descripcion" />
                                </Select>
                            </FormGroup>
                            <FormGroup label={"Sub-sector / Sub-tramo"}>
                                <Input value={predioGen.subtramo || ""} onChange={handleInputChange}
                                    name={"subtramo"} placeholder={"Ingrese el sub-sector o sub-tramo"}
                                    type={"text"}>
                                </Input>
                            </FormGroup>
                            <FormGroup label={"Tipo de Predio"}>
                                <Select value={predioGen.tipopredioid || ""} readonly={true}
                                    name={"tipopredioid"}>
                                    {listaTipoPredio.result ?
                                        <ComboOptions data={listaTipoPredio.result} valorkey="id" valornombre="valortexto" />
                                        : "Cargando..."}
                                </Select>
                            </FormGroup>
                            <FormGroup label={"Sistema de Referencia de Coordenadas"}>
                                <Select value={predioGen.sistcoordenadasid || ""}
                                    onChange={handleInputChange}
                                    name={"sistcoordenadasid"}>
                                    {listaSistemaCoordenadas.result ?
                                        <ComboOptions data={listaSistemaCoordenadas.result} valorkey="id" valornombre="valortexto" />
                                        : "Cargando..."}
                                </Select>
                            </FormGroup>
                        </Row6>
                        <Row6>
                            <FormGroup label={"Departamento"}>
                                <Select value={predioGen.departamentoid || ""} readonly={false}
                                    onChange={(e) => { handleChangeDepartmento(e); handleInputChange(e); }}
                                    name={"departamentoid"}>
                                    {listaDepartmento.result ?
                                        <ComboOptions data={listaDepartmento.result} valorkey="id_dpto" valornombre="nombre" />
                                        : "Cargando..."}
                                </Select>
                            </FormGroup>
                            <FormGroup label={"Provincia"}>
                                <Select value={predioGen.provinciaid || ""}
                                    onChange={(e) => { handleChangeProvincia(e); handleInputChange(e); }}
                                    name={"provinciaid"}>
                                    {listaProvincia.result ?
                                        <ComboOptions data={dataProvincia} valorkey="id_prov" valornombre="nombre" />
                                        : "Cargando..."}
                                </Select>
                            </FormGroup>
                            <FormGroup label={"Distrito"}>
                                <Select value={predioGen.distritoid || ""}
                                    onChange={(e) => { handleChangeDistrito(e); handleInputChange(e); }}
                                    name={"distritoid"}>
                                    {listaDistrito.result ?
                                        <ComboOptions data={dataDisttrito} valorkey="id_dist" valornombre="nombre" />
                                        : "Cargando..."}
                                </Select>
                            </FormGroup>
                            {/* <FormGroup label={"Progresiva - Inicio (Km)"}>
                                <Input value={predioGen.progreinicio || ""} onChange={handleInputChange}
                                    name={"progreinicio"} placeholder={"Ingrese el valor de inicio de la progresiva"}
                                   
                                    type={"text"}>
                                </Input>
                            </FormGroup>*/}
                            {/*   <FormGroup label={"Progresiva - Final (Km)"}>
                            <Input value={predioGen.progrefinal || ""} onChange={handleInputChange}
                                    name={"progrefinal"} placeholder={"Ingrese el valor de fin de la progresiva"}
                                   
                                    type={"text"}>
                                </Input>
                                
                            </FormGroup>*/}
                            <FormGroup label={"Archivo con Geometría del Predio"}>
                                <SingleUpload
                                    key="archivopredio"
                                    accept={'.*'}
                                    folderSave={FilesGestionPredial.FilesPredios}
                                    form={predioGen}
                                    setForm={setPredioGen}
                                    nameUpload={"archivopredio"}
                                >
                                </SingleUpload>
                            </FormGroup>
                        </Row6>
                    </RowForm>
                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>
                        <button id="btnguardar" type="submit"
                            className="btn btn-danger btn-sm btn-control">Guardar
                        </button>
                    </FormFooter>
                </Form>
            </WraperLarge>
        </>
    );
};

export default PredioEditGen;
import React from 'react';

const TableAfectada = ({data=[], deleteObject,updateObject}) => {
    const cabecera = ["#", "COD.POLIGONO", "USO DT","AREA_DIRECTA_m²","AREA_INDIRECTA_m²","AREA_TOTAL_m²","FECHA ELABORACION PLANO CBC",
    ,"CÓDIGO PLANO CBC","Acciones"];
    return (
        <>
            <table className="table table-bordered table-condensed table-hover table-striped" id="dataTableInvitado">
                <thead>
                <tr>
                    { cabecera.map((item,i)=>(
                        <th key={i}>{item}</th>
                    ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((obj, key) => (
                        
                            <tr key={key}>
                                <td key={`td_${key}_1`}>{key+1}</td>
                                <td key={`td_${key}_2`}>{obj.poligono}</td>
                                <td key={`td_${key}_3`}>{obj.usoDt}</td>
                                <td key={`td_${key}_3`}>{obj.areaDirectaM2}</td>
                                <td key={`td_${key}_3`}>{obj.areaIndirectaM2}</td>
                                <td key={`td_${key}_3`}>{obj.areaTotalM2}</td>
                                <td key={`td_${key}_3`}>{obj.fechaElaboracionPlanoCbc}</td>
                                <td key={`td_${key}_3`}>{obj.codigoPlanoCbc}</td>
                                <td key={`td_${key}_4`} className="acciones-1bot pull-center">
                                    <div className="btn-group">
                                    <button className="btn btn-xs btn-default mright-5" type="button">
                                            <i className="fa fa-edit fa-lg" onClick={() => updateObject(obj.id)} />
                                        </button>
                                        <button className="btn btn-xs btn-default" type="button">
                                        <i
                                            className="fa fa-trash-o fa-lg"
                                            onClick={() => deleteObject(obj.id)}
                                        />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                    ))
                }
                
                </tbody>
            </table>

        </>
    );
};

export default TableAfectada;
import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import {
    Select, Form, Input, FormGroup

} from "../../components/forms";
import SingleUpload from "../../components/uploader/SingleUpload";
import { FilesGestionPredial } from "../../config/parameters";
import ComboOptions from "../../components/helpers/ComboOptions";
import { list_modulos, list_pisos, list_uso_dt, list_material, list_estado_construccion_vivienda, list_estado_conservacion } from './DTValidadores'
import moment from 'moment';

const MAddEditInvitado = ({ object, closeventana, usevalue }) => {
    const [invitado, setInvitado] = useState({});
    const [lblaction, setlblaction] = useState("Agregar");

    useEffect(() => {
        function initialLoad () {
            var idDate = moment().format("HHmmss");
            if (object.id) {

                setInvitado({
                    ...object,
                    update: true
                });
                setlblaction('Actualizar')
                // setInvitado(object)

            } else {
                setInvitado({
                    ...invitado,
                    id: idDate,
                    update: false
                });
            }

        }
        initialLoad();
    }, []);

    const closeModal = () => {
        closeventana(false);
    }

    const addUpdateValor = (e) => {

        e.preventDefault();
        usevalue(invitado);

    }

    const handleInputChange = (e) => {

        setInvitado({
            ...invitado,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }

    return (
        <>
            <div className={"form-horizontal"}>
                <div>
                    <div id="lightCustomModal_background" className="popup_background backblq"></div>
                    <div id="lightCustomModal_wrapper" className="popup_wrapper bloqueador">
                        <div style={{ transform: 'scale(1)', alignContent: 'left' }}
                            className="custom-popup light  popup_content popup_content_visible bloqueador2"
                            id="lightCustomModal"
                            data-popup-initialized="true" aria-hidden="false" role="dialog" aria-labelledby="open_20531909"
                            tabIndex="-1">
                            <a onClick={closeModal} className="btn  m-right-sm lightCustomModal_close pull-right">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                            <div className=" " style={{ width: '500px' }}>
                                <div className="modal-header">
                                    <h4>{lblaction}</h4>
                                </div>
                                <Form onSubmit={addUpdateValor}>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> descripcion
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.descripcion || ""}
                                                onChange={handleInputChange}
                                                name={"descripcion"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> modulo
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.modulo || ""}
                                                onChange={handleInputChange}
                                                name={"modulo"}>
                                                <ComboOptions data={list_modulos} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> piso
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.piso || ""}
                                                onChange={handleInputChange}
                                                name={"piso"}>
                                                <ComboOptions data={list_pisos} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>


                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> directa
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.directa || ""}
                                                onChange={handleInputChange}
                                                name={"directa"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> indirecta
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.indirecta || ""}
                                                onChange={handleInputChange}
                                                name={"indirecta"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> total
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={(parseFloat(invitado?.directa) + parseFloat(invitado?.indirecta)) || ""}
                                                onChange={handleInputChange}
                                                name={"total"}
                                                disabled={true}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>




                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> piso_mas_alto
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.piso_mas_alto || ""}
                                                onChange={handleInputChange}
                                                name={"piso_mas_alto"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> volado
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.volado || ""}
                                                onChange={handleInputChange}
                                                name={"volado"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>


                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> volado2
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.volado2 || ""}
                                                onChange={handleInputChange}
                                                name={"volado2"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span> caracteristicas
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.caracteristicas || ""}
                                                onChange={handleInputChange}
                                                name={"caracteristicas"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Uso
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.uso || ""}
                                                onChange={handleInputChange}
                                                name={"uso"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>

                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> antiguedad_anos
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.antiguedad_anos || ""}
                                                onChange={handleInputChange}
                                                name={"antiguedad_anos"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Material predominante
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.material_predominante || ""}
                                                onChange={handleInputChange}
                                                name={"material_predominante"}>
                                                <ComboOptions data={list_material} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> estado_conservacion
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.estado_conservacion || ""}
                                                onChange={handleInputChange}
                                                name={"estado_conservacion"}>
                                                <ComboOptions data={list_estado_conservacion} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> estado_construccion
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.estado_construccion || ""}
                                                onChange={handleInputChange}
                                                name={"estado_construccion"}>
                                                <ComboOptions data={list_estado_construccion_vivienda} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> muros
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.muros || ""}
                                                onChange={handleInputChange}
                                                name={"muros"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> columnas
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.columnas || ""}
                                                onChange={handleInputChange}
                                                name={"columnas"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> techos
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.techos || ""}
                                                onChange={handleInputChange}
                                                name={"techos"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> pisos
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.pisos || ""}
                                                onChange={handleInputChange}
                                                name={"pisos"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> puertas
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.puertas || ""}
                                                onChange={handleInputChange}
                                                name={"puertas"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> ventanas
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.ventanas || ""}
                                                onChange={handleInputChange}
                                                name={"ventanas"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> revestimiento
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.revestimiento || ""}
                                                onChange={handleInputChange}
                                                name={"revestimiento"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> banos
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.banos || ""}
                                                onChange={handleInputChange}
                                                name={"banos"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> instalaciones_electricas
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.instalaciones_electricas || ""}
                                                onChange={handleInputChange}
                                                name={"instalaciones_electricas"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> instalaciones_sanitarias
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.instalaciones_sanitarias || ""}
                                                onChange={handleInputChange}
                                                name={"instalaciones_sanitarias"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"></span> vuat
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.vuat || ""}
                                                onChange={handleInputChange}
                                                name={"vuat"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span> fd
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.fd || ""}
                                                onChange={handleInputChange}
                                                name={"fd"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <FormGroup label={"Foto Frente"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_frente"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>

                                    <FormGroup label={"Foto lado derecho"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_lado_derecho"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>

                                    <FormGroup label={"Foto lado izquierdo"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_lado_izquierdo"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>

                                    <FormGroup label={"Foto fondo"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_lado_fondo"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>







                                    <div className="modal-footer">
                                        <button id="btnguardar" type="submit"
                                            className="btn btn-danger btn-sm btn-control">{lblaction}
                                        </button>

                                        <a onClick={closeModal} className="btn btn-default btn-sm btn-control">Cerrar</a>
                                    </div>

                                </Form>

                            </div>


                        </div>
                        <div className="popup_align bloqueador3">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MAddEditInvitado;
const TOKEN_KEY = 'SIGESPRED_TOKEN';
export const USUARIO = 'USUARIO';
export const USUARIO_ID = 'IDUSUARIO';
export const PROYECTO_GESTION_PREDIAL = 'PROYECTO_GESTION_PREDIAL';
const {XLSX} = window;

export function setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

export function deleteToken() {
    localStorage.removeItem(TOKEN_KEY);
}

export const login = ({token, usuario }) => {

    if(!token || !usuario){
        return null
    }
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USUARIO, JSON.stringify(usuario))
    localStorage.setItem(USUARIO_ID, usuario.id)
    return true;
}


export const selectProyecto = (proyecto) => {
    console.log(proyecto)
    localStorage.setItem(PROYECTO_GESTION_PREDIAL, JSON.stringify(proyecto));
}

export const getselectProyecto = () => {
    return JSON.parse(localStorage.getItem(PROYECTO_GESTION_PREDIAL))
}


export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USUARIO);
}

export const getUsuario = () => {
    return JSON.parse(localStorage.getItem(USUARIO))
}


export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}

function compareArrays(a, b) {
    if (a.length !== b.length) {
        return { areEqual: false, diff: [...a, ...b] };
    }
    let diff = [];
    for (let i = 0; i < a.length; i++) {
        if (!b.includes(a[i])) {
            diff.push(a[i]);
        }
    }
    for (let i = 0; i < b.length; i++) {
        if (!a.includes(b[i])) {
            diff.push(b[i]);
        }
    }
    if (diff.length === 0) {
        return { areEqual: true, diff: [] };
    }
    return { areEqual: false, diff: diff };
}

export function subirExcel(file,cabecerasdefinidas,page=0) {


    return new Promise((resolve, reject) => {

       // fileInput.addEventListener('change', (event) => {
            //const file = event.target.files[0]; // el primer archivo

            console.log('Archivo seleccionado:', file.name);

            const reader = new FileReader();

            reader.onload = (e) => {

                const data = new Uint8Array(e.target.result);

                const workbook = XLSX.read(data, { type: 'array' });

                // Leer la primera hoja de cálculo
                const sheet = workbook.Sheets[workbook.SheetNames[page]];

                // Convertir los datos a un arreglo de objetos
                const jsonData = XLSX.utils.sheet_to_json(sheet);

                if (jsonData.length == 0) {

                    alert("Archivo Vacio", `El archivo no tiene ningúna fila registrada.`)
                    return;
                }

                // ahora jsonData[0] debe contener las cabeceras
                const headers = Object.keys(jsonData[0]);
                console.log(headers)
                if (cabecerasdefinidas==null || cabecerasdefinidas.length == 0) {
                    return resolve(jsonData);
                }
                let comparearrays = compareArrays(headers, cabecerasdefinidas)
                console.log(cabecerasdefinidas)

                // validar las cabeceras
                if (comparearrays.areEqual) {
                    return resolve(jsonData);
                }
                else {
                    reject(e);
                }

                


                //console.log(jsonData)

                // Imprimir los datos
              
            };

            reader.onerror = (e) => {
                // Rechazar la promesa con el error
                reject(e);
            };

            reader.readAsArrayBuffer(file);
        });


    //});
}
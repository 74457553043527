import React, { useEffect, useState } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {
    Form,
    FormGroup,
    Row6,
    Row4,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table,
    TextArea
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import { useForm } from '../../hooks/useForm';
import { useAsync } from "react-async-hook";
import MAddEditInvitado from "./DLPadronModal";
import TableInvitado from "./DLPadronTable";
import PredioLinks from "./PredioLinksLegal";
import * as helperGets from "../../components/helpers/LoadMaestros";
import MAddAreasLinderos from "./MAddAreasLinderos";
import { MAddGanadera } from "./MAddGanadera";
import { MAddAgricola } from "./MAddAgricola";

import { isObjectValid, list_servidumbres, list_forma_afectacion, list_unidad_tiempo, list_entidad_titularidad, list_dl_tip_establecimiento, list_dl_actividad, list_tipo_negocio, list_estado_predio, list_condicion_juridica, list_doc_sust_titularidad, list_und_doc_titularidad, list_lucro_cesante, list_cond_negocio, list_dano, list_regimen } from './DTValidadores'

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoPredio (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function getdata (object) {
    //  alert(object.id)
    const { data } = await Axios.get(`/list_pl_padron?predioid=` + object.id);
    console.log(data)
    return data;
}

async function save (object) {
    const { data } = await Axios.post(`/save_pl_padron`, object);
    return data;
}



function generarListaMeses () {

    const meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    let listaMeses = [];
    const añoActual = new Date().getFullYear();

    // Comenzar desde junio del año actual
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual}` });
    }

    // Continuar hasta mayo del año siguiente
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual + 1}` });
    }

    return { listaMeses };
}

async function getListaTrabajadores (object) {
    const { data } = await Axios.get(`/usuario?busqueda=&limit=10000&page=1`);

    let trabajadores = data?.rows.map(({ id, nombres, apellidos }) => ({
        id,
        nombreCompleto: `${apellidos} ${nombres}`
    }));

    // Ordenar alfabéticamente por nombre completo
    trabajadores.sort((a, b) => a.nombreCompleto.localeCompare(b.nombreCompleto));

    return trabajadores;
}



const DTPlant = ({ history, match }) => {

    //Obtener los parameotros
    const { id, codpred } = useParams();

    //Definicion de estado del formularios
    const iniciales = {
        marco_legal: `ARTICULO 15  DEL TEXTO ÚNICO ORDENADO DEL DECRETO LEGISLATIVO N°1192, QUE APRUEBA LA "LEY MARCO DE ADQUISICION Y EXPROPIACION DE INMUEBLES, TRANSFERENCIA DE INMUEBLES DE PROPIEDAD DEL ESTADO, LIBERACION DE INTERFERENCIAS Y DICTA OTRAS MEDIDAS PARA LA EJECUCION DE OBRAS DE INFRAESTRUCTURA", APROBADO MEDIANTE DECRETO SUPREMO N°015-2020-VIVIENDA, QUE ESTABLECE: "EL EXPEDIENTE TÉCNICO LEGAL, DEBIDAMENTE SUSTENTADO, QUE CONTIENE LA SOLICITUD DE TASACIÓN QUE PRESENTE EL SUJETO ACTIVO AL ÓRGANO ENCARGADO DE LA TASACIÓN, CUMPLE COMO MÍNIMO CON LOS SIGUIENTES REQUISITOS, BAJO RESPONSABILIDAD:
15.1 DOCUMENTOS DEL PREDIO: A) MEMORIA DESCRIPTIVA........", ENTRE OTROS DOCUMENTOS.`,
        analisis_situacion_legal: ` EL TITULAR DEL PREDIO ACREDITA SU DERECHO DE PROPIEDAD DEL INMUEBLE, EN MERITO A LA PARTIDA REGISTRAL N° <nro-registral> DEL REGISTRO DE PREDIOS DE LIMA, ZONA REGISTRAL N° IX- SEDE LIMA, EN CUMPLIMIENTO AL ART. 6.1  DEL TEXTO UNICO ORDENADO DEL DECRETO LEGISLATIVO N° 1192.`

    }
    const [object, setObject, handleInputChange, reset] = useForm(iniciales, ["profesional_legal_responsable", "sector_localidad", "distrito", "uc",
        "titular_registral", "telefono_contacto", "entidad"]);
    const [predio, setPredio] = useState({});
    const listameses = generarListaMeses();

    const [listaInvitados, setListaInvitados] = useState([]);
    const [modalInvitado, setModalInvitado] = useState(false);
    const [accion, set_accion] = useState('Agregar');
    const [objectModal, setObjectModal] = useState({});

    const [listaTrabajadores, setListaTrabajadores] = useState({
        trabajadores: [
            { "id": "ACTUAL", "nombreCompleto": "ACTUAL" },
            { "id": "RETROESPECTIVA", "nombreCompleto": "RETROESPECTIVA" },

        ]
    });



    //Traer el objeto de datos del predio seleccionado
    useEffect(() => {
        const init = async () => {

            //obtenieno los datos predio
            let predio = await getDatoPredio(id);
            setPredio(predio);
            localStorage.setItem("predioselec", JSON.stringify(predio))

            let trabajadores = await getListaTrabajadores()
            setListaTrabajadores({
                trabajadores: trabajadores
            });
            // alert(predio.id)
            let data = await getdata(predio);
            console.log(data)
            setObject({ ...data, predioid: predio.id })
            setListaInvitados(data.titulares || []);


        }
        init();
    }, []);

    const actualizar = async (e) => {
        e.preventDefault();
        try {

            await save(object);
            toastr.success(
                "Guardando",
                "El registro fue guardado correctamente.",
                { position: "top-right" }
            );

            //history.push("/blog");
        } catch (e) {

            toastr.error('Ocurrio un error', e.message, { position: 'top-center' })
        }
    }

    const handleClickAddEditInvitado = (e) => {
        setModalInvitado(true);
        setObjectModal({})
    }

    const cerrarModal = (estado) => {
        setModalInvitado(estado);
    }
    /*Gestion de distrito*/

    const updatevaluesinvitado = (invitado) => {

        if (invitado.update) {
            const index = listaInvitados.findIndex(obj => obj.id === invitado.id);
            if (index !== -1) {
                listaInvitados[index] = { ...invitado };
            }
            calcularTotales(listaInvitados);
            setListaInvitados(listaInvitados);


        } else {
            let tam = listaInvitados.length || 0;
            let newListInvitados = [...listaInvitados, { ...invitado, poligono: "ÁREA AFECTADA N° " + (tam + 1), areaTotalM2: (parseFloat(invitado?.areaDirectaM2) + parseFloat(invitado?.areaIndirectaM2)) }]
            setListaInvitados(newListInvitados);
            calcularTotales(newListInvitados);


        }




        setModalInvitado(false);


    }

    const updatedInvitado = (key) => {

        //alert(key)
        var foundObject = listaInvitados.find(function (e) {
            return e.id == key;
        });
        setObjectModal(foundObject);
        setModalInvitado(true);

    };

    const deleteInvitado = key => {

        const toastrConfirmOptions = {
            onOk: () => {
                let data = listaInvitados.filter(function (e) {
                    return e.id !== key;
                });

                setListaInvitados(data);
                calcularTotales(data);

            },
            onCancel: () => { }
        };
        toastr.confirm(`¡Desea eliminar el registro?`, toastrConfirmOptions);





    };
    const calcularTotales = (listaInvitados) => {


        // Actualiza el estado u objeto como sea necesario
        setObject({
            ...object,

            titulares: listaInvitados
        });


    }




    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / PADRON DT"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >
                <PredioLinks active="1"></PredioLinks>
                <Form onSubmit={actualizar}>
                    <div className="mtop-35"></div>
                    <RowForm>

                        <div className="col-lg-12">
                            {isObjectValid(object) ? <h6 className="obligatorio"> Formulario no guardado </h6> : null}

                            <fieldset className="mleft-20">
                                <legend>Datos de expediente</legend>
                                <Row6>
                                    <FormGroup label={"Código del predio"} >
                                        <Input
                                            required={false}
                                            value={predio?.codigopredio || ""}
                                            //onChange={handleInputChange}
                                            name={"titulo"}
                                            placeholder={"Ingrese titulo de la entrada"}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Profesional legal resposable"} require={true} >


                                        <Select required={true} value={object?.profesional_legal_responsable || ""}
                                            onChange={handleInputChange}
                                            name={"profesional_legal_responsable"}>
                                            <ComboOptions data={listaTrabajadores} valorkey="id" valornombre="nombreCompleto" />
                                        </Select>


                                    </FormGroup>
                                    <FormGroup label={"Estado del predio."}>
                                        <Select value={object?.estado_expediente || ""}
                                            onChange={handleInputChange}
                                            name={"estado_expediente"}>
                                            <ComboOptions data={list_estado_predio} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                </Row6>
                                <Row6>
                                    <FormGroup label={"Código expediente"} >
                                        <Input
                                            value={predio?.codexpediente || ""}
                                            //onChange={handleInputChange}
                                            name={"fechainspeccion"}
                                            type={"text"}

                                        >
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Mes elaboración Exp."}>
                                        <Select value={object?.mes_elaboracion_expediente || ""}
                                            onChange={handleInputChange}
                                            name={"mes_elaboracion_expediente"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>


                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Ubicación</legend>
                                <Row6>
                                    <FormGroup label={"Distrito."}>
                                        <Input
                                            required={false}
                                            value={object?.distrito || ""}
                                            onChange={handleInputChange}
                                            name={"distrito"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>





                                    <FormGroup label={"Manzana"}>
                                        <Input
                                            required={false}
                                            value={object?.mza || ""}
                                            onChange={handleInputChange}
                                            name={"mza"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"UC."}>
                                        <Input
                                            required={false}
                                            value={object?.uc || ""}
                                            onChange={handleInputChange}
                                            name={"uc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>


                                </Row6>
                                <Row6>

                                    <FormGroup label={"Sector Localidad."}>
                                        <Input
                                            required={false}
                                            value={object?.sector_localidad || ""}
                                            onChange={handleInputChange}
                                            name={"sector_localidad"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"Lote"}>
                                        <Input
                                            required={false}
                                            value={object?.lote || ""}
                                            onChange={handleInputChange}
                                            name={"lote"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>











                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>


                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Titulares</legend>
                                <Row6>

                                    <FormGroup label={"Titular registral."}>
                                        <Input
                                            required={false}
                                            value={object?.titular_registral || ""}
                                            onChange={handleInputChange}
                                            name={"titular_registral"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>




                                </Row6>
                                <Row6>
                                    <FormGroup label={"Telefono de contacto."}>
                                        <Input
                                            required={false}
                                            value={object?.telefono_contacto || ""}
                                            onChange={handleInputChange}
                                            name={"telefono_contacto"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>










                                </Row6>

                                <div className="col-lg-12">

                                    <button className="btn btn-sm btn-info" type="button" value={accion} onClick={handleClickAddEditInvitado}>
                                        <i className="fa fa-plus fa-lg" />Añadir
                                    </button>


                                    <TableInvitado
                                        data={listaInvitados}
                                        deleteObject={deleteInvitado}
                                        updateObject={updatedInvitado}
                                    >

                                    </TableInvitado>
                                </div>

                                <FormGroup label={"Condicion Juridica de los titulares."}>
                                    <Select required={true} value={object?.condicion_juridica || ""}
                                        onChange={handleInputChange}
                                        name={"condicion_juridica"}>
                                        <ComboOptions data={list_condicion_juridica} valorkey="id" valornombre="descripcion" />
                                    </Select>
                                </FormGroup>

                                <FormGroup label={"PARTIDA REGISTRAL MATRIZ"}>
                                    <Input
                                        required={false}
                                        value={object?.partida_registral_matriz || ""}
                                        onChange={handleInputChange}
                                        name={"partida_registral_matriz"}
                                        placeholder={""}
                                        type={"text"}>
                                    </Input>
                                </FormGroup>
                                <FormGroup label={"PARTIDA REGISTRAL INDEPENDIZADA"}>
                                    <Input
                                        required={false}
                                        value={object?.partida_registral_independizada || ""}
                                        onChange={handleInputChange}
                                        name={"partida_registral_independizada"}
                                        placeholder={""}
                                        type={"text"}>
                                    </Input>
                                </FormGroup>
                                <FormGroup label={"DOCUMENTO QUE ACREDITA TITULARIDAD DEL PREDIO"}>
                                    <Select required={true} value={object?.documento_acredita_titularidad || ""}
                                        onChange={handleInputChange}
                                        name={"documento_acredita_titularidad"}>
                                        <ComboOptions data={list_doc_sust_titularidad} valorkey="id" valornombre="descripcion" />
                                    </Select>
                                </FormGroup>

                                <FormGroup label={"Entidad acredita titularidad"}>
                                    <Select required={true} value={object?.entidad_acredita_titularidad || ""}
                                        onChange={handleInputChange}
                                        name={"entidad_acredita_titularidad"}>
                                        <ComboOptions data={list_entidad_titularidad} valorkey="id" valornombre="descripcion" />
                                    </Select>
                                </FormGroup>

                                <FormGroup label={"NUMERO DOCUMENTO QUE ACREDITA TITULARIDAD DEL PREDIO"}>
                                    <Input
                                        required={false}
                                        value={object?.numero_documento_acredita_titularidad || ""}
                                        onChange={handleInputChange}
                                        name={"numero_documento_acredita_titularidad"}
                                        placeholder={""}
                                        type={"text"}>
                                    </Input>
                                </FormGroup>
                                <FormGroup label={"FECHA DE EMISION"}>
                                    <Input
                                        required={true}
                                        value={object?.fecha_emision || ""}
                                        onChange={handleInputChange}
                                        name={"fecha_emision"}
                                        placeholder={""}
                                        type={"date"}>
                                    </Input>
                                </FormGroup>

                                <FormGroup label={"ENTIDAD"}>
                                    <Input
                                        required={false}
                                        value={object?.entidad || ""}
                                        onChange={handleInputChange}
                                        name={"entidad"}
                                        placeholder={""}
                                        type={"text"}>
                                    </Input>
                                </FormGroup>

                                <FormGroup label={"AREA DOCUMENTO DE PROPIEDAD"} require={true}>
                                    <Input
                                        required={true}
                                        value={object?.area_documento_propiedad || ""}
                                        onChange={handleInputChange}
                                        name={"area_documento_propiedad"}
                                        placeholder={""}
                                        type={"number"}
                                        step={"any"}
                                    >
                                    </Input>
                                </FormGroup>
                                <FormGroup label={"UNIDADES DOCUMENTO DE PROPIEDAD."}>
                                    <Select required={true} value={object?.unidades_documento_propiedad || ""}
                                        onChange={handleInputChange}
                                        name={"unidades_documento_propiedad"}>
                                        <ComboOptions data={list_und_doc_titularidad} valorkey="id" valornombre="descripcion" />
                                    </Select>
                                </FormGroup>

                            </fieldset>
                        </div>



                    </RowForm>



                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Lucro cesante</legend>
                                <Row6>
                                    <FormGroup label={"LUCRO CESANTE SI/NO."}>
                                        <Select required={true} value={object?.lucro_cesante || ""}
                                            onChange={handleInputChange}
                                            name={"lucro_cesante"}>
                                            <ComboOptions data={list_lucro_cesante} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"Denominación de negocio."}>
                                        <Input
                                            disabled={object?.lucro_cesante === 'NO'}
                                            required={false}
                                            value={object?.denominacion_negocio || ""}
                                            onChange={handleInputChange}
                                            name={"denominacion_negocio"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"Condición de negocio."}>
                                        <Select
                                            disabled={object?.lucro_cesante === 'NO'}
                                            required={object?.lucro_cesante === 'NO'} value={object?.condicion_negocio || ""}
                                            onChange={handleInputChange}
                                            name={"condicion_negocio"}>
                                            <ComboOptions data={list_cond_negocio} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>
                                    <FormGroup label={"Regimen."}>

                                        <Select disabled={object?.lucro_cesante === 'NO'} required={object?.lucro_cesante === 'NO'} value={object?.regimen || ""}
                                            onChange={handleInputChange}
                                            name={"regimen"}>
                                            <ComboOptions data={list_regimen} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>
                                    <FormGroup label={"nro_ruc."}>
                                        <Input
                                            disabled={object?.lucro_cesante === 'NO'}
                                            required={false}
                                            value={object?.nro_ruc || ""}
                                            onChange={handleInputChange}
                                            name={"nro_ruc"}
                                            placeholder={""}
                                            type={"text"}
                                            maxlength={"11"}
                                            minlength={"11"}
                                        >
                                        </Input>
                                    </FormGroup>







                                </Row6>
                                <Row6>

                                    <FormGroup label={"tipoempresa."}>


                                        <Select disabled={object?.lucro_cesante === 'NO'}
                                            required={object?.lucro_cesante === 'NO'} value={object?.tipo_empresa || ""}
                                            onChange={handleInputChange}
                                            name={"tipo_empresa"}>
                                            <ComboOptions data={list_tipo_negocio} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>


                                    <FormGroup label={"Descripción giro comercial."}>
                                        <Input
                                            disabled={object?.lucro_cesante === 'NO'}
                                            required={false}
                                            value={object?.descripcion_giro_comercial || ""}
                                            onChange={handleInputChange}
                                            name={"descripcion_giro_comercial"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Tipo negocio."}>
                                        <Input
                                            disabled={object?.lucro_cesante === 'NO'}
                                            required={false}
                                            value={object?.tipo_negocio || ""}
                                            onChange={handleInputChange}
                                            name={"tipo_negocio"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Actividad."}>

                                        <Select disabled={object?.lucro_cesante === 'NO'} required={true} value={object?.actividad || ""}
                                            onChange={handleInputChange}
                                            name={"actividad"}>
                                            <ComboOptions data={list_dl_actividad} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>


                                    <FormGroup label={"Tipo establecimiento."}>
                                        <Select disabled={object?.lucro_cesante === 'NO'} value={object?.tipo_establecimiento || ""}
                                            onChange={handleInputChange}
                                            name={"tipo_establecimiento"}>
                                            <ComboOptions data={list_dl_tip_establecimiento} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>







                                </Row6>
                                <RowForm>

                                    <div className="col-lg-12">

                                        <label class="control-label">Descripcion de lucro</label>

                                        <TextArea disabled={object?.lucro_cesante === 'NO'} required={false} value={object?.descripcion_lucro_cesante} onChange={handleInputChange}
                                            name={"descripcion_lucro_cesante"} placeholder={"Descripción del lucro cesante"}
                                            type={"text"}>
                                        </TextArea>



                                    </div>


                                </RowForm>


                            </fieldset>
                        </div>
                        {/*
                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Daño emergente</legend>

                                <Row4>


                                    <FormGroup label={"Daño emergente."}>
                                        <Select value={object?.danoEmergente || ""}
                                            onChange={handleInputChange}
                                            name={"danoEmergente"}>
                                            <ComboOptions data={list_dano} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>
                                    <FormGroup label={"Forma de afectación"}>

                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_forma_afectacion || ""}
                                            onChange={handleInputChange}
                                            name={"de_forma_afectacion"}>
                                            <ComboOptions data={list_forma_afectacion} valorkey="id" valornombre="descripcion" />
                                        </Select>


                                    </FormGroup>

                                    <FormGroup label={"Gastos de búsqueda --->"}>

                                    </FormGroup>

                                    <FormGroup label={"Translado de enseres --->"}>

                                    </FormGroup>

                                    <FormGroup label={"Alquiler de inmueble temporal --->"}>

                                    </FormGroup>



                                </Row4>

                                <Row4>
                                    <FormGroup label={"UNIDAD DE TIEMPO"}>

                                    </FormGroup>
                                    <FormGroup label={"."}>

                                    </FormGroup>

                                    <FormGroup label={"Unidad de tiempo"}>
                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_gasto_busqueda_ut || ""}
                                            onChange={handleInputChange}
                                            name={"de_gasto_busqueda_ut"}>
                                            <ComboOptions data={list_unidad_tiempo} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>


                                    <FormGroup label={"Unidad de tiempo"}>
                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_trasl_enseres_ut || ""}
                                            onChange={handleInputChange}
                                            name={"de_trasl_enseres_ut"}>
                                            <ComboOptions data={list_unidad_tiempo} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>

                                    <FormGroup label={"Unidad de tiempo"}>
                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_alqu_inmueble_ut || ""}
                                            onChange={handleInputChange}
                                            name={"de_alqu_inmueble_ut"}>
                                            <ComboOptions data={list_unidad_tiempo} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>

                                </Row4>

                                <Row4>
                                    <FormGroup label={"CANTIDAD"}>

                                    </FormGroup>
                                    <FormGroup label={"."}>

                                    </FormGroup>

                                    <FormGroup label={"Cantidad"}>
                                        <Input disabled={object?.danoEmergente === 'NO'}
                                            required={false}
                                            value={object?.de_gasto_busqueda_cant || ""}
                                            onChange={handleInputChange}
                                            name={"de_gasto_busqueda_cant"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >

                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"Cantidad"}>
                                        <Input
                                            disabled={object?.danoEmergente === 'NO'}
                                            required={false}
                                            value={object?.de_trasl_enseres_cant || ""}
                                            onChange={handleInputChange}
                                            name={"de_trasl_enseres_cant"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >

                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"Cantidad"}>
                                        <Input
                                            disabled={object?.danoEmergente === 'NO'}
                                            required={false}
                                            value={object?.de_alqu_inmueble_cant || ""}
                                            onChange={handleInputChange}
                                            name={"de_alqu_inmueble_cant"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >

                                        </Input>
                                    </FormGroup>



                                </Row4>


                                <RowForm>

                                    <div className="col-lg-12">

                                        <label class="control-label">Descripcion daño emergente</label>

                                        <TextArea required={false} value={object?.descripcion_danio_emergente} onChange={handleInputChange}
                                            name={"descripcion_danio_emergente"} placeholder={"Descripción del daño emergente"}
                                            type={"text"}>
                                        </TextArea>



                                    </div>


                                </RowForm>

                            </fieldset>
                        </div>

                        */}

                        <p>&nbsp;</p>



                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Servidumbre</legend>
                                <Row6>
                                    <FormGroup label={"Tipo servidumbre"}>
                                        <Select required={false} value={object?.tipo_servidumbre || ""}
                                            onChange={handleInputChange}
                                            name={"tipo_servidumbre"}>
                                            <ComboOptions data={list_servidumbres} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>








                                </Row6>
                                <Row6>



                                    <FormGroup label={"Periodo (años)"}>
                                        <Input
                                            required={false}
                                            value={object?.periodo_servidumbre || ""}
                                            onChange={handleInputChange}
                                            name={"periodo_servidumbre"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >

                                        </Input>
                                    </FormGroup>





                                </Row6>

                            </fieldset>
                        </div>

                    </RowForm>


                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Certificado de búsqueda catastral CBC</legend>
                                <Row6>
                                    <FormGroup label={"NUMERO DE PUBLICIDAD CBC"}>
                                        <Input
                                            required={false}
                                            value={object?.numero_publicidad_cbc || ""}
                                            onChange={handleInputChange}
                                            name={"numero_publicidad_cbc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"COD. VERIFICACIÓN CBC"}>
                                        <Input
                                            required={false}
                                            value={object?.cod_verificacion_cbc || ""}
                                            onChange={handleInputChange}
                                            name={"cod_verificacion_cbc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"FECHA DE EMISIÓN DE CBC"}>
                                        <Input
                                            required={false}
                                            value={object?.fecha_emision_cbc || ""}
                                            onChange={handleInputChange}
                                            name={"fecha_emision_cbc"}
                                            placeholder={""}
                                            type={"date"}>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"N° INFORME TÉCNICO"}>
                                        <Input
                                            required={false}
                                            value={object?.nro_informe_tecnico || ""}
                                            onChange={handleInputChange}
                                            name={"nro_informe_tecnico"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>




                                </Row6>
                                <Row6>

                                    <FormGroup label={"FECHA DE INFOR. TECNIC"}>
                                        <Input
                                            required={false}
                                            value={object?.fecha_informe_tecnico || ""}
                                            onChange={handleInputChange}
                                            name={"fecha_informe_tecnico"}
                                            placeholder={""}
                                            type={"date"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"ESTADO DE CBC"}>
                                        <Input
                                            required={false}
                                            value={object?.estado_cbc || ""}
                                            onChange={handleInputChange}
                                            name={"estado_cbc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"ANALISIS LEGALCBC"}>
                                        <Input
                                            required={false}
                                            value={object?.analisis_legal_cbc || ""}
                                            onChange={handleInputChange}
                                            name={"analisis_legal_cbc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>








                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>


                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Marco legal en el que se basa el requerimiento</legend>

                                <TextArea required={false} value={object?.marco_legal} onChange={handleInputChange}
                                    name={"marco_legal"} placeholder={"Marco legal en el que se basa el requerimiento"}
                                    type={"text"}>
                                </TextArea>

                            </fieldset>

                        </div>


                    </RowForm>
                    <br />
                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Análisis de la situación legal del predio</legend>

                                <TextArea required={false} value={object?.analisis_situacion_legal} onChange={handleInputChange}
                                    name={"analisis_situacion_legal"} placeholder={"Analisis de la situacion legal del predio"}
                                    type={"text"}>
                                </TextArea>

                            </fieldset>

                        </div>


                    </RowForm>
                    <br />
                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Observaciones</legend>

                                <TextArea required={false} value={object?.observaciones} onChange={handleInputChange}
                                    name={"observaciones"} placeholder={"Ingrese la observación"}
                                    type={"text"}>
                                </TextArea>

                            </fieldset>

                        </div>


                    </RowForm>
                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>

                        <button id="btnguardar" type="submit"
                            className="btn btn-danger btn-sm btn-control">Guardar
                        </button>
                    </FormFooter>
                </Form>
                {modalInvitado && <MAddEditInvitado object={objectModal} closeventana={cerrarModal} usevalue={updatevaluesinvitado} />}
            </WraperLarge>
        </>
    );
};

export default DTPlant;


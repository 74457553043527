import TramoList from "../sigespred/_ddp_tramo/TramoList";
import TramoEdit from "../sigespred/_ddp_tramo/TramoEdit";

const RouteModTramos = [
    {path: "/tramo-list/:id", element: <TramoList/>},
    {path: "/tramo-edit/:id/:ti/:idtramo", element: <TramoEdit/>}
]

export default RouteModTramos;


import Area from "../sigespred/_ddp_area/Area";
import AreaAdd from "../sigespred/_ddp_area/AreaAdd";
import AreaEdit from "../sigespred/_ddp_area/AreaEdit";
import AreaDel from "../sigespred/_ddp_area/AreaDel";

const RouteModAreas = [
    {path: "/area-list", element: <Area/>},
    {path: "/area-add", element: <AreaAdd/>},
    {path: "/area-edit/:id", element: <AreaEdit/>},
    {path: "/area-del/:id", element: <AreaDel/>}
]

export default RouteModAreas;
import React, { useEffect, useState } from "react";
import { initAxiosInterceptors, serverFile } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";

import { toastr } from 'react-redux-toastr';
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {
    Form,
    FormGroup,
    Row6,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table,
    TextArea
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import { useForm } from '../../hooks/useForm';
import { useAsync } from "react-async-hook";
import MAddEditInvitado from "./DLPadronModal";
import TableInvitado from "./DLPadronTable";
import PredioLinks from "./PredioLinksLegal";
import * as helperGets from "../../components/helpers/LoadMaestros";
import MAddAreasLinderos from "./MAddAreasLinderos";
import { MAddGanadera } from "./MAddGanadera";
import { MAddAgricola } from "./MAddAgricola";

import { list_dl_tip_establecimiento, list_dl_actividad, list_tipo_negocio, list_estado_predio, list_condicion_juridica, list_doc_sust_titularidad, list_und_doc_titularidad, list_lucro_cesante, list_cond_negocio, list_dano, list_regimen } from './DTValidadores'

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoPredio (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function getdata (object) {
    //  alert(object.id)
    const { data } = await Axios.get(`/list_pl_padron?predioid=` + object.id);
    console.log(data)
    return data;
}

async function save (object) {
    const { data } = await Axios.post(`/save_pl_padron`, object);
    return data;
}


async function getmd ({ expediente, codpredio, proy, folder, ext }) {
    //  alert(object.id)
    const { data } = await Axios.get(`/generateMD?expediente=${expediente}&codpredio=${codpredio}&proy=${proy}&folder=${folder}&ext=${ext}`);
    console.log(data)
    return data;
}

function generarListaMeses () {

    const meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    let listaMeses = [];
    const añoActual = new Date().getFullYear();

    // Comenzar desde junio del año actual
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual}` });
    }

    // Continuar hasta mayo del año siguiente
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual + 1}` });
    }

    return { listaMeses };
}



const DTPlant = ({ history, match }) => {

    //Obtener los parameotros
    const { id, codpred } = useParams();

    //Definicion de estado del formularios
    const [object, setObject, handleInputChange, reset] = useForm({}, ["profesional_legal_responsable", "sector_localidad", "distrito", "uc",
        "titular_registral", "telefono_contacto", "entidad"]);
    const [predio, setPredio] = useState({});
    const listameses = generarListaMeses();

    const [listaInvitados, setListaInvitados] = useState([]);
    const [urlViewExcel, setUrlViewExcel] = useState("");
    const [modalInvitado, setModalInvitado] = useState(false);
    const [accion, set_accion] = useState('Agregar');
    const [objectModal, setObjectModal] = useState({});



    //Traer el objeto de datos del predio seleccionado
    useEffect(() => {
        const init = async () => {

            //obtenieno los datos predio
            let predio = await getDatoPredio(id);
            setPredio(predio);
            localStorage.setItem("predioselec", JSON.stringify(predio))
            // alert(predio.id)
            let data = await getdata(predio);
            console.log(data)
            setObject({ ...data, predioid: predio.id })
            setListaInvitados(data.titulares || []);


        }
        init();
    }, []);

    const actualizar = async (e) => {
        e.preventDefault();
        toastr.success(
            "Generando reportes",
            "La memoria descriptiva esta siendo generada.",
            { position: "top-center" }
        );
        let report = {

            expediente: predio.codexpediente,
            codpredio: predio.codigopredio,
            proy: 'LST4',
            folder: 'Memorias_Descriptivas',
            ext: '.html'
        }
        let data = await getmd(report)

        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        let port = window.location.port;

        let result = protocol + '//' + hostname + (port ? ':' + port : '');
        result = serverFile + data.filepath
        //result='https://view.officeapps.live.com/op/view.aspx?src='+result


        setUrlViewExcel(result)

        console.log(data)

    }

    const handleClickAddEditInvitado = (e) => {
        setModalInvitado(true);
        setObjectModal({})
    }

    const cerrarModal = (estado) => {
        setModalInvitado(estado);
    }
    /*Gestion de distrito*/

    const updatevaluesinvitado = (invitado) => {

        if (invitado.update) {
            const index = listaInvitados.findIndex(obj => obj.id === invitado.id);
            if (index !== -1) {
                listaInvitados[index] = { ...invitado };
            }
            calcularTotales(listaInvitados);
            setListaInvitados(listaInvitados);


        } else {
            let tam = listaInvitados.length || 0;
            let newListInvitados = [...listaInvitados, { ...invitado, poligono: "ÁREA AFECTADA N° " + (tam + 1), areaTotalM2: (parseFloat(invitado?.areaDirectaM2) + parseFloat(invitado?.areaIndirectaM2)) }]
            setListaInvitados(newListInvitados);
            calcularTotales(newListInvitados);


        }




        setModalInvitado(false);


    }

    const updatedInvitado = (key) => {

        //alert(key)
        var foundObject = listaInvitados.find(function (e) {
            return e.id == key;
        });
        setObjectModal(foundObject);
        setModalInvitado(true);

    };

    const deleteInvitado = key => {

        const toastrConfirmOptions = {
            onOk: () => {
                let data = listaInvitados.filter(function (e) {
                    return e.id !== key;
                });

                setListaInvitados(data);
                calcularTotales(data);

            },
            onCancel: () => { }
        };
        toastr.confirm(`¡Desea eliminar el registro?`, toastrConfirmOptions);





    };
    const calcularTotales = (listaInvitados) => {


        // Actualiza el estado u objeto como sea necesario
        setObject({
            ...object,

            titulares: listaInvitados
        });


    }

    function imprimir () {

        var ventana = window.open(urlViewExcel, "Titulo de la Ventana", "width=600,height=400");

        ventana.onload = function () {
            setTimeout(function () {
                ventana.print();
                ventana.close();
            }, 2500); // Ajustar el tiempo si es necesario
        };


    }

    async function word (e) {

        e.preventDefault();
        toastr.success(
            "Generando reportes",
            "La memoria descriptiva esta siendo generada.",
            { position: "top-center" }
        );
        let report = {
            expediente: predio.codexpediente,
            codpredio: predio.codigopredio,
            proy: 'LST4',
            folder: 'Memorias_Descriptivas',
            ext: '.doc'
        }
        let data = await getmd(report)

        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        let port = window.location.port;

        let result = protocol + '//' + hostname + (port ? ':' + port : '');
        result = serverFile + data.filepath
        //result='https://view.officeapps.live.com/op/view.aspx?src='+result

        window.open(result, "Titulo de la Ventana", "width=600,height=400");


    }




    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / PADRON DT"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >

                <Form onSubmit={actualizar}>
                    <div className="mtop-35"></div>
                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Datos de expediente</legend>
                                <Row6>
                                    <FormGroup label={"Código del predio"} >
                                        <Input
                                            required={false}
                                            value={predio?.codigopredio || ""}
                                            //onChange={handleInputChange}
                                            name={"titulo"}
                                            placeholder={"Ingrese titulo de la entrada"}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                </Row6>
                                <Row6>
                                    <FormGroup label={"Código expediente"} >
                                        <Input
                                            value={predio?.codexpediente || ""}
                                            //onChange={handleInputChange}
                                            name={"fechainspeccion"}
                                            type={"text"}

                                        >
                                        </Input>
                                    </FormGroup>




                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Principales reportes</legend>
                                <Row6>

                                    <button id="btnguardar" type="submit"
                                        className="btn btn-danger btn-sm btn-control">
                                        <i className="fa fa-file"> </i> Memoria Descriptiva
                                    </button>


                                </Row6>
                                <Row6>












                                </Row6>

                                {
                                    urlViewExcel.length >= 1 ? <>
                                        <div className="embed-responsive embed-responsive-16by9">

                                            <iframe id="iframeParaImprimir" className="embed-responsive-item" src={urlViewExcel}
                                                allowFullScreen></iframe>

                                        </div>
                                    </> : null

                                }




                            </fieldset>
                        </div>



                    </RowForm>



                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>

                        <button id="btnguardar" type="button" onClick={imprimir}
                            className="btn btn-danger btn-sm btn-control">Imprimir
                        </button>
                        <button id="btnguardar" type="button" onClick={word}
                            className="btn btn-danger btn-sm btn-control">Word
                        </button>
                    </FormFooter>
                </Form>
                {modalInvitado && <MAddEditInvitado object={objectModal} closeventana={cerrarModal} usevalue={updatevaluesinvitado} />}
            </WraperLarge>
        </>
    );
};

export default DTPlant;


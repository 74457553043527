import React, {Component} from "react";
import Wraper from "../m000_common/formContent/WraperLarge";
import CambiarContrasenia from "../m002_gestion_trabajadores/m002_01_trabajadores/CambiarContrasenia";
import PerfilProfesional from "../m002_gestion_trabajadores/m002_01_trabajadores/PerfilProfesional";
import Entregables from "../m002_gestion_trabajadores/m002_01_trabajadores/Entregables";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {toastr} from "react-redux-toastr";
import { MisActividades } from "../m002_gestion_trabajadores/m002_01_trabajadores/MisActividades";
import  Perfil  from "../_ddp_perfil/Perfil";


class ConfiguracionUsuario extends Component {
    render() {
        return (
            <>
                <Wraper titleForm={"Configuración del Usuario"} listbreadcrumb={[]} header={false}>
                    <Tabs defaultIndex={0}>
                        <TabList>
                            <Tab>
                                Cambiar tu contraseña
                            </Tab>


                            <Tab>
                                Mis Actividades Diarias
                            </Tab>

                            <Tab>
                                Perfil Profesional
                            </Tab>
                            <Tab>
                               Entregables del Mes
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <CambiarContrasenia></CambiarContrasenia>
                        </TabPanel>

                        <TabPanel>
                            <MisActividades></MisActividades>
                        </TabPanel>
                        <TabPanel>
                            <PerfilProfesional></PerfilProfesional>

                        </TabPanel>

                        <TabPanel>
                            <Entregables></Entregables>

                        </TabPanel>

                    </Tabs>
                </Wraper>
            </>
        );

    }
};


export default ConfiguracionUsuario;
import React, { useEffect }  from 'react';
import {Link} from "react-router-dom";
const {$} = window;


export const PredioRow = ( {predio, nro}) => {

    useEffect(() => {
        const init = async () => {
            $('[data-toggle="tooltip"]').tooltip()
        };
        init();
    }, []);

    return (
        <>
             <tr key={`trrowkey_${nro}`}>
                <td key={`tdrowkey_1${nro}`}>{nro+1}</td>
                <td key={`tdrowkey_2${nro}`}>{predio.id}</td>
                <td key={`tdrowkey_3${nro}`}>{predio.codigopredio}</td>
                <td key={`tdrowkey_4${nro}`}>{predio.gestionpredial}</td>
                <td key={`tdrowkey_6${nro}`}>{predio.tramo}</td>
                <td key={`tdrowkey_12${nro}`}>{predio.tipopredio}</td>
                <td key={`tdrowkey_11${nro}`}>{predio.fechacreacion}</td>
                <td key={`tdrowkey_13${nro}`} className="acciones-3bot">
                    <div key={`divrowkey_${nro}`} className="btn-group pull-right">
                        {/* <Link  to={`/solicitud-respuesta/${predio.id}`}  className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-placement="bottom" data-original-title={ "Respuesta a Solicitud" }><i
                            className="fa fa-envelope fa-lg "></i></Link> */}

<Link  to={`/predio-gen-edit/${predio.id}/${predio.codigopredio}`}  className="btn btn-xs btn-default " type="button" data-toggle="tooltip" data-original-title={ "Editar Datos de Predio" }><i
                            className="fa fa-edit fa-lg"></i></Link>
                            <Link  to={`/padron-datos-tecnicos/${predio.id}/${predio.codigopredio}`}  className="btn btn-xs btn-default " type="button" data-toggle="tooltip" data-original-title={ "Datos técnicos" }>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 22" fill="none">
                                <path d="M10.373 0L18.757 5.388L17.675 7.071L17.375 6.878V10.295L20.75 12.357L19.707 14.064L19.375 13.861V21.189H1.375V13.861L1.043 14.064L0 12.357L3.375 10.295V6.878L3.075 7.071L1.992 5.389L10.373 0ZM5.375 5.592V9.072L10.375 6.017L15.375 9.072V5.592L10.373 2.379L5.375 5.592ZM3.375 12.639V19.189H9.375V15.189H11.375V19.189H17.375V12.639L10.375 8.361L3.375 12.639Z" fill="black"/>
                                </svg>
                            </Link>
                            <Link  to={`/predio-datos-legales/${predio.id}/${predio.codigopredio}`}  className="btn btn-xs btn-default " type="button" data-toggle="tooltip" data-original-title={ "Datos legales" }>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 19" fill="none">
                                    <path d="M10.0871 0C8.81709 0 7.68709 0.8 7.26709 2H1.08709V4H3.03709L0.0870901 11C-0.38291 13 1.08709 14 3.58709 14C6.08709 14 7.64709 13 7.08709 11L4.13709 4H7.25709C7.58709 4.85 8.23709 5.5 9.08709 5.83V17H0.0870901V19H20.0871V17H11.0871V5.82C11.9371 5.5 12.5871 4.85 12.9071 4H16.0371L13.0871 11C12.6171 13 14.0871 14 16.5871 14C19.0871 14 20.6471 13 20.0871 11L17.1371 4H19.0871V2H12.9171C12.4871 0.8 11.3571 0 10.0871 0ZM10.0871 2C10.3523 2 10.6067 2.10536 10.7942 2.29289C10.9817 2.48043 11.0871 2.73478 11.0871 3C11.0871 3.26522 10.9817 3.51957 10.7942 3.70711C10.6067 3.89464 10.3523 4 10.0871 4C9.82187 4 9.56752 3.89464 9.37998 3.70711C9.19245 3.51957 9.08709 3.26522 9.08709 3C9.08709 2.73478 9.19245 2.48043 9.37998 2.29289C9.56752 2.10536 9.82187 2 10.0871 2ZM3.58709 7.25L5.08709 11H2.08709L3.58709 7.25ZM16.5871 7.25L18.0871 11H15.0871L16.5871 7.25Z" fill="black"/>
                                    </svg>
                            </Link>
                      



<Link  to={`/predio-reportes/${predio.id}/${predio.codigopredio}`}  className="btn btn-xs btn-default " type="button" data-toggle="tooltip" data-original-title={ "Generacion de reportes" }>
<i
                            className="fa fa-file fa-lg"></i></Link>
                       

                    </div>
                </td>
            </tr>
        </>
    )
}

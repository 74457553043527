import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import {
    Select, Form, Input, FormGroup

} from "../../components/forms";
import SingleUpload from "../../components/uploader/SingleUpload";
import { FilesGestionPredial } from "../../config/parameters";
import ComboOptions from "../../components/helpers/ComboOptions";
import { list_uso_oc, list_pisos, list_unidades_medida, list_material, list_estado_construccion_vivienda, list_estado_conservacion } from './DTValidadores'
import moment from 'moment';

const MObraComplementaria = ({ object, closeventana, usevalue }) => {
    const [invitado, setInvitado] = useState({});
    const [lblaction, setlblaction] = useState("Agregar");

    useEffect(() => {
        function initialLoad () {
            var idDate = moment().format("HHmmss");
            if (object.id) {

                setInvitado({
                    ...object,
                    update: true
                });
                setlblaction('Actualizar')
                // setInvitado(object)

            } else {
                setInvitado({
                    ...invitado,
                    id: idDate,
                    update: false
                });
            }

        }
        initialLoad();
    }, []);

    const closeModal = () => {
        closeventana(false);
    }

    const addUpdateValor = (e) => {

        e.preventDefault();
        usevalue(invitado);

    }

    const handleInputChange = (e) => {


        if ((['m_m2'].includes(e.target.name))) {
            setInvitado({
                ...invitado,
                [e.target.name]: e.target.value
            });
        } else {
            setInvitado({
                ...invitado,
                [e.target.name]: e.target.value.toUpperCase()
            });
        }


    }

    return (
        <>
            <div className={"form-horizontal"}>
                <div>
                    <div id="lightCustomModal_background" className="popup_background backblq"></div>
                    <div id="lightCustomModal_wrapper" className="popup_wrapper bloqueador">
                        <div style={{ transform: 'scale(1)', alignContent: 'left' }}
                            className="custom-popup light  popup_content popup_content_visible bloqueador2"
                            id="lightCustomModal"
                            data-popup-initialized="true" aria-hidden="false" role="dialog" aria-labelledby="open_20531909"
                            tabIndex="-1">
                            <a onClick={closeModal} className="btn  m-right-sm lightCustomModal_close pull-right">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                            <div className=" " style={{ width: '500px' }}>
                                <div className="modal-header">
                                    <h4>{lblaction}</h4>
                                </div>
                                <Form onSubmit={addUpdateValor}>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Tipo
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.tipo || ""}
                                                onChange={handleInputChange}
                                                name={"tipo"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Longitud
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.longitud || ""}
                                                onChange={handleInputChange}
                                                name={"longitud"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Altura
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.altura || ""}
                                                onChange={handleInputChange}
                                                name={"altura"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Ancho
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.ancho || ""}
                                                onChange={handleInputChange}
                                                name={"ancho"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Espesor
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.espesor || ""}
                                                onChange={handleInputChange}
                                                name={"espesor"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Metrado
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.metrado || ""}
                                                onChange={handleInputChange}
                                                name={"metrado"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>




                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> m/m²/
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.m_m2 || ""}
                                                onChange={handleInputChange}
                                                name={"m_m2"}>
                                                <ComboOptions data={list_unidades_medida} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Uso
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.uso || ""}
                                                onChange={handleInputChange}
                                                name={"uso"}>
                                                <ComboOptions data={list_uso_oc} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>



                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> antiguedad_anos
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.antiguedad_anos || ""}
                                                onChange={handleInputChange}
                                                name={"antiguedad_anos"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Material predominante
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.material_predominante || ""}
                                                onChange={handleInputChange}
                                                name={"material_predominante"}>
                                                <ComboOptions data={list_material} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> estado_conservacion
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.estado_conservacion || ""}
                                                onChange={handleInputChange}
                                                name={"estado_conservacion"}>
                                                <ComboOptions data={list_estado_conservacion} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> estado_construccion
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.estado_construccion || ""}
                                                onChange={handleInputChange}
                                                name={"estado_construccion"}>
                                                <ComboOptions data={list_estado_construccion_vivienda} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>


                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> CARACTERÍSTICAS TÉCNICAS
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.caracteristicas_tecnicas || ""}
                                                onChange={handleInputChange}
                                                name={"caracteristicas_tecnicas"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> UBICACIÓN
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.ubicacion || ""}
                                                onChange={handleInputChange}
                                                name={"ubicacion"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"></span> VUOC
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.vuoc || ""}
                                                onChange={handleInputChange}
                                                name={"vuoc"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span> F.D.
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.fd || ""}
                                                onChange={handleInputChange}
                                                name={"fd"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <FormGroup label={"Foto del obra complementaria"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"fotooc"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>


                                    <FormGroup label={"Foto 2 del obra complementaria"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"fotooc2"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>

                                    <FormGroup label={"Foto 3 del obra complementaria"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"fotooc3"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>







                                    <div className="modal-footer">
                                        <button id="btnguardar" type="submit"
                                            className="btn btn-danger btn-sm btn-control">{lblaction}
                                        </button>

                                        <a onClick={closeModal} className="btn btn-default btn-sm btn-control">Cerrar</a>
                                    </div>

                                </Form>

                            </div>


                        </div>
                        <div className="popup_align bloqueador3">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MObraComplementaria;
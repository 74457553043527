import SolicitudAdd  from "../sigespred/_ddp_solicitud/SolicitudAdd";
import SolicitudList  from "../sigespred/_ddp_solicitud/SolicitudList";
import SolicitudEdit from "../sigespred/_ddp_solicitud/SolicitudEdit";
import SolicitudRespuesta from "../sigespred/_ddp_solicitud/solicitudRespuesta";

const RouteModSolicitud = [
    {path: "/solicitud-list", element: <SolicitudList/>},
    {path: "/solicitud-add/:ante?", element: <SolicitudAdd/>},
    {path: "/solicitud-edit/:id", element: <SolicitudEdit/>},
    {path: "/solicitud-respuesta/:id", element: <SolicitudRespuesta/>}
]

export default RouteModSolicitud;
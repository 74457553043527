import React, { useEffect, useState } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {
    Form,
    FormGroup,
    Row6,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import { useForm } from '../../hooks/useForm';
import { useAsync } from "react-async-hook";

import PredioLinks from "./PredioLinksTecnico";
import * as helperGets from "../../components/helpers/LoadMaestros";
import MAddAreasLinderos from "./MAddAreasLinderos";
import { MAddGanadera } from "./MAddGanadera";
import { MAddAgricola } from "./MAddAgricola";

import { isObjectValid, list_estado_predio, list_tipo_pre, list_uso_predio, list_tipo_via, list_lado, list_topografia, list_dano, list_datum } from './DTValidadores'

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoPredio (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function getdata (object) {
    //  alert(object.id)
    const { data } = await Axios.get(`/list_colindanciamatriz?predioid=` + object.id);
    console.log(data)
    return data;
}

async function save (object) {
    const { data } = await Axios.post(`/save_colindanciamatriz`, object);
    return data;
}



const DTPadron = ({ history, match }) => {

    //Obtener los parameotros
    const { id, codpred } = useParams();

    //Definicion de estado del formularios
    const [object, setObject, handleInputChange, reset] = useForm({}, []);
    const [predio, setPredio] = useState({});


    //Traer el objeto de datos del predio seleccionado
    useEffect(() => {
        const init = async () => {

            //obtenieno los datos predio
            let predio = await getDatoPredio(id);
            setPredio(predio);
            //alert(predio.id)
            let data = await getdata(predio);
            console.log(data)
            setObject({ ...data, predioid: predio.id })



        }
        init();
    }, []);

    const actualizar = async (e) => {
        e.preventDefault();


        try {

            await save(object);
            toastr.success(
                "Guardando",
                "El registro fue guardado correctamente.",
                { position: "top-right" }
            );

            //history.push("/blog");
        } catch (e) {

            toastr.error('Ocurrio un error', e.message, { position: 'top-center' })
        }
    }

    /*Gestion de distrito*/





    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / PADRON DT"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >
                <PredioLinks active="2"></PredioLinks>
                <Form onSubmit={actualizar}>
                    <div className="mtop-35"></div>
                    <RowForm>

                        <div className="col-lg-12">
                            {isObjectValid(object) ? <h6 className="obligatorio"> Formulario no guardado </h6> : null}

                            <fieldset className="mleft-20">
                                <legend>Datos de expediente</legend>
                                <Row6>
                                    <FormGroup label={"Código del predio"} >
                                        <Input
                                            required={false}
                                            value={predio?.codigopredio || ""}
                                            //onChange={handleInputChange}
                                            name={"titulo"}
                                            placeholder={"Ingrese titulo de la entrada"}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                </Row6>
                                <Row6>
                                    <FormGroup label={"Código expediente"} >
                                        <Input
                                            value={predio?.codexpediente || ""}
                                            //onChange={handleInputChange}
                                            name={"fechainspeccion"}
                                            type={"text"}

                                        >
                                        </Input>
                                    </FormGroup>




                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Colindancias</legend>
                                <Row6>



                                    <FormGroup label={"norteFrente."}>
                                        <Input
                                            required={false}
                                            value={object?.norteFrente || ""}
                                            onChange={handleInputChange}
                                            name={"norteFrente"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"esteDerecha."}>
                                        <Input
                                            required={false}
                                            value={object?.esteDerecha || ""}
                                            onChange={handleInputChange}
                                            name={"esteDerecha"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"surIzquierda."}>
                                        <Input
                                            required={false}
                                            value={object?.surIzquierda || ""}
                                            onChange={handleInputChange}
                                            name={"surIzquierda"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"oesteFondo."}>
                                        <Input
                                            required={false}
                                            value={object?.oesteFondo || ""}
                                            onChange={handleInputChange}
                                            name={"oesteFondo"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>





                                </Row6>
                                <Row6>

                                    <FormGroup label={"norteFrenteDistancia."}>
                                        <Input
                                            required={false}
                                            value={object?.norteFrenteDistancia || ""}
                                            onChange={handleInputChange}
                                            name={"norteFrenteDistancia"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"esteDerechaDistancia."}>
                                        <Input
                                            required={false}
                                            value={object?.esteDerechaDistancia || ""}
                                            onChange={handleInputChange}
                                            name={"esteDerechaDistancia"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"surIzquierdaDistancia."}>
                                        <Input
                                            required={false}
                                            value={object?.surIzquierdaDistancia || ""}
                                            onChange={handleInputChange}
                                            name={"surIzquierdaDistancia"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"oesteFondoDistancia."}>
                                        <Input
                                            required={false}
                                            value={object?.oesteFondoDistancia || ""}
                                            onChange={handleInputChange}
                                            name={"oesteFondoDistancia"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >
                                        </Input>
                                    </FormGroup>






                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>




                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>

                        <button id="btnguardar" type="submit"
                            className="btn btn-danger btn-sm btn-control">Guardar
                        </button>
                    </FormFooter>
                </Form>

            </WraperLarge>
        </>
    );
};

export default DTPadron;


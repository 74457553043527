import React, {useEffect, useState,createContext} from 'react';
import {Link} from "react-router-dom";
import Wraper from "../m000_common/formContent/WraperLarge";
import {LISTAR_GESTIONPREDIAL_BREADCRUM} from "../../config/breadcrums";
import {initAxiosInterceptors, serverFile} from '../../config/axios';
import TableTrabajador from "./Table";
import TrabajadorRow from "./Row";
import {useForm} from "../../hooks/useForm"
import {useTable} from "../../hooks/useTable";
import Pagination from "react-js-pagination";
const queryString = require('query-string');
const {alasql} = window;

const Axios = initAxiosInterceptors();

async function buscarTrabajador(query) {
    // alert(query)
    const {data} = await Axios.get(`/gestionpredialpaginate?`+ query);
    return data;
}

const GestionPredials = ({history}) => {
    const [busqueda, setBusqueda] = useState('');
    const [activePage,changePage, limit, totalItemsCount,pageRangeDisplayed , list] = useTable();
    const [cargandoGrid, setCargandoGrid] = useState(true);

    useEffect(() => {
        async function init() {
            try {
                let query =  await  queryString.stringify({busqueda,page: activePage, limit});
                let resultList=await buscarTrabajador(query)
                changePage(activePage,resultList);
                setCargandoGrid(false);
            } catch (error) {
                alert('Ocurrio un error')
                console.log(error);
            }
        }
        init();
    }, []);

    const buscarTrabadorFilter = async (e) => {
        setCargandoGrid(true);
        e.preventDefault();
        let query =  await  queryString.stringify({busqueda, page:activePage, limit});
        let list=await buscarTrabajador(query)
        changePage(activePage,list);
        setCargandoGrid(false);
    }

    const descarxls = () => {

        let listexportexcel = list.rows;
        var resultgeojson = alasql(`SELECT *
                 FROM ? `, [listexportexcel])
        var opts = [{
            sheetid: 'Reporte',
            headers: true
        }];
        var res = alasql('SELECT INTO XLSX("ListadoTrabajadores.xlsx",?) FROM ?', [opts, [resultgeojson]]);
        return false;
    }


    const handlePageChange = async (pageNumber) => {
        setCargandoGrid(true);
        let query =  await  queryString.stringify({busqueda, page:activePage, limit});
        let resultList=await buscarTrabajador(query)
        changePage(pageNumber,resultList);
        setCargandoGrid(false);
    }

    const cabecerasTabla = ["TIPO DE INFRAESTRUCTURA", "INFRAESTRUCTURA", "ABREVIATURA", "RESO. MINISTERIAL", "NRO DOCUMENTO", "Acciones"]

    return (
        <>

                <Wraper titleForm={"Listado de Gestion Prediales"} listbreadcrumb={LISTAR_GESTIONPREDIAL_BREADCRUM}>
                    <fieldset className={'fielsettext'}>
                        <form onSubmit={buscarTrabadorFilter}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-group">
                                        <input type="text" className="form-control "
                                               placeholder="Nombre del Proyecto"
                                               onChange={e => setBusqueda(e.target.value)}
                                        ></input>
                                        <span className="input-group-btn">
                                                                <button className="btn btn-default " type="submit"><i
                                                                    className="fa fa-search"></i></button>
                                                            </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Link to={`/gestionpredial-add`} className="btn btn-danger pull-right btn-sm fullborder btn-control">
                                        <i className="fa fa-plus"></i> Agregar </Link>
                                    <button type="button" onClick={descarxls}
                                            className="btn btn-default pull-right btn-sm fullborder">
                                        <i className="fa fa-file-excel-o"></i> Descargar Excel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </fieldset>
                    <div className="panel panel-default">
                    {
                    (cargandoGrid)?
                        <div className="alert alert-danger text-center">Cargando...</div>
                        :
                        (
                        <>
                        <TableTrabajador cabecera={cabecerasTabla}>
                            {list.rows.map((trabajador, i) => (
                                <TrabajadorRow nro={i} row={trabajador}></TrabajadorRow>
                            ))}
                        </TableTrabajador>
                        <div className="panel-footer clearfix pull-right">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={limit}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                            ></Pagination>
                        </div>
                        </>
                        )
                    }
                    </div>
                </Wraper>
        </>
    );

}


export default GestionPredials;
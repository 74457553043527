import Equipo from "../sigespred/_ddp_equipo/Equipo";
import EquipoAdd from "../sigespred/_ddp_equipo/EquipoAdd";
import EquipoDel from "../sigespred/_ddp_equipo/EquipoDel"
import EquipoEdit from "../sigespred/_ddp_equipo/EquipoEdit"

const RouteModEquipos = [
    {path: "/list-equipos2", element: <Equipo/>},
    {path: "/equipo-add", element: <EquipoAdd/>},
    {path: "/equipo-edit/:id", element: <EquipoEdit/>},
    {path: "/equipo-del/:id", element: <EquipoDel/>}
]

export default RouteModEquipos;
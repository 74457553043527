import React, { useEffect, useState } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import WraperLarge from "../m000_common/formContent/WraperLarge";
import SingleUpload from "../../components/uploader/SingleUpload";
import { subirExcel } from "../../utils";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
    Form,
    FormGroup,
    Row6,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import { useForm } from '../../hooks/useForm';
import { useAsync } from "react-async-hook";
import MAddEditInvitado from "./DTAreaAfectadaModal";
import TableInvitado from "./DTCuadroDatosTecnicoTable";
import TableColindanciasTable from "./DTCuadroColindanciasTable";
import DTAreaAfectadaTable from "./DTAreaAfectadaDTTable";
import PredioLinks from "./PredioLinksTecnico";
import * as helperGets from "../../components/helpers/LoadMaestros";
import MAddAreasLinderos from "./MAddAreasLinderos";
import { MAddGanadera } from "./MAddGanadera";
import { MAddAgricola } from "./MAddAgricola";


import { isObjectValid, list_estado_predio, list_tipo_pre, list_uso_predio, list_tipo_via, list_lado, list_topografia, list_dano, list_datum } from './DTValidadores'

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoPredio (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function getdataAF (object) {
    //  alert(object.id)
    const { data } = await Axios.get(`/list_area_afectada?predioid=` + object.id);
    console.log(data)
    return data;
}



async function getdataCDT (object) {
    //  alert(object.id)
    const { data } = await Axios.get(`/list_cdt?predioid=` + object.id);
    console.log(data)
    return data;
}

async function saveCDT (object) {
    const { data } = await Axios.post(`/save_cdt`, object);
    return data;
}


function agregarCampo (array, nuevoCampo, valorCampo) {
    array.forEach(objeto => {
        objeto[nuevoCampo] = valorCampo;
    });
    return array;
}




const DTAreaAfectada = ({ history, match }) => {

    //Obtener los parameotros
    const { id, codpred } = useParams();

    //Definicion de estado del formularios
    const [object, setObject, handleInputChange, reset] = useForm({}, []);
    const [areasAfectadas, setAreasAfectadas] = useState();
    const [predio, setPredio] = useState({});


    const [listAreaAfectada, setListaAreaAfectada] = useState([]);
    const [listaCDT, setListaCDT] = useState({ "NINGUNO": [] });
    const [listaColindancias, setListaColindancias] = useState({ "NINGUNO": [] });
    const [listacoordenadas, setListacoordenadas] = useState([]);
    const [listacoordcolindancias, setListacoordcolindancias] = useState([]);
    const [modalInvitado, setModalInvitado] = useState(false);
    const [accion, set_accion] = useState('Agregar');
    const [objectModal, setObjectModal] = useState({});
    const [areaseleccionada, setareaseleccionada] = useState("NINGUNO");

    //archivo
    const [invitado, setInvitado] = useState({});

    //Traer el objeto de datos del predio seleccionado
    useEffect(() => {
        const init = async () => {

            //obtenieno los datos predio
            let predio = await getDatoPredio(id);
            setPredio(predio);
            //cargando los datos tecnicos
            let dataCDT = await getdataCDT(predio);

            let dataAF = await getdataAF(predio);

            setObject({ ...dataCDT, predioid: predio.id })
            setListaCDT(dataCDT.detalle);
            setListaColindancias(dataCDT.colindancias);
            //Cargando las areas afectadas
            // setAreasAfectadas(getdata(predio))

            //Cargando la lista de areas afectadas
            setListaAreaAfectada(dataAF.listAreas || []);
        }
        init();
    }, []);

    const actualizar = async (e) => {
        e.preventDefault();
        try {


            object.detalle = listaCDT;
            object.colindancias = listaColindancias;
            await saveCDT(object);
            toastr.success(
                "Guardando",
                "El registro fue guardado correctamente.",
                { position: "top-right" }
            );

            //history.push("/blog");
        } catch (e) {

            toastr.error('Ocurrio un error', e.message, { position: 'top-center' })
        }
    }

    const selectAreaAfectada = (areaafectada) => {

        setareaseleccionada(areaafectada)

        if (listaCDT[areaafectada] == undefined) {
            setListacoordenadas([])
        } else {
            setListacoordenadas(listaCDT[areaafectada])
        }

        if (listaColindancias[areaafectada] == undefined) {
            setListacoordcolindancias([])
        } else {
            setListacoordcolindancias(listaColindancias[areaafectada])
        }




    }

    const cerrarModal = (estado) => {
        setModalInvitado(estado);
    }
    /*Gestion de distrito*/







    const subirCDT = async (e) => {
        e.preventDefault();

        try {
            if (areaseleccionada == 'NINGUNO') {
                toastr.error(`Seleccione un areaa afectada`);
                return
            }

            //var output = document.getElementById('output');
            var file = document.getElementById('fileDatosTecnicos').files[0];
            //var file2 = document.getElementById('fileDatosTecnicos').files[0];
            if (file) {
                let result = await subirExcel(file, [
                    "NRO",
                    "EXPEDIENTE",
                    "POLIGONO",
                    "DATUM",
                    "USO_DT",
                    "FORMULA1",
                    "FORMULA2",
                    "FORMULA3",
                    "VERTICE",
                    "LADO",
                    "DISTANCIA_M",
                    "ANG_INTERNO",
                    "ESTE",
                    "NORTE",
                    "ESTE2",
                    "NORTE2",
                    "FORMULA4",
                    "LADO2",
                    "COLINDANCIA",
                    "COLINDANCIA_MINUSCULA",
                    "SUMA_LADOS",
                    "COLINDANCIA_CBC"
                ]);

                result = agregarCampo(result, "AREA_AFECTADA", areaseleccionada)




                setListaCDT({ ...listaCDT, [areaseleccionada]: result })

                setListacoordenadas(result)

                toastr.info(`Se actualizo correctamente el CDT`);
            } else {
                toastr.error(`Seleccione un archivo`);
                //showNotification('top', 'right', "No se seleccionó ningún archivo", 3);
            }
            //File 2
            if (file) {
                let result2 = await subirExcel(file, ["NRO", "TIPO", "EXPEDIENTE", "FORMULA2", "POLIGONO", "DATUM", "USO_DT", "LADO", "FORMULA", "COLINDANCIA", "SUMA_LADOS", "COLINDANCIA_CBC"]
                    , 1);

                result2 = agregarCampo(result2, "AREA_AFECTADA", areaseleccionada)

                setListaColindancias({ ...listaColindancias, [areaseleccionada]: result2 })
                setListacoordcolindancias(result2);

                toastr.info(`Se actualizo correctamente las Colindancias`);
            } else {
                toastr.error(`Seleccione un archivo`);
                //showNotification('top', 'right', "No se seleccionó ningún archivo", 3);
            }

        } catch (error) {
            debugger
            toastr.error(`No contiene el formato solicitado`);
        }



    }





    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / PADRON DT"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >
                <PredioLinks active="4"></PredioLinks>
                <Form onSubmit={actualizar}>
                    <div className="mtop-35"></div>
                    <RowForm>

                        <div className="col-lg-12">
                            {isObjectValid(object) ? <h6 className="obligatorio"> Formulario no guardado </h6> : null}

                            <fieldset className="mleft-20">
                                <legend>Datos de expediente</legend>
                                <Row6>
                                    <FormGroup label={"Código del predio"} >
                                        <Input
                                            required={false}
                                            value={predio?.codigopredio || ""}
                                            //onChange={handleInputChange}
                                            name={"titulo"}
                                            placeholder={"Ingrese titulo de la entrada"}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>
                                </Row6>
                                <Row6>
                                    <FormGroup label={"Código expediente"} >
                                        <Input
                                            value={predio?.codexpediente || ""}
                                            //onChange={handleInputChange}
                                            name={"fechainspeccion"}
                                            type={"text"}
                                        >
                                        </Input>
                                    </FormGroup>
                                </Row6>
                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Listado de areas Afectadas</legend>

                                <Row12>
                                    <DTAreaAfectadaTable
                                        data={listAreaAfectada.filter(objeto => objeto.usoDt === "AFECTADA")}
                                        selectAreaAfectada={selectAreaAfectada}
                                        objcordenadas={listaCDT}
                                        updateObject={null}
                                    >

                                    </DTAreaAfectadaTable>
                                </Row12>

                            </fieldset>
                        </div>





                    </RowForm>


                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Digital de cuadro en excel
                                </legend>

                                <RowForm>
                                    <Row6>
                                        <FormGroup label={"Area seleccionada."}>


                                            <label>{areaseleccionada}</label>
                                        </FormGroup>

                                    </Row6>
                                    <Row6>

                                    </Row6>
                                </RowForm>
                                <RowForm>
                                    <Row6>
                                        <FormGroup label={"Descarga plantilla de carga de datos."}>


                                            <a href="https://drive.google.com/uc?id=17nJ9tRmPRJYtE8bvhIEkAv_fjMf50CcX&export=download" target="blank" id="btnguardar" type="button"
                                                className="btn btn-danger btn-sm ">
                                                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                                            </a>
                                        </FormGroup>

                                    </Row6>
                                    <Row6>

                                    </Row6>
                                </RowForm>
                                <Row6>
                                    <FormGroup label={"Archivo de datos tecnicos"}>
                                        <input id="fileDatosTecnicos" name='fileDatosTecnicos' required="" className=" input-sm" type="file" accept=".xlsx"
                                        ></input>

                                    </FormGroup>

                                </Row6>
                                <Row6>
                                    <button id="btnguardar" type="button" onClick={subirCDT}
                                        className="btn btn-danger btn-sm btn-control">
                                        <i class="fa fa-upload" aria-hidden="true"></i> Subir CDT
                                    </button>
                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>
                        <div className="col-lg-12">
                            <fieldset className="mleft-20">

                                <legend>Cuadro de datos técnicos y colindancias</legend>

                                <Tabs defaultIndex={0}>
                                    <TabList>
                                        <Tab>
                                            Cuadro de datos técnicos
                                        </Tab>


                                        <Tab>
                                            Colindancias
                                        </Tab>


                                    </TabList>
                                    <TabPanel>
                                        <Row12>
                                            <TableInvitado
                                                data={areaseleccionada == 'NINGUNO' ? [] : listacoordenadas}
                                                deleteObject={null}
                                                updateObject={null}
                                            >

                                            </TableInvitado>
                                        </Row12>
                                    </TabPanel>

                                    <TabPanel>
                                        <Row12>
                                            <TableColindanciasTable
                                                data={areaseleccionada == 'NINGUNO' ? [] : listacoordcolindancias}
                                                deleteObject={null}
                                                updateObject={null}
                                            >

                                            </TableColindanciasTable>
                                        </Row12>
                                    </TabPanel>


                                </Tabs>



                            </fieldset>


                        </div>




                    </RowForm>


                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>

                        <button id="btnguardar" type="submit"
                            className="btn btn-danger btn-sm btn-control">Guardar
                        </button>
                    </FormFooter>
                </Form>

            </WraperLarge>
        </>
    );
};

export default DTAreaAfectada;


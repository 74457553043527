import React from 'react';

const Input = ({required,type,placeholder,name,onChange,value,pattern,title,readonly=false,autocomplete="on",maxlength,disabled=false,minlength}) => {
    return (
        <>
            <input required={ required ? "required": ""}
                   type={type}
                   className="form-control input-sm center-date"
                   placeholder={placeholder}
                   id={name}
                   name={name}
                   disabled={disabled}
                   onChange={onChange}
                   value={value}
                   pattern={pattern}
                   title={title}
                   readOnly={readonly}
                   autoComplete={autocomplete}
                   maxlength={maxlength}
                   minlength={minlength}
            >
            </input>
        </>
    );
};

export default Input;
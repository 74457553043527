import React, { useEffect, useState } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {
    Form,
    FormGroup,
    Row6,
    Row4,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table,
    TextArea
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import { useForm } from '../../hooks/useForm';
import { useAsync } from "react-async-hook";
import MAddEditInvitado from "./DLPadronModal";
import TableInvitado from "./DLPadronTable";
import PredioLinks from "./PredioLinksTecnico";
import * as helperGets from "../../components/helpers/LoadMaestros";
import MAddAreasLinderos from "./MAddAreasLinderos";
import { MAddGanadera } from "./MAddGanadera";
import { MAddAgricola } from "./MAddAgricola";

import { isObjectValid, list_servidumbres, list_forma_afectacion, list_unidad_tiempo, list_entidad_titularidad, list_dl_tip_establecimiento, list_dl_actividad, list_tipo_negocio, list_estado_predio, list_condicion_juridica, list_doc_sust_titularidad, list_und_doc_titularidad, list_lucro_cesante, list_cond_negocio, list_dano, list_regimen } from './DTValidadores'

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoPredio (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function getdata (object) {
    //  alert(object.id)
    const { data } = await Axios.get(`/list_pl_padron?predioid=` + object.id);
    console.log(data)
    return data;
}

async function save (object) {
    const { data } = await Axios.post(`/save_pl_padron`, object);
    return data;
}



function generarListaMeses () {

    const meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    let listaMeses = [];
    const añoActual = new Date().getFullYear();

    // Comenzar desde junio del año actual
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual}` });
    }

    // Continuar hasta mayo del año siguiente
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual + 1}` });
    }

    return { listaMeses };
}

async function getListaTrabajadores (object) {
    const { data } = await Axios.get(`/usuario?busqueda=&limit=10000&page=1`);

    let trabajadores = data?.rows.map(({ id, nombres, apellidos }) => ({
        id,
        nombreCompleto: `${apellidos} ${nombres}`
    }));

    // Ordenar alfabéticamente por nombre completo
    trabajadores.sort((a, b) => a.nombreCompleto.localeCompare(b.nombreCompleto));

    return trabajadores;
}



const DTPlant = ({ history, match }) => {

    //Obtener los parameotros
    const { id, codpred } = useParams();

    //Definicion de estado del formularios
    const iniciales = {
        marco_legal: `ARTICULO 15  DEL TEXTO ÚNICO ORDENADO DEL DECRETO LEGISLATIVO N°1192, QUE APRUEBA LA "LEY MARCO DE ADQUISICION Y EXPROPIACION DE INMUEBLES, TRANSFERENCIA DE INMUEBLES DE PROPIEDAD DEL ESTADO, LIBERACION DE INTERFERENCIAS Y DICTA OTRAS MEDIDAS PARA LA EJECUCION DE OBRAS DE INFRAESTRUCTURA", APROBADO MEDIANTE DECRETO SUPREMO N°015-2020-VIVIENDA, QUE ESTABLECE: "EL EXPEDIENTE TÉCNICO LEGAL, DEBIDAMENTE SUSTENTADO, QUE CONTIENE LA SOLICITUD DE TASACIÓN QUE PRESENTE EL SUJETO ACTIVO AL ÓRGANO ENCARGADO DE LA TASACIÓN, CUMPLE COMO MÍNIMO CON LOS SIGUIENTES REQUISITOS, BAJO RESPONSABILIDAD:
15.1 DOCUMENTOS DEL PREDIO: A) MEMORIA DESCRIPTIVA........", ENTRE OTROS DOCUMENTOS.`,
        analisis_situacion_legal: ` EL TITULAR DEL PREDIO ACREDITA SU DERECHO DE PROPIEDAD DEL INMUEBLE, EN MERITO A LA PARTIDA REGISTRAL N° <nro-registral> DEL REGISTRO DE PREDIOS DE LIMA, ZONA REGISTRAL N° IX- SEDE LIMA, EN CUMPLIMIENTO AL ART. 6.1  DEL TEXTO UNICO ORDENADO DEL DECRETO LEGISLATIVO N° 1192.`

    }
    const [object, setObject, handleInputChange, reset] = useForm(iniciales, ["profesional_legal_responsable", "sector_localidad", "distrito", "uc",
        "titular_registral", "telefono_contacto", "entidad"]);
    const [predio, setPredio] = useState({});
    const listameses = generarListaMeses();

    const [listaInvitados, setListaInvitados] = useState([]);
    const [modalInvitado, setModalInvitado] = useState(false);
    const [accion, set_accion] = useState('Agregar');
    const [objectModal, setObjectModal] = useState({});

    const [listaTrabajadores, setListaTrabajadores] = useState({
        trabajadores: [
            { "id": "ACTUAL", "nombreCompleto": "ACTUAL" },
            { "id": "RETROESPECTIVA", "nombreCompleto": "RETROESPECTIVA" },

        ]
    });



    //Traer el objeto de datos del predio seleccionado
    useEffect(() => {
        const init = async () => {

            //obtenieno los datos predio
            let predio = await getDatoPredio(id);
            setPredio(predio);
            localStorage.setItem("predioselec", JSON.stringify(predio))

            let trabajadores = await getListaTrabajadores()
            setListaTrabajadores({
                trabajadores: trabajadores
            });
            // alert(predio.id)
            let data = await getdata(predio);
            console.log(data)
            setObject({ ...data, predioid: predio.id })
            setListaInvitados(data.titulares || []);


        }
        init();
    }, []);

    const actualizar = async (e) => {
        e.preventDefault();
        try {

            await save(object);
            toastr.success(
                "Guardando",
                "El registro fue guardado correctamente.",
                { position: "top-right" }
            );

            //history.push("/blog");
        } catch (e) {

            toastr.error('Ocurrio un error', e.message, { position: 'top-center' })
        }
    }

    const handleClickAddEditInvitado = (e) => {
        setModalInvitado(true);
        setObjectModal({})
    }

    const cerrarModal = (estado) => {
        setModalInvitado(estado);
    }
    /*Gestion de distrito*/

    const updatevaluesinvitado = (invitado) => {

        if (invitado.update) {
            const index = listaInvitados.findIndex(obj => obj.id === invitado.id);
            if (index !== -1) {
                listaInvitados[index] = { ...invitado };
            }
            calcularTotales(listaInvitados);
            setListaInvitados(listaInvitados);


        } else {
            let tam = listaInvitados.length || 0;
            let newListInvitados = [...listaInvitados, { ...invitado, poligono: "ÁREA AFECTADA N° " + (tam + 1), areaTotalM2: (parseFloat(invitado?.areaDirectaM2) + parseFloat(invitado?.areaIndirectaM2)) }]
            setListaInvitados(newListInvitados);
            calcularTotales(newListInvitados);


        }




        setModalInvitado(false);


    }

    const updatedInvitado = (key) => {

        //alert(key)
        var foundObject = listaInvitados.find(function (e) {
            return e.id == key;
        });
        setObjectModal(foundObject);
        setModalInvitado(true);

    };

    const deleteInvitado = key => {

        const toastrConfirmOptions = {
            onOk: () => {
                let data = listaInvitados.filter(function (e) {
                    return e.id !== key;
                });

                setListaInvitados(data);
                calcularTotales(data);

            },
            onCancel: () => { }
        };
        toastr.confirm(`¡Desea eliminar el registro?`, toastrConfirmOptions);





    };
    const calcularTotales = (listaInvitados) => {


        // Actualiza el estado u objeto como sea necesario
        setObject({
            ...object,

            titulares: listaInvitados
        });


    }




    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / PADRON DT"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >
                <PredioLinks active="11"></PredioLinks>
                <Form onSubmit={actualizar}>
                    <div className="mtop-35"></div>
                    <RowForm>

                        <div className="col-lg-12">
                            {isObjectValid(object) ? <h6 className="obligatorio"> Formulario no guardado </h6> : null}

                            <fieldset className="mleft-20">
                                <legend>Datos de expediente</legend>
                                <Row6>
                                    <FormGroup label={"Código del predio"} >
                                        <Input
                                            required={false}
                                            value={predio?.codigopredio || ""}
                                            //onChange={handleInputChange}
                                            name={"titulo"}
                                            placeholder={"Ingrese titulo de la entrada"}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>




                                </Row6>
                                <Row6>
                                    <FormGroup label={"Código expediente"} >
                                        <Input
                                            value={predio?.codexpediente || ""}
                                            //onChange={handleInputChange}
                                            name={"fechainspeccion"}
                                            type={"text"}

                                        >
                                        </Input>
                                    </FormGroup>




                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>



                    <RowForm>



                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Daño emergente</legend>

                                <Row4>


                                    <FormGroup label={"Daño emergente."}>
                                        <Select value={object?.danoEmergente || ""}
                                            onChange={handleInputChange}
                                            name={"danoEmergente"}>
                                            <ComboOptions data={list_dano} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>
                                    <FormGroup label={"Forma de afectación"}>

                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_forma_afectacion || ""}
                                            onChange={handleInputChange}
                                            name={"de_forma_afectacion"}>
                                            <ComboOptions data={list_forma_afectacion} valorkey="id" valornombre="descripcion" />
                                        </Select>


                                    </FormGroup>

                                    <FormGroup label={"Gastos de búsqueda --->"}>

                                    </FormGroup>

                                    <FormGroup label={"Traslado de enseres --->"}>

                                    </FormGroup>

                                    <FormGroup label={"Alquiler de inmueble temporal --->"}>

                                    </FormGroup>



                                </Row4>

                                <Row4>
                                    <FormGroup label={"UNIDAD DE TIEMPO"}>

                                    </FormGroup>
                                    <FormGroup label={"."}>

                                    </FormGroup>

                                    <FormGroup label={"Unidad de tiempo"}>
                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_gasto_busqueda_ut || ""}
                                            onChange={handleInputChange}
                                            name={"de_gasto_busqueda_ut"}>
                                            <ComboOptions data={list_unidad_tiempo} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>


                                    <FormGroup label={"Unidad de tiempo"}>
                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_trasl_enseres_ut || ""}
                                            onChange={handleInputChange}
                                            name={"de_trasl_enseres_ut"}>
                                            <ComboOptions data={list_unidad_tiempo} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>

                                    <FormGroup label={"Unidad de tiempo"}>
                                        <Select disabled={object?.danoEmergente === 'NO'} required={false} value={object?.de_alqu_inmueble_ut || ""}
                                            onChange={handleInputChange}
                                            name={"de_alqu_inmueble_ut"}>
                                            <ComboOptions data={list_unidad_tiempo} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>

                                </Row4>

                                <Row4>
                                    <FormGroup label={"CANTIDAD"}>

                                    </FormGroup>
                                    <FormGroup label={"."}>

                                    </FormGroup>

                                    <FormGroup label={"Cantidad"}>
                                        <Input disabled={object?.danoEmergente === 'NO'}
                                            required={false}
                                            value={object?.de_gasto_busqueda_cant || ""}
                                            onChange={handleInputChange}
                                            name={"de_gasto_busqueda_cant"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >

                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"Cantidad"}>
                                        <Input
                                            disabled={object?.danoEmergente === 'NO'}
                                            required={false}
                                            value={object?.de_trasl_enseres_cant || ""}
                                            onChange={handleInputChange}
                                            name={"de_trasl_enseres_cant"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >

                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"Cantidad"}>
                                        <Input
                                            disabled={object?.danoEmergente === 'NO'}
                                            required={false}
                                            value={object?.de_alqu_inmueble_cant || ""}
                                            onChange={handleInputChange}
                                            name={"de_alqu_inmueble_cant"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >

                                        </Input>
                                    </FormGroup>



                                </Row4>


                                <RowForm>

                                    <div className="col-lg-12">

                                        <label class="control-label">Descripcion daño emergente</label>

                                        <TextArea required={false} value={object?.descripcion_danio_emergente} onChange={handleInputChange}
                                            name={"descripcion_danio_emergente"} placeholder={"Descripción del daño emergente"}
                                            type={"text"}>
                                        </TextArea>



                                    </div>


                                </RowForm>

                            </fieldset>
                        </div>

                        <p>&nbsp;</p>




                    </RowForm>






                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>

                        <button id="btnguardar" type="submit"
                            className="btn btn-danger btn-sm btn-control">Guardar
                        </button>
                    </FormFooter>
                </Form>
                {modalInvitado && <MAddEditInvitado object={objectModal} closeventana={cerrarModal} usevalue={updatevaluesinvitado} />}
            </WraperLarge>
        </>
    );
};

export default DTPlant;


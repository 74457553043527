import React, {useState, useEffect} from 'react';
import {initAxiosInterceptors} from '../../config/axios';
import moment from 'moment';
import {REGISTRO_PLANO_BREADCRUM} from "../../config/breadcrums";
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {Link} from "react-router-dom";
import {toastr} from 'react-redux-toastr'
import { useAsync } from "react-async-hook";
import ComboOptions from "../../components/helpers/ComboOptions";
import Autocomplete from '../../components/helpers/Autocomplete';
import SubLista from './SubListaDelete';
import * as helperGets from "../../components/helpers/LoadMaestros";
import * as PARAMS from "../../config/parameters";
import {useDispatch} from 'react-redux';
import UploadMemo from "../../components/helpers/uploaders/UploadMemo";
import {getselectProyecto} from '../../utils';

const {$} = window;
const Axios = initAxiosInterceptors();
const directorioPlanos = "FilesDDP/planosadmin";

const PlanoAdd = ({history,  match}) => {
    const [plano, set_plano] = useState({observaciones: ''});
    const [planoArchTmp, set_planoArchTmp] = useState({digital: '', memdescriptiva: ''});
    const resListaTipoPlano = useAsync(helperGets.helperGetListTipoPlano, [""]);
    const resListaProyectos = useAsync(helperGets.helperGetListProyectos, []);
    const resListaAnios = useAsync(helperGets.helperGetListDetalle, [PARAMS.LISTASIDS.ANIO]);
    const resListaProcesos = useAsync(helperGets.helperGetListDetalle, [PARAMS.LISTASIDS.PROCESOSGPRED]);
    const resListaDepartmento = useAsync(helperGets.helperGetListDepartamento, []);
    const resListaProvincia = useAsync(helperGets.helperGetListProvincia,[]);
    const resListaDistrito = useAsync(helperGets.helperGetListDistrito,[]);
    const resListaEstadosPlano = useAsync(helperGets.helperGetListDetalle, [PARAMS.LISTASIDS.ESTADOPLANO]);
    const resListaSolicitantes = useAsync(helperGets.helperGetListaLocadores, []);

    const [dataProv, set_dataProv] = useState(null);
    const [dataDist, set_dataDist] = useState(null);
    const [dataTramo, setDataTramo] = useState(null);
    const [listaArchivos, set_listaArchivos] = useState([]);
    const [valAncedente, setValAntecedente] = useState('');
    const [reiniciarValDigital, setReiniciarValDigital] = useState(false);
    const [reiniciarValMemoria, setReiniciarValMemoria] = useState(false);

    const {ante} = match.params;

    useEffect(() => {
        async function initialLoad() {
            try {
                var datosProyecto =  getselectProyecto();
                if (datosProyecto) {
                    set_plano({
                        ...plano,
                        gestionpredialid: datosProyecto.idproyecto
                    });
                    setValoresTramo(datosProyecto.idproyecto);
                }
            } catch (error) {
                console.log(error);
            }
        }
        initialLoad();
    }, []);

    if(ante && !valAncedente){
        setValAntecedente(ante);
        set_plano({
            ...plano,
            antecedente: ante
        });
    }

    const handleChangeProyecto = async(e) => {
        if (e.target.value) {
            setValoresTramo(e.target.value);
        } else {
            setDataTramo(null);
        }
    }

    const setValoresTramo = async(idgestionpredial) => {
        let data = await helperGets.helperGetListTramos(idgestionpredial);
        setDataTramo(data);
    }

    function handleChangeDepartmento(e) {
        if(!resListaProvincia.loading){
            let data = resListaProvincia.result;
            let provList = data[Object.keys(data)[0]].filter( o => o.id_dpto === e.target.value);
            set_dataProv({data: provList});
            set_dataDist(null);
        }
    }

    function handleChangeProvincia(e) {
        if(!resListaDistrito.loading){
            let data = resListaDistrito.result;
            let distList = data[Object.keys(data)[0]].filter( o => o.id_prov === e.target.value);
            set_dataDist({data: distList});
        }
    }

    function handleInputChange(e) {
        switch(e.target.name){
            case 'nroexpediente':
                set_plano({
                    ...plano,
                    [e.target.name]: e.target.value.toUpperCase()
                });
                break;
            case 'gestionpredialid':
                set_plano({
                    ...plano,
                    [e.target.name]: e.target.value,
                    tramoid: ''
                });
                break;
            case 'departamentoid':
                set_plano({
                    ...plano,
                    [e.target.name]: e.target.value,
                    provinciaid: '',
                    distritoid: ''
                });
                break;
                case 'provinciaid':
                    set_plano({
                        ...plano,
                        [e.target.name]: e.target.value,
                        distritoid: ''
                    });
                    break;
            default:
                set_plano({
                    ...plano,
                    [e.target.name]: e.target.value
                });
        }
    }

    const saveArchivoDigital = (file) => {
        setReiniciarValDigital(false);
        console.log(file);
        console.log('saveArchivoDigital')
        set_planoArchTmp({
            ...planoArchTmp,
            "digital": file
        });
        console.log(planoArchTmp);
    }

    const saveArchivoMemoria = (file) => {
        setReiniciarValMemoria(false);
        set_planoArchTmp({
            ...planoArchTmp,
            "memoria": file
        });
    }

    const deleteArchivoDigital = () => {
        set_planoArchTmp({
            ...planoArchTmp,
            "digital": ''
        });
    }

    const deleteArchivoMemoria = () => {
        set_planoArchTmp({
            ...planoArchTmp,
            "memoria": ''
        });
    }

    const handleChangeLamina = (e) => {
        var uidDate = moment().format("YYYYMMDDHHmmss");
        console.log(planoArchTmp);
        console.log('paso por aqui');
        set_planoArchTmp({
            ...planoArchTmp,
            "lamina": e.target.value,
            "laminaid": uidDate,
        });
    }

    const actualizarLista = () => {
        console.log('planoArchTmp');
        console.log(planoArchTmp);
        if (planoArchTmp.lamina && planoArchTmp.digital) {
            set_listaArchivos(listaArchivos => [...listaArchivos, planoArchTmp]);
            set_planoArchTmp({
                ...planoArchTmp,
                "lamina": '',
                "laminaid": '',
                "digital": '',
                "memoría": ''
            });
            setReiniciarValDigital(true);
            setReiniciarValMemoria(true);
        } else {
            toastr.error(`Se require al menos un identificador o descripción de lámina y el plano digital.`)
        }
    }

    const removerDeLista = (idLamina) => {
        var data = $.grep(listaArchivos, function(e){
            return e.laminaid != idLamina;
       });
       set_listaArchivos(data);
    }

    function setSolicitante(idLocador) {
        set_plano({
            ...plano,
            profesionalid: idLocador
        });
    }

    const setValorProyecto = () => {
        console.log('valor');
    }

    async function addPlano(plano) {
        const {data} = await Axios.post(`/plano`,plano);
        return data;
    }

    const registrar = async e => {

        e.preventDefault();

        $.each(plano, function(key, value){
            if (key === 'tramoid' && (value === "" || value === null)){
                delete plano[key];
            }
        });

        if (Array.isArray(listaArchivos) && listaArchivos.length) {
            plano.archivos = listaArchivos;
            set_plano({
                ...plano,
                archivos: listaArchivos
            });
        }

        $('#btnguardar').button('loading');
        try {
            let resultPlano = await addPlano(plano);
            $('#btnguardar').button('reset');
            toastr.success('Registro de Plano', `El plano ${resultPlano.codplano} fue ingresado correctamente.`);
            history.push('/planos');
        }
        catch (e) {
            toastr.error('Registro de Plano', "Se encontró un error: " +  e.message);
            $('#btnguardar').button('reset');
        }
    }

    const cabeceraArchivos = ["Desc./Lámina","Plano Digital", "Mem. Descriptiva", "Eliminar"];

        return (
            <>
            <WraperLarge titleForm={"Registro de Plano"} listbreadcrumb={REGISTRO_PLANO_BREADCRUM}>
                <form onSubmit={registrar} className={"form-horizontal"}>
                    <div className="form-group">
                        <div className="form-group col-lg-6">
                            <fieldset className="mleft-20">
                                <legend>Datos de Codificación</legend>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">
                                        <span className="obligatorio">* </span>Tipo de Plano
                                    </label>
                                    <div className="col-lg-8">
                                        <select className="form-control input-sm" id="tipoplanoid" name="tipoplanoid"
                                        required
                                        title="El Tipo de Plano es requerido"
                                        onChange={handleInputChange}
                                        >
                                            <option value="">--SELECCIONE--</option>
                                            {resListaTipoPlano.error
                                            ? "Se produjo un error cargando los tipos de plano"
                                            : resListaTipoPlano.loading
                                            ? "Cargando..."
                                            : <ComboOptions data={resListaTipoPlano.result} valorkey="id" valornombre="descripcion" />}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">
                                        <span className="obligatorio">* </span>Proyecto
                                    </label>
                                    <div className="col-lg-8">
                                        <select className="form-control input-sm" id="gestionpredialid" name="gestionpredialid"
                                        required
                                        title="El Proyecto es requerido"
                                        value={plano.gestionpredialid || ""}
                                        onChange={(e) => {handleChangeProyecto(e); handleInputChange(e);}}>
                                            <option value="">--SELECCIONE--</option>
                                            {resListaProyectos.error
                                            ? "Se produjo un error cargando los proyectos"
                                            : resListaProyectos.loading
                                            ? "Cargando..."
                                            : <ComboOptions data={resListaProyectos.result} valorkey="id" valornombre="denominacion"/>}
                                            {resListaProyectos.result &&
                                                setValorProyecto()
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">
                                        <span className="obligatorio">* </span>Nro. de Expediente
                                    </label>
                                    <div className="col-lg-8">
                                        <input type="text" className="form-control input-sm uppercaseinput" id="nroexpediente" name="nroexpediente"
                                        placeholder="Número de expediente"
                                        required
                                        title="El Número de Expediente es requerido"
                                        autoComplete = "off"
                                        onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">
                                        <span className="obligatorio">* </span>Año
                                    </label>
                                    <div className="col-lg-8">
                                        <select className="form-control input-sm" id="periodoid" name="periodoid"
                                        required
                                        title="El Año es requerido"
                                        autoComplete = "off"
                                        onChange={handleInputChange}
                                        >
                                            <option value="">--SELECCIONE--</option>
                                            {resListaAnios.error
                                            ? "Se produjo un error cargando los años"
                                            : resListaAnios.loading
                                            ? "Cargando..."
                                            : <ComboOptions data={resListaAnios.result} valorkey="valorcodigo" valornombre="valortexto" />}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">
                                        <span className="obligatorio">* </span>Proceso
                                    </label>
                                    <div className="col-lg-8">
                                        <select className="form-control input-sm" id="procesoid" name="procesoid"
                                        required
                                        title="El Proceso es requerido"
                                        onChange={handleInputChange}
                                        >
                                            <option value="">--SELECCIONE--</option>
                                            {resListaProcesos.error
                                            ? "Se produjo un error cargando los procesos"
                                            : resListaProcesos.loading
                                            ? "Cargando..."
                                            : <ComboOptions data={resListaProcesos.result} valorkey="valorcodigo" valornombre="valortexto" />}
                                        </select>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className="form-group col-lg-6">
                            <fieldset className="mleft-20">
                                <legend>Datos Generales</legend>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">Profesional Solicitante</label>
                                    <div className="col-lg-8">
                                        {resListaSolicitantes.error
                                        ? "Se produjo un error cargando los locadores"
                                        : resListaSolicitantes.loading
                                        ? "Cargando..."
                                        : <Autocomplete listaDatos={resListaSolicitantes.result} callabck={setSolicitante} />}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">Estado del Plano</label>
                                    <div className="col-lg-8">
                                        <select id="estadoid" name="estadoid" className="form-control input-sm" onChange={handleInputChange}>
                                            <option value="0">--SELECCIONE--</option>
                                            {resListaEstadosPlano.error
                                            ? "Se produjo un error cargando los estados de plano"
                                            : resListaEstadosPlano.loading
                                            ? "Cargando..."
                                            : <ComboOptions data={resListaEstadosPlano.result} valorkey="valorcodigo" valornombre="valortexto" />}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">Plano Antecedente</label>
                                    <div className="col-lg-8">
                                        <input type="text" className="form-control input-sm" id="antecedente" name="antecedente"
                                        value={valAncedente}
                                        readOnly
                                        onChange={handleInputChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">Fecha de Creación</label>
                                    <div className="col-lg-8">
                                        <input style={{lineHeight: '1.43'}} type="date" id="fechacreacion" name="fechacreacion" className="form-control input-sm" onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-lg-4 control-label">Observaciones</label>
                                    <div className="col-lg-8">
                                        <input type="text" className="form-control input-sm" id="observaciones" name="observaciones" onChange={handleInputChange}/>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div className="form-group col-lg-12">
                        <fieldset className="mleft-20">
                            <legend>Ubicación</legend>
                        </fieldset>
                    </div>

                    <div className="form-group col-lg-6">
                        <fieldset className="mleft-20">
                            <div className="form-group">
                                <label className="col-lg-4 control-label">Departamento</label>
                                <div className="col-lg-8">
                                    <select className="form-control" id="departamentoid" name="departamentoid" onChange={(e) => {handleChangeDepartmento(e); handleInputChange(e);}}>
                                        <option value="">--SELECCIONE--</option>
                                        {resListaDepartmento.error
                                        ? "Se produjo un error cargando los departamentos"
                                        : resListaDepartmento.loading
                                        ? "Cargando..."
                                        : <ComboOptions data={resListaDepartmento.result} valorkey="id_dpto" valornombre="nombre" />}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label">Provincia</label>
                                <div className="col-lg-8">
                                    <select id="provinciaid" name="provinciaid" className="form-control input-sm" onChange={(e) => {handleChangeProvincia(e); handleInputChange(e);}}>
                                        <option value="0">--SELECCIONE--</option>
                                        <ComboOptions data={dataProv} valorkey="id_prov" valornombre="nombre" />
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label">Distrito</label>
                                <div className="col-lg-8">
                                    <select id="distritoid" name="distritoid" className="form-control input-sm" onChange={handleInputChange}>
                                        <option value="0">--SELECCIONE--</option>
                                        <ComboOptions data={dataDist} valorkey="id_dist" valornombre="nombre" />
                                    </select>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className="form-group col-lg-6">
                        <div className="form-group">
                                <label className="col-lg-4 control-label">Tramo</label>
                                <div className="col-lg-8">
                                    <select id="tramoid" name="tramoid" className="form-control input-sm" onChange={handleInputChange}>
                                    <option value="">--SELECCIONE--</option>
                                    {dataTramo &&
                                    <ComboOptions data={dataTramo} valorkey="id" valornombre="descripcion" />}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-4 control-label">Subtramo</label>
                                <div className="col-lg-8">
                                    <input type="text" className="form-control input-sm" id="subtramo" name="subtramo" onChange={handleInputChange}/>
                                </div>
                            </div>
                    </div>

                    <div className="form-group col-lg-12">
                        <fieldset className="mleft-20">
                            <legend>Archivos</legend>
                            <div className="form-group col-lg-6">
                                <label className="col-lg-4 control-label">Descripción / Lámina</label>
                                <div className="col-lg-8">
                                    <input type="text" className="form-control input-sm" id="nombrelam" name="nombrelam"
                                    value = {planoArchTmp.lamina || ''}
                                    onChange={handleChangeLamina}/>
                                </div>
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="col-lg-4 control-label">Plano Dígital</label>
                                <div className="col-lg-6">
                                    <UploadMemo key="planodigitaltmp" file={{urlDocumento:''}}
                                    accept={'.*'} resetContenido={reiniciarValDigital}
                                    setFile={saveArchivoDigital} folderSave={directorioPlanos} eliminar={deleteArchivoDigital}></UploadMemo>
                                </div>
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="col-lg-4 control-label">Memoría Descriptiva</label>
                                <div className="col-lg-6">
                                    <UploadMemo key="memdescriptivatmp" file={{urlDocumento:''}}
                                    accept={'.*'} resetContenido={reiniciarValMemoria}
                                    setFile={saveArchivoMemoria} folderSave={directorioPlanos} eliminar={deleteArchivoMemoria}></UploadMemo>
                                </div>
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="col-lg-4 control-label">
                                    <a className="btn btn-info btn-sm"
                                        title="Agregar a Lista"
                                        onClick={actualizarLista}
                                    >Agregar a Lista</a>
                                </label>
                                <div className="col-lg-6">

                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="form-group col-lg-12">
                            <div className="form-group">
                            {listaArchivos ?
                                <SubLista data={listaArchivos} cabecera={cabeceraArchivos} deleterow={removerDeLista}/>
                                :
                                <SubLista data={[]} cabecera={cabeceraArchivos} deleterow={removerDeLista}/>
                            }
                            </div>
                    </div>

                    <div className="panel-body">
                        <div className="form-group">
                            <div className="col-lg-offset-8 col-lg-4">
                                <Link to={`/planos`} className="btn btn-default btn-sm btn-control">Cancelar
                                </Link>
                                <button id="btnguardar" type="submit"
                                        className="btn btn-danger btn-sm btn-control">Guardar
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
                </WraperLarge>
            </>
        );
    }

    export default PlanoAdd;

import React, {useEffect, useState, createContext} from 'react';
import {Link} from "react-router-dom";
import Wraper from "../m000_common/formContent/WraperLarge";
import {LISTADO_FICHAS_RENIEC} from "../../config/breadcrums";
import {initAxiosInterceptors, serverFile} from '../../config/axios';
import TableTrabajador from "./TableCiudadano";
import TrabajadorRow from "./CiudadanoRow";
import {useTable} from "../../hooks/useTable";
import Pagination from "react-js-pagination";
import MAddEditInvitado from "./CiudadanoModal";
import { toastr } from "react-redux-toastr";

const queryString = require('query-string');

const {alasql} = window;

const Axios = initAxiosInterceptors();


async function buscarTrabajador(query) {
    // alert(query)
    const {data} = await Axios.get(`/list_ciudano?` + query);
    return data;
}

async function save_ciudadano(ciudadano) {
    console.log('-----------------')
    console.log(ciudadano)

    const {data} = await Axios.post(`/save_ciudadano`, ciudadano);
    return data;
}

function convertirFormatoFecha(fecha) {

    if(fecha.trim()==='DNI NO CADUCA'){
        fecha='31/12/3000';
    }
    if(fecha.trim()==='' || fecha==null){
       return null
    }
    // Dividir la fecha original por los slashes
    var partes = fecha.split("/");

    // Reorganizar las partes y unirlas con guiones
    return partes[2] + "-" + partes[1] + "-" + partes[0];
}

function convertirACiudadano(jsonEntrada) {
    return {
        id: null, // El ID se establece generalmente automáticamente por la base de datos
        dni: jsonEntrada.dni,
        cui: jsonEntrada.CUI,
        apellido_paterno: jsonEntrada["Apellido Paterno"],
        apellido_materno: jsonEntrada["Apellido Materno"],
        nombres: jsonEntrada.Nombres,
        sexo: jsonEntrada.Sexo,
        fecha_de_nacimiento:  convertirFormatoFecha(jsonEntrada["Fecha de Nacimiento"]),
        departamento_de_nacimiento: jsonEntrada["Departamento de Nacimiento"],
        provincia_de_nacimiento: jsonEntrada["Provincia de Nacimiento"],
        distrito_de_nacimiento: jsonEntrada["Distrito de Nacimiento"],
        grado_de_instruccion: jsonEntrada["Grado de Instrucción"],
        estado_civil: jsonEntrada["Estado Civil"],
        estatura: jsonEntrada.Estatura,
        fecha_de_inscripcion: convertirFormatoFecha(jsonEntrada["Fecha de Inscripción"]),
        nombre_del_padre: jsonEntrada["Nombre del Padre"],
        nombre_de_la_madre: jsonEntrada["Nombre de la Madre"],
        restriccion: jsonEntrada.Restricción,
        departamento_de_domicilio: jsonEntrada["Departamento de Domicilio"],
        provincia_de_domicilio: jsonEntrada["Provincia de Domicilio"],
        distrito_de_domicilio: jsonEntrada["Distrito de Domicilio"],
        direccion: jsonEntrada.Dirección,
        fecha_de_caducidad: convertirFormatoFecha(jsonEntrada["Fecha de Caducidad"]),
        fecha_de_fallecimiento: convertirFormatoFecha(jsonEntrada["Fecha de Fallecimiento"]),
        glosa_informativa: jsonEntrada["Glosa Informativa"],
        observacion: jsonEntrada.Observación,
        fileficha: jsonEntrada.fileficha,
        foto: jsonEntrada.foto,
        // Los siguientes campos no están presentes en el JSON de entrada, por lo que se dejan como nulos o valores por defecto
       // usuaregistraid: null,
        //usuaactualizaid: null,
        //createdAt: null,
        //updatedAt: null,
        eliminado: false,
        foto: null
    };
}



async function exportExcel(query) {
    // alert(query)
    const {data} = await Axios.get(`/getAllExcel?` + query);
    var resultgeojson = alasql(`SELECT *
                 FROM ? `, [data.rows])
    var opts = [{
        sheetid: 'Reporte',
        headers: true
    }];
    var res = alasql('SELECT INTO XLSX("Listadociudadanos.xlsx",?) FROM ?', [opts, [resultgeojson]]);
    return true;
}



const Trabajadores = ({history}) => {

    const [busqueda, setBusqueda] = useState('');
    const [activePage,changePage, limit, totalItemsCount,pageRangeDisplayed  , trabajadors] = useTable();

    const [modalInvitado, setModalInvitado] = useState(false);
    const [objectModal, setObjectModal] = useState({});

    useEffect(() => {
        async function init() {
            try {
                let query = await queryString.stringify({busqueda, page: activePage, limit});
                let trabajadores = await buscarTrabajador(query)
                changePage(activePage, trabajadores);
            } catch (error) {
                alert('Ocurrio un error')
                console.log(error);
            }
        }

        init();
    }, []);

    const cerrarModal = (estado) => {
        setModalInvitado(estado);
    }

    const updatevaluesinvitado = async (invitado) => {

        //console.log(JSON.stringify())
        try {
            save_ciudadano(convertirACiudadano(invitado))
            setModalInvitado(false);
            let query = await queryString.stringify({busqueda, page: activePage, limit});
            let trabajadores = await buscarTrabajador(query)
            changePage(activePage, trabajadores);
            toastr.success(' Se agrego/actualizo correctamente.')
        } catch (error) {
            toastr.error(' Ocurrio un error.')
        }
       


    }

    const buscarTrabadorFilter = async (e) => {

        e.preventDefault();
        let query = await queryString.stringify({busqueda,page: 1, limit});
        let trabajadores = await buscarTrabajador(query)
        changePage(activePage, trabajadores);
    }

    //const trabajadores = useSelector(state => state.trabajador.trabajadors);
    //const loading = useSelector(state => state.trabajador.cargando);

    const descarxls =async (e) => {

        e.preventDefault();
        let query = await queryString.stringify({busqueda});
        let trabajadores = await exportExcel(query);

    }
    const handleClickAddEditCiudadano = (e) => {
        setModalInvitado(true);
       
    }



    const handlePageChange = async (pageNumber) => {
        let query = await queryString.stringify({busqueda, page: pageNumber, limit});
        let resultList = await buscarTrabajador(query)
        changePage(pageNumber, resultList);

    }

    const cabecerasTabla = ["DNI", "NOMBRES", "AP. PATERNO","AP. MATERNO", "ESTADO CIVIL", "FALLECIDO",  "VENC. DNI", "DOMICILIO", "VER FICHA"]

    return (
        <>

            <Wraper titleForm={"Listado de fichas Reniec"} listbreadcrumb={LISTADO_FICHAS_RENIEC}>
                <fieldset className={'fielsettext'}>
                    <form onSubmit={buscarTrabadorFilter}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-group">
                                    <input type="text" className="form-control "
                                           placeholder="Nombre del ciudadano o su DNI"
                                           onChange={e => setBusqueda(e.target.value)}
                                    ></input>
                                    <span className="input-group-btn">
                                                                <button className="btn btn-default " type="submit"><i
                                                                    className="fa fa-search"></i></button>
                                                            </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <button onClick={handleClickAddEditCiudadano}  className="btn btn-danger pull-right btn-sm fullborder">
                                    <i className="fa fa-plus"></i> Agregar Ciudadano</button>
                               {/* <button type="button" onClick={descarxls}
                                        className="btn btn-default pull-right btn-sm fullborder">
                                    <i className="fa fa-file-excel-o"></i> Descargar Excel
                                </button>*/}
                            </div>
                        </div>
                    </form>
                </fieldset>
                <div className="panel panel-default">
                    <TableTrabajador cabecera={cabecerasTabla}>
                        {trabajadors.rows.map((trabajador, i) => (
                            <TrabajadorRow nro={i} trabajador={trabajador}></TrabajadorRow>
                        ))}
                    </TableTrabajador>
                    <div className="panel-footer clearfix pull-right">
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={limit}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={pageRangeDisplayed}
                            onChange={handlePageChange}
                        ></Pagination>
                    </div>
                </div>

                {modalInvitado && <MAddEditInvitado object={objectModal} closeventana={cerrarModal} usevalue={updatevaluesinvitado} />}
            </Wraper>

        </>
    );

}


export default Trabajadores;
import React from 'react';

const Select = ({children, onChange, required, name, value,initial=true,title="Seleccione un valor",readonly=false,disabled=false}) => {
    return (
        <>
            <select
               disabled= {!disabled? '': 'disabled'}
                required={required?"required":""}
                className="form-control input-sm"
                name={name}
                onChange={onChange}
                value={value}
                title={title}
                readOnly={readonly}
            >
                {initial ? <option value="">-- SELECCIONE --</option> : ''}
                {children}

            </select>
        </>
    );
};

export default Select;
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
const { $ } = window;

function getpredioselect () {

    let result = JSON.parse(localStorage.getItem("predioselec"))
    return result;
}

const PredioLinksTecnico = ({ active = "1" }) => {

    // const dataPredio=getpredioselect()
    const [dataPredio, setDataPredio] = useState({})

    useEffect(() => {
        const init = async () => {
            let result = getpredioselect();

            setDataPredio(result)
            $('[data-toggle="tooltip"]').tooltip()
            // setDatapredio()
        };
        init();
    }, []);

    return (
        <>
            <div className="border-abajo clearfix">
                <ul className="tab-bar">
                    <li className={active === "1" ? "active" : ""}>

                        <Link to={`/padron-datos-tecnicos/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Padrón</Link>
                    </li>
                    <li className={active === "2" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-colindancia/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Colindancia matriz"}>

                            Colindancia matriz</Link>
                    </li>
                    <li className={active === "3" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-area-afectada/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={""}>

                            Area  afectada</Link>
                    </li>

                    <li className={active === "4" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-cuadro/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={""}>

                            Cuadro de datos técnicos</Link>
                    </li>

                    {/*
                    <li className={active === "5" ? "active": ""}>
                        <Link  to={`/predio-reg-edit/${dataPredio.predioid}/${dataPredio.codigopredio}`}  className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={ "" }>
                           
                            Colindancia predio</Link>
                    </li>
                    */ }
                    <li className={active === "6" ? "active" : ""}>


                        <Link to={`/predio-datos-tecnicos-vivienda/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Datos de la vivienda</Link>
                    </li>
                    <li className={active === "7" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-oc/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Obras complementarias
                        </Link>
                    </li>

                    <li className={active === "8" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-plant-frutales/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Plantaciones frutales
                        </Link>
                    </li>

                    <li className={active === "9" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-plant-forestales/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Plantaciones forestales
                        </Link>
                    </li>

                    <li className={active === "10" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-plant-transitorias/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Plantaciones transitorias
                        </Link>
                    </li>
                    <li className={active === "11" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-danio-emergente/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Daño emergente
                        </Link>
                    </li>
                    {/* <li className={active === "12" ? "active" : ""}>
                        <Link to={`/predio-datos-tecnicos-observaciones/${dataPredio.id}/${dataPredio.codigopredio}`} className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={"Padron"}>
                            Observaciones
                        </Link>
                    </li> */}


                </ul>
            </div>
        </>
    );
};

export default PredioLinksTecnico;
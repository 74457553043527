import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";

import {
    Form,
    FormGroup,
    Row6,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table,
    TextArea
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import SingleUpload from "../../components/uploader/SingleUploadDNI";
import { FilesGestionPredial } from "../../config/parameters";


import moment from 'moment';

const MAddEditInvitado = ({ object, closeventana, usevalue }) => {
    const [invitado, setInvitado] = useState({});
    const [lblaction, setlblaction] = useState("Agregar ciudadano");

    // const listameses=generarListaMeses()

    useEffect(() => {
        function initialLoad () {
            var idDate = moment().format("HHmmss");
            if (object.id) {

                setInvitado({
                    ...object,
                    update: true
                });
                setlblaction('Actualizar')
                // setInvitado(object)

            } else {
                setInvitado({
                    ...invitado,
                    id: idDate,
                    update: false
                });
            }

        }
        initialLoad();
    }, []);

    const closeModal = () => {
        closeventana(false);
    }



    const addUpdateValor = (e) => {
       
        e.preventDefault();
        console.log(invitado)
        let foto= invitado.foto.path
        let fileficha= invitado.foto.path
        let object={...invitado?.foto?.ciudadano, foto,fileficha}
        console.log(object);
        usevalue(object);

    }

    const handleInputChange = (e) => {
        setInvitado({
            ...invitado,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }

    return (
        <>
            <div className={"form-horizontal"}>
                <div>
                    <div id="lightCustomModal_background" className="popup_background backblq"></div>
                    <div id="lightCustomModal_wrapper" className="popup_wrapper bloqueador">
                        <div style={{ transform: 'scale(1)', alignContent: 'left' }}
                            className="custom-popup light  popup_content popup_content_visible bloqueador2"
                            id="lightCustomModal"
                            data-popup-initialized="true" aria-hidden="false" role="dialog" aria-labelledby="open_20531909"
                            tabIndex="-1">
                            <a onClick={closeModal} className="btn  m-right-sm lightCustomModal_close pull-right">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                            <div className=" " style={{ width: '500px' }}>
                                <div className="modal-header">
                                    <h4>{lblaction}</h4>
                                </div>
                                <Form onSubmit={addUpdateValor}>


                                    <FormGroup label={"Archivo DNI"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>


                                    <div>

                                        <h4>Datos del ciudadano</h4>
                                        <hr/>
                                        <Detalle data={invitado?.foto?.ciudadano} />
                                    </div>

                                    <div className="modal-footer">
                                        <button id="btnguardar" type="submit"
                                            className="btn btn-danger btn-sm btn-control">{lblaction}
                                        </button>

                                        <a onClick={closeModal} className="btn btn-default btn-sm btn-control">Cerrar</a>
                                    </div>

                                </Form>

                            </div>


                        </div>
                        <div className="popup_align bloqueador3">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MAddEditInvitado;

function Tabla ({ data }) {
    return (
        <table>
            <tbody>
                {Object.entries(data).map(([key, value]) => (
                    <tr key={key}>
                        <td>{key}</td>
                        <td>{value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}


function Detalle ({ data = {} }) {
    return (
        <div>
            {Object.entries(data).map(([key, value]) => (
                <p key={key}>
                    <strong>{key}:</strong> {value}
                </p>
            ))}
        </div>
    );
}
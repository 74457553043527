
import { Categoria } from "../sigespred/_ddp_blog/Categoria";
import { CategoriaAdd } from "../sigespred/_ddp_blog/CategoriaAdd";
import { CategoriaEdit } from "../sigespred/_ddp_blog/CategoriaEdit";


const RouteModCategoria = [
    {path: "/categoria", element: <Categoria/>},
    {path: "/categoria-add", element: <CategoriaAdd/>},
    {path: "/categoria-edit/:id", element: <CategoriaEdit/>},
]

export default RouteModCategoria;
import React, { useState, useEffect } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { toastr } from "react-redux-toastr";
import {
    Select, Form, Input, FormGroup

} from "../../components/forms";
import SingleUpload from "../../components/uploader/SingleUpload";
import { FilesGestionPredial } from "../../config/parameters";
import ComboOptions from "../../components/helpers/ComboOptions";
import { list_tipo_persona, list_condicion_juridica, list_doc_sust_titularidad, list_estado_civil, list_material, list_estado_construccion_vivienda, list_estado_conservacion } from './DTValidadores'
import moment from 'moment';

const Axios = initAxiosInterceptors();

async function getdataDNI (dni) {
    //  alert(object.id)
    const { data } = await Axios.get(`/obtener-dni?dni=` + dni);
    console.log(data)
    return data;
}

async function getdataDNIFR (dni) {
    //  alert(object.id)
    const { data } = await Axios.get(`/ciudadano_dni?dni=` + dni);
    console.log(data)
    return data;
}


async function getdataRUC (ruc) {
    //  alert(object.id)
    const { data } = await Axios.get(`/obtener-ruc?ruc=` + ruc);
    console.log(data)
    return data;
}


const MPlantaciones = ({ object, closeventana, usevalue }) => {
    const [invitado, setInvitado] = useState({});
    const [lblaction, setlblaction] = useState("Agregar");

    useEffect(() => {
        function initialLoad () {
            var idDate = moment().format("HHmmss");
            if (object.id) {

                setInvitado({
                    ...object,
                    update: true
                });
                setlblaction('Actualizar')
                // setInvitado(object)

            } else {
                setInvitado({
                    ...invitado,
                    id: idDate,
                    update: false
                });
            }

        }
        initialLoad();
    }, []);

    const closeModal = () => {
        closeventana(false);
    }

    const addUpdateValor = (e) => {

        e.preventDefault();
        usevalue(invitado);

    }

    const handleInputChange = (e) => {

        setInvitado({
            ...invitado,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }

    /*Permite Filtrar la infraestructura en relacion a una tipo de infraestrucutra*/
    const setnro = (e) => {


        setInvitado({
            ...invitado,
            nro_documento_identidad: '',
            tipo_persona: e.target.value
        });
    }


    const obtenerdatosconsulta = async (e, tipopersona, nrodoc) => {
        e.preventDefault()
        // let nrodoc = e.target.value
        let tamdoc = nrodoc.length
        //alert(tamdoc)

        if (tipopersona == "NATURAL" && tamdoc == 8) {
            let { success, nombres, apellidoPaterno, apellidoMaterno } = await getdataDNI(nrodoc);
            if (success) {
                let nombre = nombres + ' ' + apellidoPaterno + ' ' + apellidoMaterno;
                setInvitado({
                    ...invitado,

                    nombres_razon_social: nombre,
                    direccion: ""
                });
            }

        }
        else if (tipopersona == "JURIDICA" && tamdoc == 11) {
            let { success, razonSocial, direccion } = await getdataRUC(nrodoc)

            if (success == undefined) {
                setInvitado({
                    ...invitado,

                    nombres_razon_social: razonSocial,
                    direccion: direccion
                });
            }
        } else {
            setInvitado({
                ...invitado,

                nombres_razon_social: "",
                direccion: ""
            });
        }






    }

    function esMenor (fecha) {
        // Convertir la fecha a un objeto Date
        const fechaObjeto = new Date(fecha);

        // Obtener la fecha actual
        const fechaActual = new Date();

        // Comparar las dos fechas
        return fechaObjeto < fechaActual;
    }

    /*Permite Filtrar la infraestructura en relacion a una tipo de infraestrucutra*/
    const verificarFichareniec = async (e) => {
        e.preventDefault()

        let data = await getdataDNIFR(invitado.nro_documento_identidad)



        let messajealerta = "";

        if (!data) {
            messajealerta += "El ciudadano NO tiene FICHA RENIEC EN EL SISTEMA\n"
            toastr.error(messajealerta)
            return
        }

        if (data.fecha_de_fallecimiento !== null) {
            messajealerta += "El ciudadano se encuentra FALLECIDO"
        }

        if (data.estado_civil == "CASADO") {
            messajealerta += "\nEl ciudadano se encuentra CASADO"
        }

        if (esMenor(data.fecha_de_caducidad)) {
            messajealerta += "\nEl ciudadano se tiene DNI CADUCADO"
        }


        if (messajealerta.length > 0) {
            toastr.warning(messajealerta)
        }
        else {
            toastr.info("El ciudadano no posee alertas")
        }

        setInvitado({
            ...invitado
            , nombres_razon_social: data.nombres + ' ' + data.apellido_paterno + ' ' + data.apellido_materno
            , direccion: data.direccion
            , fech_caducidad_dni: data.fecha_de_caducidad
            , dpto_domicilio: data.departamento_de_domicilio
            , prov_domicilio: data.provincia_de_domicilio
            , dist_domicilio: data.distrito_de_domicilio
            , estado_civil: data.estado_civil
        })

    }

    return (
        <>
            <div className={"form-horizontal"}>
                <div>
                    <div id="lightCustomModal_background" className="popup_background backblq"></div>
                    <div id="lightCustomModal_wrapper" className="popup_wrapper bloqueador">
                        <div style={{ transform: 'scale(1)', alignContent: 'left' }}
                            className="custom-popup light  popup_content popup_content_visible bloqueador2"
                            id="lightCustomModal"
                            data-popup-initialized="true" aria-hidden="false" role="dialog" aria-labelledby="open_20531909"
                            tabIndex="-1">
                            <a onClick={closeModal} className="btn  m-right-sm lightCustomModal_close pull-right">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                            <div className=" " style={{ width: '500px' }}>
                                <div className="modal-header">
                                    <h4>{lblaction}</h4>
                                </div>
                                <Form onSubmit={addUpdateValor}>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span>Tipo persona
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.tipo_persona || ""}
                                                onChange={(e) => { setnro(e); }}
                                                name={"tipo_persona"}>
                                                <ComboOptions data={list_tipo_persona} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> {invitado?.tipo_persona == "NATURAL" ? 'DNI' : 'RUC'}

                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.nro_documento_identidad || ""}
                                                onChange={handleInputChange}
                                                name={"nro_documento_identidad"}
                                                placeholder={""}
                                                maxlength={invitado?.tipo_persona == "NATURAL" ? '8' : '11'}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                            {
                                                invitado?.tipo_persona == "NATURAL" ? <button type="button" onClick={verificarFichareniec}>Validación ficha RENIEC</button> : null
                                            }
                                            <button type="button" onClick={(e) => obtenerdatosconsulta(e, invitado?.tipo_persona, invitado?.nro_documento_identidad)}> {invitado?.tipo_persona == "NATURAL" ? "Búscar DNI" : "Búscar RUC"}</button>


                                        </div>
                                    </div>




                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span>
                                            {invitado?.tipo_persona == "NATURAL" ? 'Nombres y Apellidos' : 'Razon Social'}

                                        </label>
                                        <div className="col-lg-8">
                                            <Input

                                                required={true}
                                                value={invitado?.nombres_razon_social || ""}
                                                onChange={handleInputChange}
                                                name={"nombres_razon_social"}
                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span>Fecha caducidad DNI
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.fech_caducidad_dni || ""}
                                                onChange={handleInputChange}
                                                name={"fech_caducidad_dni"}
                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span>Departamento de domicilio
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.dpto_domicilio || ""}
                                                onChange={handleInputChange}
                                                name={"dpto_domicilio"}
                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span>Provincia de domicilio
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.prov_domicilio || ""}
                                                onChange={handleInputChange}
                                                name={"prov_domicilio"}
                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span>Distrito de domicilio
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.dist_domicilio || ""}
                                                onChange={handleInputChange}
                                                name={"dist_domicilio"}
                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span>Dirección
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.direccion || ""}
                                                onChange={handleInputChange}
                                                name={"direccion"}
                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>
                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span>Telf. de contacto
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.telefonos || ""}
                                                onChange={handleInputChange}
                                                name={"telefonos"}
                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>

                                    <FormGroup label={"Foto Identidad"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"foto_identidad"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Condición Juridica
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={false} value={invitado?.condicion_juridica || ""}
                                                onChange={handleInputChange}
                                                name={"condicion_juridica"}>
                                                <ComboOptions data={list_condicion_juridica} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>


                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Estado civil
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={false} value={invitado?.estado_civil || ""}
                                                onChange={handleInputChange}
                                                name={"estado_civil"}>
                                                <ComboOptions data={list_estado_civil} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Documento que sustenta titularidad
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={false} value={invitado?.doc_sust_titularidad || ""}
                                                onChange={handleInputChange}
                                                name={"doc_sust_titularidad"}>
                                                <ComboOptions data={list_doc_sust_titularidad} valorkey="id" valornombre="descripcion" />
                                            </Select>
                                        </div>
                                    </div>

                                    <FormGroup label={"Archivo documento sustento titularidad"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"arch_doc_sustento"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>







                                    <div className="modal-footer">
                                        <button id="btnguardar" type="submit"
                                            className="btn btn-danger btn-sm btn-control">{lblaction}
                                        </button>

                                        <a onClick={closeModal} className="btn btn-default btn-sm btn-control">Cerrar</a>
                                    </div>

                                </Form>

                            </div>


                        </div>
                        <div className="popup_align bloqueador3">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MPlantaciones;
import Planos from "../sigespred/_ddp_planos/Planos";
import PlanoAdd from "../sigespred/_ddp_planos/PlanoAdd";
import PlanoEdit from "../sigespred/_ddp_planos/PlanoEdit";
import PlanoCodigos from "../sigespred/_ddp_planos/PlanoCodigos";

const RouteModPlanos = [
    {path: "/", element: <Planos/>},
    {path: "/planos", element: <Planos/>},
    {path: "/plano-add/:ante?", element: <PlanoAdd/>},
    {path: "/plano-edit/:id", element: <PlanoEdit/>},
    {path: "/plano-grupo", element: <PlanoCodigos/>},
]

export default RouteModPlanos;


import Predios from "../sigespred/m005_diagnostico_tecnico_legal/m005_01_predios/Predios";
import DatosPredio from "../sigespred/m005_diagnostico_tecnico_legal/m005_01_predios/DatosPredio";
import UbicacionPredio from "../sigespred/m005_diagnostico_tecnico_legal/m005_01_predios/UbicacionPredio";
import PropietarioPredio from "../sigespred/m005_diagnostico_tecnico_legal/m005_01_predios/PropietarioPredio";
import PolygonoPredio from "../sigespred/m005_diagnostico_tecnico_legal/m005_01_predios/PolygonoPredio";
import Expedientes from "../sigespred/m005_diagnostico_tecnico_legal/expedientes/Expedientes";



const RouteMod05DiagnosticoTecnicoLegal = [
    {path: "/listado-predios/:codigo", element: <Predios/>},
    {path: "/datos-predio/:codigo", element: <DatosPredio/>},
    {path: "/ubicacion-predio/:codigo", element: <UbicacionPredio/>},
    {path: "/propietarios-predio/:codigo", element: <PropietarioPredio/>},
    {path: "/polygono-predio/:codigo", element: <PolygonoPredio/>},
    {path: "/expedientes/:codigo", element: <Expedientes/>},


]
export default RouteMod05DiagnosticoTecnicoLegal;
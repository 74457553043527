import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import { serverFile} from '../../config/axios';
const {$} = window;
const TrabajadorRow = ({trabajador,nro}) => {

    let fallecido=trabajador

    useEffect(() => {
        const init = async () => {
            $('[data-toggle="tooltip"]').tooltip()
        };
        init();
    }, []);


    return (
        <>
        
            <tr >

                <td>{trabajador.dni}</td>
                <td>{trabajador.nombres}</td>
                <td>{trabajador.apellido_paterno}</td>
                <td>{trabajador.apellido_materno}</td>
                <td>{trabajador.estado_civil==="CASADO"? <b style={{color:'red'}}>CASADO </b> : trabajador.estado_civil }</td>
                <td>{trabajador.fecha_de_fallecimiento ? <b style={{color:'red'}}>{trabajador.fecha_de_fallecimiento} </b> : trabajador.fecha_de_fallecimiento   }</td>
                <td>{trabajador.fecha_de_caducidad }</td>
                <td>{trabajador?.direccion}</td>

                <td className="acciones-3bot">
                    <div className="btn-group pull-right">
                        <a  target='_blank'  className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" href={serverFile+trabajador?.fileficha}
                               data-original-title={ "Ver datos" }>
                            <i className="fa fa-eye fa-lg"></i>
                        </a>
                        
                    </div>
                </td>
            </tr>
        </>
    );
};

export default TrabajadorRow;
import React from 'react';

const TableAfectadaColindancia = ({data=[], deleteObject,updateObject}) => {
    const cabecera = ["NRO","COD.AFECT.", "TIPO",   "DATUM", "USO DT", "LADO", "COLINDANCIA"
    , "SUMA LADOS", "COLINDANCIA CBC"];

    return (
        <>
            <table className="table table-bordered table-condensed table-hover table-striped" id="dataTableInvitado">
                <thead>
                <tr>
                    { cabecera.map((item,i)=>(
                        <th key={i}>{item}</th>
                    ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((obj, key) => (
                        
                            <tr key={key}>
                               <td key={`td_${key}_3`}>{obj?.NRO}</td>
                               <td key={`td_${key}_2`}>{obj?.AREA_AFECTADA}</td>
                                <td key={`td_${key}_2`}>{obj?.TIPO}</td>
                                <td key={`td_${key}_3`}>{obj?.DATUM}</td>
                                
                                <td key={`td_${key}_3`}>{obj?.USO_DT}</td>
                                <td key={`td_${key}_3`}>{obj?.LADO}</td>
                                <td key={`td_${key}_3`}>{obj?.COLINDANCIA}</td>
                                <td key={`td_${key}_3`}>{obj?.SUMA_LADOS}</td>
                                <td key={`td_${key}_3`} style={{ fontSize: '9px' , width: '300px' }}>{obj?.COLINDANCIA_CBC}</td>
                              
                            </tr>
                    ))
                }
                
                </tbody>
            </table>

        </>
    );
};

export default TableAfectadaColindancia;
import listProyectos from "../sigespred/m003_gestion_proyectos/Proyectos";
import ProyectoAdd from "../sigespred/m003_gestion_proyectos/ProyectoAdd";
import ProyectoEdit from "../sigespred/m003_gestion_proyectos/ProyectoEdit";
import ProyectoDel from "../sigespred/m003_gestion_proyectos/ProyectoDel";


const RouteMod03GestionProyectos =
    [
        {path: "/proyecto-add", element: <ProyectoAdd/>},
        {path: "/proyecto-edit/:codigo", element: <ProyectoEdit/>},
        {path: "/proyecto-del", element: <ProyectoDel/>},
        {path: "/gestionpredials", element: <listProyectos/>},
    ]


export default RouteMod03GestionProyectos;
import React, { useEffect, useState }  from 'react';
import {Link} from "react-router-dom";
import {useSelector} from 'react-redux';
const {$} = window;

function getpredioselect(){
    
    let result= JSON.parse(localStorage.getItem("predioselec"))
   // alert(result)
    return result
}

const PredioLinks = ({active = "1"}) => {
   // const dataPredio = ;

    const [dataPredio,setDatapredio]=useState({})

    useEffect(() => {
        const init = async () => {
            $('[data-toggle="tooltip"]').tooltip();
            setDatapredio(getpredioselect())
        };
        init();
    }, []);

    return (
        <>
            <div className="border-abajo clearfix">
                <ul className="tab-bar">
                <li className={active === "1" ? "active": ""}>
                    
                    <Link  to={`/padron-legal/${dataPredio.id}/${dataPredio.codigopredio}`}  className="btn btn-xs btn-default mright-5" type="button" data-toggle="tooltip" data-original-title={ "Padron" }>
                        Padrón Legal</Link>
                </li>
                    
                </ul>
            </div>
        </>
    );
};

export default PredioLinks;
import React, { useEffect, useState } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {
    Form,
    FormGroup,
    Row6,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import { useForm } from '../../hooks/useForm';
import { useAsync } from "react-async-hook";
import MAddEditInvitado from "./DTAreaAfectadaModal";
import TableInvitado from "./DTAreaAfectadaTable";
import PredioLinks from "./PredioLinksTecnico";
import * as helperGets from "../../components/helpers/LoadMaestros";
import MAddAreasLinderos from "./MAddAreasLinderos";
import { MAddGanadera } from "./MAddGanadera";
import { MAddAgricola } from "./MAddAgricola";

import { isObjectValid, list_estado_predio, list_tipo_pre, list_uso_predio, list_tipo_via, list_lado, list_topografia, list_dano, list_datum } from './DTValidadores';

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoPredio (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function getdata (object) {
    //  alert(object.id)
    const { data } = await Axios.get(`/list_area_afectada?predioid=` + object.id);
    console.log(data)
    return data;
}

async function save (object) {
    const { data } = await Axios.post(`/save_area_afectada`, object);
    return data;
}

function generateDUUID () {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const DTAreaAfectada = ({ history, match }) => {

    //Obtener los parameotros
    const { id, codpred } = useParams();

    //Definicion de estado del formularios
    const [object, setObject, handleInputChange, reset] = useForm({}, []);
    const [predio, setPredio] = useState({});


    const [listaInvitados, setListaInvitados] = useState([]);
    const [modalInvitado, setModalInvitado] = useState(false);
    const [accion, set_accion] = useState('Agregar');
    const [objectModal, setObjectModal] = useState({});

    //Traer el objeto de datos del predio seleccionado
    useEffect(() => {
        const init = async () => {

            //obtenieno los datos predio
            let predio = await getDatoPredio(id);
            setPredio(predio);
            //alert(predio.id)
            let data = await getdata(predio);
            console.log(data)
            setObject({ ...data, predioid: predio.id })
            setListaInvitados(data.listAreas || []);



        }
        init();
    }, []);

    const actualizar = async (e) => {
        e.preventDefault();
        try {

            await save({ ...object, listAreas: listaInvitados });
            toastr.success(
                "Guardando",
                "El registro fue guardado correctamente.",
                { position: "top-right" }
            );

            //history.push("/blog");
        } catch (e) {

            toastr.error('Ocurrio un error', e.message, { position: 'top-center' })
        }
    }

    const handleClickAddEditInvitado = (e) => {
        setModalInvitado(true);
        setObjectModal({})
    }

    const cerrarModal = (estado) => {
        setModalInvitado(estado);
    }
    /*Gestion de distrito*/

    const updatevaluesinvitado = (invitado) => {

        if (invitado.update) {
            const index = listaInvitados.findIndex(obj => obj.id === invitado.id);
            if (index !== -1) {
                listaInvitados[index] = { ...invitado, areaTotalM2: (parseFloat(invitado?.areaDirectaM2) + parseFloat(invitado?.areaIndirectaM2)) };
            }
            setListaInvitados(listaInvitados);
            calcularTotales(listaInvitados);



        } else {
            let tam = listaInvitados.length || 0;
            let newListInvitados = [...listaInvitados, { ...invitado, poligono: generateDUUID(), areaTotalM2: (parseFloat(invitado?.areaDirectaM2) + parseFloat(invitado?.areaIndirectaM2)) }]
            setListaInvitados(newListInvitados);
            calcularTotales(newListInvitados);


        }




        setModalInvitado(false);


    }

    const updatedInvitado = (key) => {

        //alert(key)
        var foundObject = listaInvitados.find(function (e) {
            return e.id == key;
        });
        setObjectModal(foundObject);
        setModalInvitado(true);

    };

    const deleteInvitado = key => {

        const toastrConfirmOptions = {
            onOk: () => {
                let data = listaInvitados.filter(function (e) {
                    return e.id !== key;
                });

                setListaInvitados(data);
                calcularTotales(data);

            },
            onCancel: () => { }
        };
        toastr.confirm(`¡Desea eliminar el registro?`, toastrConfirmOptions);





    };
    const calcularTotales = (listaInvitados) => {

        listaInvitados = listaInvitados.filter(objeto => objeto.usoDt === "AFECTADA");

        let totalDirecta = 0;
        let totalIndirecta = 0;
        let totalArea = 0;

        // Asegúrate de que listaInvitados esté definida y tenga el formato esperado
        if (!Array.isArray(listaInvitados) || !listaInvitados.length) {
            console.error('listaInvitados no está definida o está vacía');
            setObject({
                ...object, // Asumiendo que object es un objeto previamente definido
                areaIndirectaTotalM2: 0,
                sumatoriaAreasM2Total: 0,
                areaDirectaTotalM2: 0
            });
            return;
        }

        for (let i = 0; i < listaInvitados.length; i++) {
            let objetoActual = listaInvitados[i];

            // Asegúrate de que objetoActual tiene las propiedades necesarias y son numéricas
            if (typeof parseFloat(objetoActual.areaDirectaM2) !== 'number' || typeof parseFloat(objetoActual.areaIndirectaM2) !== 'number') {
                console.error('Objeto inválido en listaInvitados:', objetoActual);
                continue; // Omite este objeto y continúa con el siguiente
            }

            totalDirecta += parseFloat(objetoActual.areaDirectaM2);
            totalIndirecta += parseFloat(objetoActual.areaIndirectaM2);
            totalArea += parseFloat(objetoActual.areaTotalM2);
        }

        // Actualiza el estado u objeto como sea necesario
        setObject({
            ...object, // Asumiendo que object es un objeto previamente definido
            areaIndirectaTotalM2: totalIndirecta,
            sumatoriaAreasM2Total: totalArea,
            areaDirectaTotalM2: totalDirecta,
            listAreas: listaInvitados
        });


    }




    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / PADRON DT"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >
                <PredioLinks active="3"></PredioLinks>
                <Form onSubmit={actualizar}>
                    <div className="mtop-35"></div>
                    <RowForm>

                        <div className="col-lg-12">
                            {isObjectValid(object) ? <h6 className="obligatorio"> Formulario no guardado </h6> : null}

                            <fieldset className="mleft-20">
                                <legend>Datos de expediente</legend>
                                <Row6>
                                    <FormGroup label={"Código del predio"} >
                                        <Input
                                            required={false}
                                            value={predio?.codigopredio || ""}
                                            //onChange={handleInputChange}
                                            name={"titulo"}
                                            placeholder={"Ingrese titulo de la entrada"}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                </Row6>
                                <Row6>
                                    <FormGroup label={"Código expediente"} >
                                        <Input
                                            value={predio?.codexpediente || ""}
                                            //onChange={handleInputChange}
                                            name={"fechainspeccion"}
                                            type={"text"}

                                        >
                                        </Input>
                                    </FormGroup>




                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Area matriz</legend>
                                <Row12>
                                    <button className="btn btn-sm btn-info" type="button" value={accion} onClick={handleClickAddEditInvitado}>
                                        <i className="fa fa-plus fa-lg" /> Añadir</button>
                                </Row12>
                                <Row12>
                                    <TableInvitado
                                        data={listaInvitados.filter(objeto => objeto.usoDt === "MATRIZ")}
                                        deleteObject={deleteInvitado}
                                        updateObject={updatedInvitado}
                                    >

                                    </TableInvitado>
                                </Row12>

                            </fieldset>
                            <fieldset className="mleft-20">
                                <legend>Listado de areas Afectadas</legend>
                                <Row12>
                                    <button className="btn btn-sm btn-info" type="button" value={accion} onClick={handleClickAddEditInvitado}>
                                        <i className="fa fa-plus fa-lg" /> Añadir</button>
                                </Row12>
                                <Row12>
                                    <TableInvitado
                                        data={listaInvitados.filter(objeto => objeto.usoDt === "AFECTADA")}
                                        deleteObject={deleteInvitado}
                                        updateObject={updatedInvitado}
                                    >

                                    </TableInvitado>
                                </Row12>

                                <div className="col-lg-12">
                                    <fieldset className="mleft-20">
                                        <legend>Totales</legend>

                                        <Row6>

                                            <FormGroup label={"areaDirectaTotalM2"} >
                                                <Input
                                                    value={object?.areaDirectaTotalM2 || ""}
                                                    //onChange={handleInputChange}
                                                    name={"areaDirectaTotalM2"}
                                                    type={"text"}

                                                >
                                                </Input>
                                            </FormGroup>

                                            <FormGroup label={"areaIndirectaTotalM2"} >
                                                <Input
                                                    value={object?.areaIndirectaTotalM2 || ""}
                                                    //onChange={handleInputChange}
                                                    name={"areaIndirectaTotalM2"}
                                                    type={"text"}

                                                >
                                                </Input>
                                            </FormGroup>




                                        </Row6>
                                        <Row6>

                                            <FormGroup label={"sumatoriaAreasM2Total"} >
                                                <Input
                                                    required={false}
                                                    value={object?.sumatoriaAreasM2Total || ""}
                                                    //onChange={handleInputChange}
                                                    name={"sumatoriaAreasM2Total"}
                                                    placeholder={""}
                                                    type={"text"}>
                                                </Input>
                                            </FormGroup>



                                        </Row6>

                                    </fieldset>
                                </div>

                            </fieldset>
                            <hr></hr>
                            <fieldset className="mleft-20">
                                <legend>Listado de areas remanentes</legend>
                                <Row12>
                                    <button className="btn btn-sm btn-info" type="button" value={accion} onClick={handleClickAddEditInvitado}>
                                        <i className="fa fa-plus fa-lg" /> Añadir</button>
                                </Row12>
                                <Row12>
                                    <TableInvitado
                                        data={listaInvitados.filter(objeto => objeto.usoDt === "REMANENTE")}
                                        deleteObject={deleteInvitado}
                                        updateObject={updatedInvitado}
                                    >

                                    </TableInvitado>
                                </Row12>

                            </fieldset>
                        </div>





                    </RowForm>


                    <RowForm>





                    </RowForm>

                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>

                        <button id="btnguardar" type="submit"
                            className="btn btn-danger btn-sm btn-control">Guardar
                        </button>
                    </FormFooter>
                </Form>
                {modalInvitado && <MAddEditInvitado object={objectModal} closeventana={cerrarModal} usevalue={updatevaluesinvitado} />}
            </WraperLarge>
        </>
    );
};

export default DTAreaAfectada;


import React from 'react';

const Loading = () => {
    return (
        <>
            <div className="alert alert-danger text-center loading loading01">
                    <span>C </span>
                    <span>A </span>
                    <span>R </span>
                    <span>G </span>
                    <span>A </span>
                    <span>N </span>
                    <span>D </span>
                    <span>O </span>
            </div>
        </>
    );
};

export default Loading;
import React from 'react';

const TableAgenda = ({cabecera, data=[], deleteTema}) => {
    return (
        <>
            <table className="table table-bordered table-condensed table-hover table-striped" id="dataTableActividad">
                <thead>
                <tr>
                    { cabecera.map((cabeza,i)=>(
                        <th key={i}>{cabeza}</th>
                    ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((user, key) => (
                        
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{user.tema}</td>
                                <td className="acciones-1bot pull-center">
                                    <div className="btn-group">

                                        <button className="btn btn-xs btn-default" type="button">
                                        <i
                                            className="fa fa-trash-o fa-lg"
                                            onClick={() => deleteTema(key)}
                                        />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                    ))
                }
                
                </tbody>
            </table>

        </>
    );
};

export default TableAgenda;

import { Blog } from "../sigespred/_ddp_blog/Blog";
import { BlogAdd } from "../sigespred/_ddp_blog/BlogAdd";
import { BlogEdit } from "../sigespred/_ddp_blog/BlogEdit";
import { BlogPost } from "../sigespred/_ddp_blog/BlogPost";
import { DisplayAllPost } from "../sigespred/_ddp_blog/DisplayAllPost";
import { Post } from "../sigespred/_ddp_blog/Post";

import { Single_Post } from "../sigespred/_ddp_blog/Single_Post";



const RouteModBlog = [
    {path: "/blog", element: <Blog/>},
    {path: "/blog-add", element: <BlogAdd/>},
    {path: "/blog-edit/:id", element: <BlogEdit/> },
    {path: "/post", element: <Post/> },
    {path: "/display-post", element: <DisplayAllPost/> },
    {path: "/single-post/:id", element: <Single_Post/> },
    
]

export default RouteModBlog;
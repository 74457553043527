import React, { useEffect, useState } from "react";
import moment from "moment";
import { useAsync } from "react-async-hook";
import { Link, useParams } from "react-router-dom";
import { initAxiosInterceptors } from "../../config/axios";
import { ACTUALIZAR_PARTIDA_BREADCRUM } from "../../config/breadcrums";
import Wraper from "../m000_common/formContent/WraperLarge";
import * as helperGets from "../../components/helpers/LoadMaestros";
import * as PARAMS from "../../config/parameters";
import ComboOptions from "../../components/helpers/ComboOptions";

import { toastr } from "react-redux-toastr";
import { editar, respuestaPartida } from "../../actions/_ddp_partida/Actions";
import { useDispatch } from "react-redux";
import history from "../../history";
import MultipleUpload from "../../components/uploader/MultipleUpload";

const { $ } = window;
const axios = initAxiosInterceptors();
const directorioPartidas = "FilesDDP/Partidas";

const obtenerPartida = async (id) => {
  const { data } = await axios.get(`/partidaregistral?id=${id}`);
  return data;
};

const PartidaRespuesta = () => {
  const resListaProyectos = useAsync(helperGets.helperGetListProyectos, []);
  const resListaTipoPredio = useAsync(helperGets.helperGetListDetalle, [
    PARAMS.LISTASIDS.TIPOPRED,
  ]);
  const [partidaArchTmp, set_partidaArchTmp] = useState({ archivodigital: "" });
  const [partidaRespuesta, setPartidaRespuesta] = useState({});
  const [partidaEditado, set_partidaEditado] = useState({});
  const [dataTramo, setDataTramo] = useState(null);
  const [listaArchivos, set_listaArchivos] = useState([]);
    
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const getPartida = async (idpartida) => {
      let partidaDB = await obtenerPartida(idpartida);
      cargarTramo(partidaDB.infraestructuraid);
      setPartidaRespuesta(partidaDB);
      if (partidaDB.archivos) {
        set_listaArchivos(partidaDB.archivos);
      }
    };
    getPartida(id);
  }, []);

  const agregarPartidaRespuestaAction = (partida) =>
    dispatch(respuestaPartida(partidaRespuesta));

  function handleInputChange(e) {
    if (e.target.name) {
      partidaRespuesta[e.target.name] = e.target.value;
      set_partidaEditado({
        ...partidaEditado,
        [e.target.name]: e.target.value,
      });
    }
  }

  const handleChangeArchivos = (e) => {
    var uidDate = moment().format("YYYYMMDDHHmmss");
    set_partidaArchTmp({
      ...partidaArchTmp,
      descripcion: e.target.value,
      archivoid: uidDate,
    });
  };


  const registrar = async (e) => {
    e.preventDefault();
    

    $("#btnguardar").button("loading");

    try {
      await agregarPartidaRespuestaAction(partidaRespuesta);
      toastr.success(
        "Registro de respuesta de partida ",
        "La Partida fue actualizado correctamente."
      );

      $("#btnguardar").button("reset");
      history.push("/partidas");
    } catch (e) {
      alert(e.message);
    }
  };


  const cargarTramo = async (idProyecto) => {
    if (idProyecto) {
      let data = await helperGets.helperGetListTramos(idProyecto);
      setDataTramo(data);
    } else {
      setDataTramo(null);
    }
  };

  return (
    <>
      <Wraper
        titleForm={"Respuesta de la Partida: " + partidaRespuesta.id}
        listbreadcrumb={ACTUALIZAR_PARTIDA_BREADCRUM}
      >
        <form onSubmit={registrar}>
          {/* <div className="form-group"> */}
          <fieldset className="mleft-20">
            <legend>Datos de Generales</legend>
            <div className="form-group">
              <label className="col-lg-2 control-label">
                <span className="obligatorio">* </span> Proyecto
              </label>
              <div className="col-lg-4">
                {resListaProyectos.error ? (
                  "Se produjo un error cargando los proyectos"
                ) : resListaProyectos.loading ? (
                  "Cargando..."
                ) : (
                  <select
                    className="form-control input-sm"
                    id="infraestructuraid"
                    name="infraestructuraid"
                    readOnly
                    value={partidaRespuesta.infraestructuraid || ""}
                  >
                    <option value="">--SELECCIONE--</option>
                    <ComboOptions
                      data={resListaProyectos.result}
                      valorkey="id"
                      valornombre="denominacion"
                    />
                  </select>
                )}
              </div>
              <label className="col-lg-2 control-label">
                <span className="obligatorio">* </span>Número de Partida
              </label>
              <div className="col-lg-4">
                <input
                  className="form-control input-sm"
                  required
                  type="text"
                  name="nropartida"
                  id="nropartida"
                  onChange={handleInputChange}
                  placeholder="Ingrese numero de Partida"
                  value={partidaRespuesta.nropartida}
                  readOnly
                ></input>
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-2 control-label">
                Tramo
              </label>
              <div className="col-lg-4">
                {dataTramo ? (
                  <select
                    id="tramoid"
                    name="tramoid"
                    value={partidaRespuesta.tramoid || ""}
                    onChange={handleInputChange}
                    className="form-control input-sm"
                    readOnly
                  >
                    <option value="">--SELECCIONE--</option>
                    <ComboOptions
                      data={dataTramo}
                      valorkey="id"
                      valornombre="descripcion"
                    />
                  </select>
                ) : (
                  <select
                    id="tramoid"
                    name="tramoid"
                    className="form-control input-sm"
                    readOnly
                  >
                    <option value="">--SELECCIONE--</option>
                  </select>
                )}
              </div>
              <label className="col-lg-2 control-label">Tipo de Predio</label>
              <div className="col-lg-4">
                <select
                  id="tipopredio"
                  className="form-control input-sm"
                  name="tipopredio"
                  value={partidaRespuesta.tipopredioid}
                  onChange={handleInputChange}
                  readOnly
                >
                  <option value="0">--SELECCIONE--</option>
                  {resListaTipoPredio.error ? (
                    "Se produjo un error cargando los proyectos"
                  ) : resListaTipoPredio.loading ? (
                    "Cargando..."
                  ) : (
                    <ComboOptions
                      data={resListaTipoPredio.result}
                      valorkey="valorcodigo"
                      valornombre="valortexto"
                    />
                  )}
                </select>
              </div>

             
            </div>

            <div className="form-group">
            <label className="col-lg-2 control-label">
                Sub Tramo
              </label>
              <div className="col-lg-4">
                <input
                  className="form-control input-sm"
                  type="text"
                  name="subtramoid"
                  id="subtramoid"
                  onChange={handleInputChange}
                  placeholder="Ingrese el sub tramo"
                  value={partidaRespuesta.subtramoid}
                  readOnly
                ></input>
              </div>
              <label className="col-lg-2 control-label">Asiento</label>
              <div className="col-lg-4">
                <input
                  className="form-control input-sm"
                  type="text"
                  name="nroasiento"
                  id="nroasiento"
                  onChange={handleInputChange}
                  placeholder="Ingrese numero de asiento"
                  value={partidaRespuesta.nroasiento}
                  readOnly
                ></input>
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-2 control-label">Observación</label>
              <div className="col-lg-4">
                <input
                  className="form-control input-sm"
                  type="text"
                  name="observacion"
                  id="observacion"
                  onChange={handleInputChange}
                  placeholder="Ingrese alguna observacion"
                  value={partidaRespuesta.observacion}
                  readOnly
                ></input>
              </div>
            </div>
          </fieldset>
          {/* </div> */}

          <fieldset className="mleft-20">
            <legend>Datos de Respuesta</legend>
            <div className="form-group">
              <label className="col-lg-2 control-label">
                Fecha de Atención
              </label>
              <div className="col-lg-4">
                <input
                  style={{ lineHeight: "1.43" }}
                  type="date"
                  id="fechaatencion"
                  name="fechaatencion"
                  className="form-control"
                  value={partidaRespuesta.fechaatencion || ""}
                  onChange={handleInputChange}
                />
              </div>

              <label className="col-lg-2 control-label">
                Gravamen del Predio
              </label>
              <div className="col-lg-4">
                <select
                  id="gravamentpredio"
                  className="form-control"
                  name="gravamentpredio"
                  onChange={handleInputChange}
                  value={partidaRespuesta.gravamentpredio}
                >
                  <option value="">--SELECCIONE--</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-2 control-label">
                Carga del Predio{" "}
              </label>
              <div className="col-lg-4">
                <select
                  id="cargapredio"
                  className="form-control"
                  name="cargapredio"
                  onChange={handleInputChange}
                  value={partidaRespuesta.cargapredio}
                >
                  <option value="">--SELECCIONE--</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </div>

              <label className="col-lg-2 control-label">
                Transferencias del Predio
              </label>
              <div className="col-lg-4">
                <input
                  className="form-control input-sm"
                  type="text"
                  name="transferenciaspredio"
                  id="transferenciaspredio"
                  onChange={handleInputChange}
                  placeholder="Ingrese la transferencia del predio"
                  value={partidaRespuesta.transferenciaspredio}
                ></input>
              </div>
            </div>

            <div className="form-group">
              <label className="col-lg-2 control-label">Observación</label>
              <div className="col-lg-4">
                <input
                  className="form-control input-sm"
                  type="text"
                  name="observacionrespuesta"
                  id="observacionrespuesta"
                  onChange={handleInputChange}
                  placeholder="Ingrese alguna observación"
                  value={partidaRespuesta.observacionrespuesta}
                  
                ></input>
              </div>
            </div> 

          </fieldset>

          <div className="form-group col-lg-6">
            <fieldset className="mleft-20">
              <legend>Archivos</legend>

              <div className="form-group">
                <MultipleUpload
                  key="multiple"
                  accept={".*"}
                  folderSave={directorioPartidas}
                  form={partidaRespuesta}
                  setForm={setPartidaRespuesta}
                  nameUpload={"archivos"}
                ></MultipleUpload>
              </div>
            </fieldset>
          </div>

          <div className="panel-body">
            <div className="form-group">
              <div className="col-lg-offset-8 col-lg-4">
                <Link
                  to={`/partidas`}
                  className="btn btn-default btn-sm btn-control"
                >
                  Cancelar
                </Link>
                <button
                  id="btnguardar"
                  type="submit"
                  className="btn btn-danger btn-sm btn-control"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </form>
      </Wraper>
    </>
  );
};

export default PartidaRespuesta;

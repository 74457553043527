import PredioList from "../sigespred/_ddp_predio/PredioList";
import PredioAdd from "../sigespred/_ddp_predio/PredioAdd";
import PredioEditGen from "../sigespred/_ddp_predio/PredioEditGen";
import PredioEditTec from "../sigespred/_ddp_predio/PredioEditTec";
import DTPadron from "../sigespred/_ddp_predio/DTPadron";
import DTColindanciaMatriz from "../sigespred/_ddp_predio/DTColindanciaMatriz";
import DTAreaAfectada from "../sigespred/_ddp_predio/DTAreaAfectada";
import DTCuadroDatosTecnicos from "../sigespred/_ddp_predio/DTCuadroDatosTecnicos";
import DTVivienda from "../sigespred/_ddp_predio/DTVivienda";
import DTObraComplementaria from "../sigespred/_ddp_predio/DTObraComplementaria";

import DTPlantFrutales from "../sigespred/_ddp_predio/DTPlantFrutales";
import DTPlantForestales from "../sigespred/_ddp_predio/DTPlantForestales";
import DTPlantTransitorias from "../sigespred/_ddp_predio/DTPlantTransitorias";
import DTObservaciones from "../sigespred/_ddp_predio/DTObservaciones";
import DTDanioemergente from "../sigespred/_ddp_predio/DTDanioemergente";

import DLPadron from "../sigespred/_ddp_predio/DLPadron";
import DPReportes from "../sigespred/_ddp_predio/DPReportes";
import PredioLinderos from "../sigespred/_ddp_predio/PredioLinderos";
import Ciudadano from "../sigespred/md_ciudadano/Ciudadano";

const RouteModPredio = [
    { path: "/predio-list", element: <PredioList /> },
    { path: "/predio-add", element: <PredioAdd /> },
    { path: "/predio-gen-edit/:id/:codpred", element: <PredioEditGen /> },
    { path: "/padron-datos-tecnicos/:id/:codpred", element: <DTPadron /> },
    { path: "/predio-datos-tecnicos-colindancia/:id/:codpred", element: <DTColindanciaMatriz /> },
    { path: "/predio-datos-tecnicos-area-afectada/:id/:codpred", element: <DTAreaAfectada /> },
    { path: "/predio-datos-tecnicos-cuadro/:id/:codpred", element: <DTCuadroDatosTecnicos /> },
    { path: "/predio-datos-tecnicos-vivienda/:id/:codpred", element: <DTVivienda /> },
    { path: "/predio-datos-tecnicos-oc/:id/:codpred", element: <DTObraComplementaria /> },

    { path: "/predio-datos-tecnicos-plant-frutales/:id/:codpred", element: <DTPlantFrutales /> },
    { path: "/predio-datos-tecnicos-plant-forestales/:id/:codpred", element: <DTPlantForestales /> },
    { path: "/predio-datos-tecnicos-plant-transitorias/:id/:codpred", element: <DTPlantTransitorias /> },
    { path: "/predio-datos-tecnicos-observaciones/:id/:codpred", element: <DTObservaciones /> },
    { path: "/predio-datos-tecnicos-danio-emergente/:id/:codpred", element: <DTDanioemergente /> },

    { path: "/predio-datos-legales/:id/:codpred", element: <DLPadron /> },
    { path: "/predio-reportes/:id/:codpred", element: <DPReportes /> },

    { path: "/predio-linderos/:id/:codpred", element: <PredioLinderos /> },
    { path: "/ciudadanos", element: <Ciudadano /> },
];

export default RouteModPredio;

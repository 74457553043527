import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
const {$} = window;
const TrabajadorRow = ({row,nro}) => {


    useEffect(() => {
        const init = async () => {
            $('[data-toggle="tooltip"]').tooltip()
        };
        init();
    }, []);

    return (
        <>
            <tr>
                <td>{row.tipoinfraestructura}</td>
                <td>{row.infraestructura}</td>
                <td>{row.abreviatura}</td>
                <td>{row.resoministerial}</td>
                <td>{row.nrodocumento}</td>

                <td>
                    <div className="btn-group pull-right">
                        <Link  to={`/tramo-list/${row.id}`}  className="btn btn-xs btn-default" type="button" data-toggle="tooltip"
                               data-original-title={ "Administración de Sectores o Tramos" }><i
                            className="fa fa-puzzle-piece fa-lg"></i></Link>

                       

                        <Link  to={`/gestionpredial-edit/${row.id}`}  className="btn btn-xs btn-default" type="button" data-toggle="tooltip"
                               data-original-title={ "Editar" }><i
                            className="fa fa-edit fa-lg"></i></Link>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default TrabajadorRow;
import React from 'react';
import { serverFile} from '../../config/axios';

const PlantTableAfectada = ({data=[], deleteObject,updateObject}) => {
    const cabecera = ["#", "Nombre común", "Nombre científico","Variedad","Edad", "Unidad de medida","Cantidad","Utilidad",
    ,"VUPT"  ,"FOTO","Acciones"];

    console.log(data) 
    return (
        <>
            <table className="table table-bordered table-condensed table-hover table-striped" id="dataTableInvitado">
                <thead>
                <tr>
                    { cabecera.map((item,i)=>(
                        <th key={i}>{item}</th>
                    ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((obj, key) => (
                
                            <tr key={key}>
                                <td key={`td_${key}_1`}>{key+1}</td>
                                <td key={`td_${key}_2`}>{obj.nombre_comun}</td>
                                <td key={`td_${key}_3`}>{obj.nombre_cientifico}</td>
                                <td key={`td_${key}_3`}>{obj.variedad}</td>
                                <td key={`td_${key}_3`}>{obj.edad}</td>
                                <td key={`td_${key}_3`}>{obj.unidad_medida}</td>
                               
                                <td key={`td_${key}_3`}>{obj.cantidad}</td>
                               
                                <td key={`td_${key}_3`}>{obj.utilidad}</td>
                                <td key={`td_${key}_3`}>{obj.vupt}</td>
                              
                                <td key={`td_${key}_3`} title='Ver archivo'>
                                    {obj.fotooc?.path ? <a target='_blank' href={serverFile+obj?.fotooc?.path}>
                                        <i class="fa fa-picture-o" aria-hidden="true"></i>
                                    </a>: null}
                                    
                               
                                </td>
                                <td key={`td_${key}_4`} className="acciones-1bot pull-center">
                                    <div className="btn-group">
                                    <button className="btn btn-xs btn-default mright-5" type="button">
                                            <i className="fa fa-edit fa-lg" onClick={() => updateObject(obj.id)} />
                                        </button>
                                        <button className="btn btn-xs btn-default" type="button">
                                        <i
                                            className="fa fa-trash-o fa-lg"
                                            onClick={() => deleteObject(obj.id)}
                                        />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                    ))
                }
                
                </tbody>
            </table>

        </>
    );
};

export default PlantTableAfectada;
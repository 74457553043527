export var tipovias_global=[{"tipovia":"AL"}, {"tipovia":"AV"},
    {"tipovia":"BO"},
    {"tipovia":"CA"},
    {"tipovia":"CL"},
    {"tipovia":"JR"},
    {"tipovia":"PJ"},
    {"tipovia":"PL"},
    {"tipovia":"PQ"},
    {"tipovia":"PR"},
    {"tipovia":"PS"},
    {"tipovia":"PSJ"}];

export var tipourbanizacion_global=[{"tipourbanizacion":"AGR"},
    {"tipourbanizacion":"AH"},
    {"tipourbanizacion":"ASOC"},
    {"tipourbanizacion":"BARRIO"},
    {"tipourbanizacion":"CH"},
    {"tipourbanizacion":"COND"},
    {"tipourbanizacion":"COOP"},
    {"tipourbanizacion":"CP"},
    {"tipourbanizacion":"FUNDO"},
    {"tipourbanizacion":"LOT"},
    {"tipourbanizacion":"POBLADO"},
    {"tipourbanizacion":"PPJJ"},
    {"tipourbanizacion":"RES"},
    {"tipourbanizacion":"URB"},
    {"tipourbanizacion":"UV"},
    {"tipourbanizacion":"VILLA"}];

export const LISTASIDS = {
        GENERO: 1,
        TIPODOC: 2,
        TIPOPRED: 3,
        ANIO: 4,
        PROCESOSGPRED: 5,
        ESTADOPLANO: 6,
        TIPOSOLICEXT: 7,
        SOLICCANALENVIO: 8,
        REQAREALIST: 9,
        TIPODOCINTER: 10,
        TIPOREPGRAFICA: 11,
        SISTCORDENADAS: 12,
        TIPORASTER: 13,
        TIPOGENERAINFO: 14,
        TIPODOCCONSULTA: 15,
        TIPOACTAACTIVIDAD: 16,
        TIPOACTAACUERDO: 17,
        TIPOVIAS: 18,
        TIPOINTERIOR: 19,
        TIPOUSOSCOMPATIBLES: 20,
        TIPOZONIFICACION: 21,
        PREDIOTIPOCARGA: 22,
        PREDIOTIPOPERSONA: 23,
        PREDIOTIPOPERSONAJURI: 24,
        PREDIOTIPOCONDTITULAR: 25,
        ESTADOCIVIL: 26,
        TIPOADQUISICION: 27,
        TIPOLINDEROS:28, 
        TIPOCODIGOUSO:29,
        TIPOCLASIFICACIONUSO:30,
        LISTACONSTRUCCION:31,
        LISTARIESGO:32,
        LISTAAGRICOLA:33,
        LISTAGANADERIA:34,
        LISTATIPOPRINTTEMP:35
    }

export const listaplantillas = {
        generaciontdr:224
    }

export const FilesUsuario={
    "fotosUsuario":"FilesDDP/Usuario/Fotos",
    "fotos":"FilesDDP/Usuario/Fotos",
    "CV":"FilesDDP/Usuario/CV",
}

export const FilesGestionPredial={
    "FilesSolicitud":"FilesDDP/GestionPredial/Solicitudes",
    "Files":"FilesDDP/GestionPredial/Files",
    "FilesPredios":"FilesDDP/GestionPredial/Predios"
}

export const FilesIndicador={
    "FilesIndicadores":"FilesDDP/GestionPredial/Indicadores",
}

export const FilesOrdenServicio={
    "FilesOrdenes":"FilesDDP/Ordenes/Files",
}

export const FilesPlantillaImpresion={
    //Si se modifica, modificar en el backend tambien
    "FilesPlantillas":"FilesDDP/Plantillas/plantillasprint/",
}

export const FilesImagenBlog={
    "FilesImagenes":"FilesDDP/Blog/img",
}
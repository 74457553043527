 export const AGREGAR_PLANO = 'AGREGAR_PLANO';
 export const BUSCAR_PLANOS = 'BUSCAR_PLANOS';
 export const MOSTRAR_PLANO = 'MOSTRAR_PLANO';
 export const EDITAR_PLANO = 'EDITAR_PLANO';
 export const CONTINUAR_AGREGAR_PLANO = 'CONTINUAR_AGREGAR_PLANO';
// export const MOSTRAR_TRABAJADORS = 'MOSTRAR_TRABAJADORS';
// export const MOSTRAR_TRABAJADOR = 'MOSTRAR_TRABAJADOR';
// export const AGREGAR_TRABAJADOR = 'AGREGAR_TRABAJADOR';
// export const EDITAR_TRABAJADOR = 'EDITAR_TRABAJADOR';
// export const ELIMINAR_TRABAJADOR = 'ELIMINAR_TRABAJADOR';
// export const CONTINUAR_AGREGAR_TRABAJADOR = 'CONTINUAR_AGREGAR_TRABAJADOR';
// export const BUSCAR_TRABAJADOR = 'BUSCAR_TRABAJADOR';
// export const SET_FOTO = 'SET_FOTO';

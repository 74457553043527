import React from 'react';

const TableAfectada = ({data=[], deleteObject,updateObject}) => {
    const cabecera = ["VERTICE", "COD_AFECTADA", "USO_DT","LADO","DISTANCIA_M","ANG_INTERNO",
    ,"ESTE","NORTE","ESTE2","NORTE2","LADO2","COLINDANCIA","COLINDANCIA CBC"];
    return (
        <>
            <table className="table table-bordered table-condensed table-hover table-striped" id="dataTableInvitado">
                <thead>
                <tr>
                    { cabecera.map((item,i)=>(
                        <th key={i}>{item}</th>
                    ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((obj, key) => (
                        
                            <tr key={key}>
                               <td key={`td_${key}_3`}>{obj?.VERTICE}</td>
                                <td key={`td_${key}_2`}>{obj?.AREA_AFECTADA}</td>
                                <td key={`td_${key}_3`}>{obj?.USO_DT}</td>
                                
                                <td key={`td_${key}_3`}>{obj?.LADO}</td>
                                <td key={`td_${key}_3`}>{obj?.DISTANCIA_M}</td>
                                <td key={`td_${key}_3`}>{obj?.ANG_INTERNO}</td>
                                <td key={`td_${key}_3`}>{obj?.ESTE}</td>
                                <td key={`td_${key}_3`}>{obj?.NORTE}</td>
                                <td key={`td_${key}_3`}>{obj?.ESTE2}</td>
                                <td key={`td_${key}_3`}>{obj?.NORTE2}</td>
                                <td key={`td_${key}_3`}>{obj?.LADO2}</td>
                                <td key={`td_${key}_3`}>{obj?.COLINDANCIA}</td>
                                <td key={`td_${key}_3`} style={{ fontSize: '9px' , width: '300px' }}>{obj?.COLINDANCIA_CBC}</td>
                              
                            </tr>
                    ))
                }
                
                </tbody>
            </table>

        </>
    );
};

export default TableAfectada;
import React, { useEffect, useState } from "react";
import { initAxiosInterceptors } from '../../config/axios';
import { EDICION_PREDIOS_BREADCRUM } from "../../config/breadcrums";
import { Link, useParams } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import WraperLarge from "../m000_common/formContent/WraperLarge";
import {
    Form,
    FormGroup,
    Row6,
    Row12,
    RowForm,
    Select,
    Input,
    Options,
    FormControl,
    InputInline,
    FormFooter,
    Table, TextArea
} from "../../components/forms";
import ComboOptions from "../../components/helpers/ComboOptions";
import { useForm } from '../../hooks/useForm';
import { useAsync } from "react-async-hook";

import PredioLinks from "./PredioLinksTecnico";
import * as helperGets from "../../components/helpers/LoadMaestros";
import MAddAreasLinderos from "./MAddAreasLinderos";
import { MAddGanadera } from "./MAddGanadera";
import { MAddAgricola } from "./MAddAgricola";

import { isObjectValid, list_estado_predio, list_periodo_tipo_tasacion, list_tipo_tasacion, list_tipo_pre, list_cond_rustico, list_uso_predio, list_tipo_via, list_lado, list_topografia, list_dano, list_datum } from './DTValidadores'

const { $ } = window;
const Axios = initAxiosInterceptors();

async function getDatoPredio (id) {
    const { data } = await Axios.get(`/predio/${id}`);
    return data;
}

async function getdata (object) {
    const { data } = await Axios.get(`/list_dt_padron?predioid=` + object.id);
    return data;
}

async function getListaTrabajadores (object) {
    const { data } = await Axios.get(`/usuario?busqueda=&limit=10000&page=1`);

    let trabajadores = data?.rows.map(({ id, nombres, apellidos }) => ({
        id,
        nombreCompleto: `${apellidos} ${nombres}`
    }));

    // Ordenar alfabéticamente por nombre completo
    trabajadores.sort((a, b) => a.nombreCompleto.localeCompare(b.nombreCompleto));

    return trabajadores;
}

async function save (object) {
    const { data } = await Axios.post(`/save_dt_padron`, object);
    return data;
}

function generarListaMeses () {

    const meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
        "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    let listaMeses = [];
    const añoActual = new Date().getFullYear();
    // Comenzar desde junio del año actual
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual - 1}`, descripcion: `${meses[i]} ${añoActual - 1}` });
    }

    // Comenzar desde junio del año actual
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual}` });
    }

    // Continuar hasta mayo del año siguiente
    for (let i = 0; i < meses.length; i++) {
        listaMeses.push({ id: `${meses[i]} ${añoActual + 1}`, descripcion: `${meses[i]} ${añoActual + 1}` });
    }

    return { listaMeses };
}

const DTPadron = ({ history, match }) => {

    //Obtener los parameotros
    const { id, codpred } = useParams();

    //Definicion de estado del formularios
    const [object, setObject, handleInputChange, reset] = useForm({}, []);
    const [predio, setPredio] = useState({});
    const listameses = generarListaMeses();
    const listaDepartmento = useAsync(helperGets.helperGetListDepartamento, []);
    const listaProvincia = useAsync(helperGets.helperGetListProvincia, []);
    const listaDistrito = useAsync(helperGets.helperGetListDistrito, []);
    const [listaTrabajadores, setListaTrabajadores] = useState({
        trabajadores: [
            { "id": "ACTUAL", "nombreCompleto": "ACTUAL" },
            { "id": "RETROESPECTIVA", "nombreCompleto": "RETROESPECTIVA" },

        ]
    });
    const [dataProvincia, setDataProvincia] = useState(null);
    const [dataDisttrito, setDataDisttrito] = useState(null);

    //Traer el objeto de datos del predio seleccionado
    useEffect(() => {
        const init = async () => {

            //obtenieno los datos predio
            let predio = await getDatoPredio(id);
            setPredio(predio);

            let trabajadores = await getListaTrabajadores()




            setListaTrabajadores({
                trabajadores: trabajadores
            });

            localStorage.setItem("predioselec", JSON.stringify(predio))
            // alert(predio.id)
            let data = await getdata(predio);
            console.log(data)
            setObject({ ...data, predioid: predio.id })



        }
        init();
    }, []);

    const actualizar = async (e) => {
        e.preventDefault();


        try {

            await save(object);
            toastr.success(
                "Guardando",
                "El registro fue guardado correctamente.",
                { position: "top-right" }
            );

            //history.push("/blog");
        } catch (e) {

            toastr.error('Ocurrio un error', e.message, { position: 'top-center' })
        }
    }

    /*Gestion de distrito*/

    function handleChangeDepartmento (e) {
        setValuesProvincia(e.target.value);
    }

    function setValuesProvincia (value) {
        if (!listaProvincia.loading) {
            let data = listaProvincia.result;
            let provList = data[Object.keys(data)[0]].filter(o => o.id_dpto === value);
            setDataProvincia({ data: provList });
            setDataDisttrito(null);
        }
    }

    function handleChangeProvincia (e) {
        setValuesDistrito(e.target.value);
    }


    async function handleChangeDistrito (e) {

        let { gestionpredialid, tipopredioid, tramoid, codigoubigeo = e.target.value } = predio
        try {

            let result = null;
            console.log(e.target.value)
            if (!e.target.value) {
                throw {
                    error: "Se encontró un error",
                    message: "No falta parametros para generar correctamente el código del Predio.",
                    status: 405
                };
            }
            //result = await getcodePredio({ gestionpredialid, tipopredioid, tramoid, codigoubigeo });
            // setPredio({ ...predio, codigopredio: result.codpredio })


        }
        catch (e) {

            toastr.error('Generación de código del predio', "Se encontró un error: " + e.message);

        }
    }

    function setValuesDistrito (value) {
        if (!listaDistrito.loading) {
            let data = listaDistrito.result;
            let distList = data[Object.keys(data)[0]].filter(o => o.id_prov === value);
            setDataDisttrito({ data: distList });
        }
    }



    return (
        <>
            <WraperLarge titleForm={"PREDIO: " + codpred + " / PADRON DT"} listbreadcrumb={EDICION_PREDIOS_BREADCRUM} >
                <PredioLinks active="1"></PredioLinks>
                <Form onSubmit={actualizar}>
                    <div className="mtop-35"></div>
                    <RowForm>

                        <div className="col-lg-12">
                            {isObjectValid(object) ? <h6 className="obligatorio"> Formulario no guardado </h6> : null}
                            <fieldset className="mleft-20">

                                <legend>Datos de expediente</legend>
                                <Row6>
                                    <FormGroup label={"Código del predio"} >
                                        <Input
                                            required={false}
                                            value={predio?.codigopredio || ""}
                                            //onChange={handleInputChange}
                                            name={"titulo"}
                                            placeholder={"Ingrese titulo de la entrada"}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Profesional tecnico resposable"} require={true} >

                                        <Select value={object?.profesionalTecnicoResponsable || ""}
                                            onChange={handleInputChange}
                                            name={"profesionalTecnicoResponsable"}>
                                            <ComboOptions data={listaTrabajadores} valorkey="id" valornombre="nombreCompleto" />
                                        </Select>


                                    </FormGroup>
                                    <FormGroup label={"Estado del predio."}>
                                        <Select value={object?.estadoExpediente || ""}
                                            onChange={handleInputChange}
                                            name={"estadoExpediente"}>
                                            <ComboOptions data={list_estado_predio} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"Tipo periodo tasación."}>
                                        <Select value={object?.tipo_periodo_tasacion || ""}
                                            onChange={handleInputChange}
                                            name={"tipo_periodo_tasacion"}>
                                            <ComboOptions data={list_periodo_tipo_tasacion} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                </Row6>
                                <Row6>
                                    <FormGroup label={"Código expediente"} >
                                        <Input
                                            value={predio?.codexpediente || ""}
                                            //onChange={handleInputChange}
                                            name={"fechainspeccion"}
                                            type={"text"}

                                        >
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Mes elaboración Exp."}>
                                        <Select value={object?.mesElaboracionExpediente || ""}
                                            onChange={handleInputChange}
                                            name={"mesElaboracionExpediente"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>
                                    <FormGroup label={"Tipo de tasación."}>
                                        <Select value={object?.tipo_tasacion || ""}
                                            onChange={handleInputChange}
                                            name={"tipo_tasacion"}>
                                            <ComboOptions data={list_tipo_tasacion} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"Fecha Inspeccion campo."}>
                                        <Input
                                            required={false}
                                            value={object?.fecha_inspeccion || ""}
                                            onChange={handleInputChange}
                                            name={"fecha_inspeccion"}
                                            placeholder={""}
                                            type={"date"}>
                                        </Input>
                                    </FormGroup>

                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Ubicacion</legend>
                                <Row6>

                                    <FormGroup label={"Tipo predio."}>
                                        <Select value={object?.tipoPredio || ""}
                                            onChange={handleInputChange}
                                            name={"tipoPredio"}>
                                            <ComboOptions data={list_tipo_pre} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"Cond. rustico."}>
                                        <Select value={object?.cond_rustico || ""} disabled={object?.tipoPredio !== 'RURAL'}
                                            onChange={handleInputChange}
                                            name={"cond_rustico"}>
                                            <ComboOptions data={list_cond_rustico} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>


                                    <FormGroup label={"Uso."}>
                                        <Select value={object?.uso || ""}
                                            onChange={handleInputChange}
                                            name={"uso"}>
                                            <ComboOptions data={list_uso_predio} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"Denominacion."}>
                                        <Input
                                            required={false}
                                            value={object?.denominacion || ""}
                                            onChange={handleInputChange}
                                            name={"denominacion"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Manzana."}>
                                        <Input
                                            required={false}
                                            value={object?.manzana || ""}
                                            onChange={handleInputChange}
                                            name={"manzana"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Lote."}>
                                        <Input
                                            required={false}
                                            value={object?.lote || ""}
                                            onChange={handleInputChange}
                                            name={"lote"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Nombre via."}>
                                        <Input
                                            required={false}
                                            value={object?.nombreVia || ""}
                                            onChange={handleInputChange}
                                            name={"nombreVia"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Interior."}>
                                        <Input
                                            required={false}
                                            value={object?.interior || ""}
                                            onChange={handleInputChange}
                                            name={"interior"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"lado."}>
                                        <Select value={object?.lado || ""}
                                            onChange={handleInputChange}
                                            name={"lado"}>
                                            <ComboOptions data={list_lado} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                </Row6>
                                <Row6>

                                    <FormGroup label={"Zonificacion."}>
                                        <Input
                                            required={false}
                                            value={object?.zonificacion || ""}
                                            onChange={handleInputChange}
                                            name={"zonificacion"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Norma que aprueba."}>
                                        <Input
                                            required={false}
                                            value={object?.norma_aprueba || ""}
                                            onChange={handleInputChange}
                                            name={"norma_aprueba"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>
                                    <FormGroup label={"Comunidad Campesina."}>
                                        <Input
                                            required={false}
                                            value={object?.comunidadCampesina || ""}
                                            onChange={handleInputChange}
                                            name={"comunidadCampesina"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Sector."}>
                                        <Input
                                            required={false}
                                            value={object?.sector || ""}
                                            onChange={handleInputChange}
                                            name={"sector"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"UC."}>
                                        <Input
                                            required={false}
                                            value={object?.uc || ""}
                                            onChange={handleInputChange}
                                            name={"uc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"Via."}>
                                        <Select value={object?.via || ""}
                                            onChange={handleInputChange}
                                            name={"via"}>
                                            <ComboOptions data={list_tipo_via} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>
                                    <FormGroup label={"No Municipal."}>
                                        <Input
                                            required={false}
                                            value={object?.numeroMunicipal || ""}
                                            onChange={handleInputChange}
                                            name={"numeroMunicipal"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                    <FormGroup label={"progresivaInicial."}>
                                        <Input
                                            required={false}
                                            value={object?.progresivaInicial || ""}
                                            onChange={handleInputChange}
                                            name={"progresivaInicial"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"progresivaFinal."}>
                                        <Input
                                            required={false}
                                            value={object?.progresivaFinal || ""}
                                            onChange={handleInputChange}
                                            name={"progresivaFinal"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>




                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>


                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Topografia</legend>
                                <Row6>

                                    <FormGroup label={"topografia."}>
                                        <Select value={object?.topografia || ""}
                                            onChange={handleInputChange}
                                            name={"topografia"}>
                                            <ComboOptions data={list_topografia} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>


                                    <FormGroup label={"pistasVeredas."}>
                                        <Select value={object?.pistasVeredas || ""}
                                            onChange={handleInputChange}
                                            name={"pistasVeredas"}>
                                            <ComboOptions data={list_dano} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"instalacionesGas."}>
                                        <Select value={object?.instalacionesGas || ""}
                                            onChange={handleInputChange}
                                            name={"instalacionesGas"}>
                                            <ComboOptions data={list_dano} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>
                                    <FormGroup label={"aguaPotable."}>
                                        <Select value={object?.aguaPotable || ""}
                                            onChange={handleInputChange}
                                            name={"aguaPotable"}>
                                            <ComboOptions data={list_dano} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>


                                </Row6>
                                <Row6>
                                    <FormGroup label={"accesibilidad."}>
                                        <Input
                                            required={false}
                                            value={object?.accesibilidad || ""}
                                            onChange={handleInputChange}
                                            name={"accesibilidad"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"alumbradoPublico."}>
                                        <Select value={object?.alumbradoPublico || ""}
                                            onChange={handleInputChange}
                                            name={"alumbradoPublico"}>
                                            <ComboOptions data={list_dano} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"alcantarillado."}>
                                        <Select value={object?.alcantarillado || ""}
                                            onChange={handleInputChange}
                                            name={"alcantarillado"}>
                                            <ComboOptions data={list_dano} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>








                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>...</legend>
                                <Row6>
                                    <FormGroup label={"Area Grafica Matriz. (m2)"}>
                                        <Input
                                            required={false}
                                            value={object?.areaGraficaMatriz || ""}
                                            onChange={handleInputChange}
                                            name={"areaGraficaMatriz"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >
                                        </Input>
                                    </FormGroup>




                                    <FormGroup label={"partidaElectronicaParaCbc."}>
                                        <Input
                                            required={false}
                                            value={object?.partidaElectronicaParaCbc || ""}
                                            onChange={handleInputChange}
                                            name={"partidaElectronicaParaCbc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    {/* <FormGroup label={"fechaInspeccionCampo."}>
                                        <Input
                                            required={false}
                                            value={object?.fechaInspeccionCampo || ""}
                                            onChange={handleInputChange}
                                            name={"fechaInspeccionCampo"}
                                            placeholder={""}
                                            type={"date"}>
                                        </Input>
                                    </FormGroup> */}


                                </Row6>
                                <Row6>

                                    <FormGroup label={"Area Registral Matriz. (m2)"}>
                                        <Input
                                            required={false}
                                            value={object?.areaRegistralMatriz || ""}
                                            onChange={handleInputChange}
                                            name={"areaRegistralMatriz"}
                                            placeholder={""}
                                            type={"number"}
                                            step={"any"}
                                        >
                                        </Input>
                                    </FormGroup>
                                    {/* <FormGroup label={"observacionesTecnica."}>
                                        <Input
                                            required={false}
                                            value={object?.observacionesTecnica || ""}
                                            onChange={handleInputChange}
                                            name={"observacionesTecnica"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup> */}

                                    <FormGroup label={"profesionalElaboroCbc."}>
                                        <Input
                                            required={false}
                                            value={object?.profesionalElaboroCbc || ""}
                                            onChange={handleInputChange}
                                            name={"profesionalElaboroCbc"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    {/*"DAÑO EMERGENTE
SI/NO"	OBSERVACIONES TECNICA	PARTIDA ELECTRONICA PARA CBC	"PROFESIONAL
ELABORO CBC"	FECHA DE INSPECCION DE CAMPO*/}



                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Observaciones tecnicas</legend>

                                <TextArea required={false} value={object?.observacionesTecnica} onChange={handleInputChange}
                                    name={"observacionesTecnica"} placeholder={"Ingrese la observación"}
                                    type={"text"}>
                                </TextArea>

                            </fieldset>

                        </div>


                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Datos de los planos</legend>
                                <Row6>
                                    <FormGroup label={"profesionalTecnicoResponsableFirmarPlanos."}>
                                        <Input
                                            required={false}
                                            value={object?.profesionalTecnicoResponsableFirmarPlanos || ""}
                                            onChange={handleInputChange}
                                            name={"profesionalTecnicoResponsableFirmarPlanos"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>


                                    <FormGroup label={"fechaElaboracionPlanoDiagnostico."}>


                                        <Select value={object?.fechaElaboracionPlanoDiagnostico || ""}
                                            onChange={handleInputChange}
                                            name={"fechaElaboracionPlanoDiagnostico"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"fechaElaboracionPlanoUbicacion."}>

                                        <Select value={object?.fechaElaboracionPlanoUbicacion || ""}
                                            onChange={handleInputChange}
                                            name={"fechaElaboracionPlanoUbicacion"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"fechaElaboracionPlanoPerimetrico."}>
                                        <Select value={object?.fechaElaboracionPlanoPerimetrico || ""}
                                            onChange={handleInputChange}
                                            name={"fechaElaboracionPlanoPerimetrico"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>

                                    <FormGroup label={"fechaElaboracionPlanoAfectacion."}>
                                        <Select value={object?.fechaElaboracionPlanoAfectacion || ""}
                                            onChange={handleInputChange}
                                            name={"fechaElaboracionPlanoAfectacion"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>

                                    <FormGroup label={"fechaElaboracionPlanoDistribucion."}>


                                        <Select value={object?.fechaElaboracionPlanoDistribucion || ""}
                                            onChange={handleInputChange}
                                            name={"fechaElaboracionPlanoDistribucion"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>










                                    <FormGroup label={"fechaElaboracionPlanoArquitectura."}>

                                        <Select value={object?.fechaElaboracionPlanoArquitectura || ""}
                                            onChange={handleInputChange}
                                            name={"fechaElaboracionPlanoArquitectura"}>
                                            <ComboOptions data={listameses} valorkey="id" valornombre="descripcion" />
                                        </Select>

                                    </FormGroup>

                                    <FormGroup label={"firmaPlanoArquitectura."}>
                                        <Input
                                            required={false}
                                            value={object?.firmaPlanoArquitectura || ""}
                                            onChange={handleInputChange}
                                            name={"firmaPlanoArquitectura"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                </Row6>
                                <Row6>

                                    <FormGroup label={"Datum de los planos."}>
                                        <Select value={object?.datumPlanos || ""}
                                            onChange={handleInputChange}
                                            name={"datumPlanos"}>
                                            <ComboOptions data={list_datum} valorkey="id" valornombre="descripcion" />
                                        </Select>
                                    </FormGroup>


                                    <FormGroup label={"codigoPlanoDiagnostico."}>
                                        <Input
                                            required={false}
                                            value={object?.codigoPlanoDiagnostico || ""}
                                            onChange={handleInputChange}
                                            name={"codigoPlanoDiagnostico"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                    <FormGroup label={"codigoPlanoUbicacion."}>
                                        <Input
                                            required={false}
                                            value={object?.codigoPlanoUbicacion || ""}
                                            onChange={handleInputChange}
                                            name={"codigoPlanoUbicacion"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                    <FormGroup label={"codigoPlanoPerimetrico."}>
                                        <Input
                                            required={false}
                                            value={object?.codigoPlanoPerimetrico || ""}
                                            onChange={handleInputChange}
                                            name={"codigoPlanoPerimetrico"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>



                                    <FormGroup label={"codigoPlanoAfectacion."}>
                                        <Input
                                            required={false}
                                            value={object?.codigoPlanoAfectacion || ""}
                                            onChange={handleInputChange}
                                            name={"codigoPlanoAfectacion"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"codigoPlanoDistribucion."}>
                                        <Input
                                            required={false}
                                            value={object?.codigoPlanoDistribucion || ""}
                                            onChange={handleInputChange}
                                            name={"codigoPlanoDistribucion"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup label={"codigoPlanoArquitectura."}>
                                        <Input
                                            required={false}
                                            value={object?.codigoPlanoArquitectura || ""}
                                            onChange={handleInputChange}
                                            name={"codigoPlanoArquitectura"}
                                            placeholder={""}
                                            type={"text"}>
                                        </Input>
                                    </FormGroup>


                                    {/*"DAÑO EMERGENTE
SI/NO"	OBSERVACIONES TECNICA	PARTIDA ELECTRONICA PARA CBC	"PROFESIONAL
ELABORO CBC"	FECHA DE INSPECCION DE CAMPO*/}



                                </Row6>

                            </fieldset>
                        </div>



                    </RowForm>

                    <RowForm>

                        <div className="col-lg-12">
                            <fieldset className="mleft-20">
                                <legend>Datos Memoria descriptiva</legend>
                                <Row12>
                                    <FormGroup label={"Fecha Emisión."}>
                                        <Input
                                            required={false}
                                            value={object?.fecha_emision || ""}
                                            onChange={handleInputChange}
                                            name={"fecha_emision"}
                                            placeholder={""}
                                            type={"date"}>
                                        </Input>
                                    </FormGroup>
                                </Row12>
                            </fieldset>
                        </div>

                    </RowForm>

                    <FormFooter>
                        <Link to={`/predio-list`}
                            className="btn btn-default btn-sm btn-control">Cancelar</Link>
                        <button id="btnguardar" type="submit"
                            className="btn btn-danger btn-sm btn-control">Guardar
                        </button>
                    </FormFooter>
                </Form>

            </WraperLarge>
        </>
    );
};

export default DTPadron;


export const REGISTRO_TRABAJADOR_BREADCRUM = [
    {link: "trabajador-add", name: "Gestion Predial"},
    {link: "list-trabajadores", name: "Listado de Trabajadores"},
    {link: "#", name: "Agregar Trabajador"}
]

export const ACTUALIZAR_TRABAJADOR_BREADCRUM = [
    {link: "trabajador-add", name: "Gestion Predial"},
    {link: "../list-trabajadores", name: "Listado de Trabajadores"},
    {link: "#", name: "Actualizar Trabajador"}
]

export const ELIMINAR_TRABAJADOR_BREADCRUM = [
    {link: "/", name: "Gestion Predial"},
    {link: "../list-trabajadores", name: "Listado de Trabajadores"},
    {link: "#", name: "Eliminar Trabajador"}
]


export const CHANGEPASS_TRABAJADOR_BREADCRUM = [
    {link: "/", name: "Gestion Predial"},
    {link: "../list-trabajadores", name: "Listado de Trabajadores"},
    {link: "#", name: "Cambiar la contraseña"}
]


export const LISTADO_TRABAJADOR_BREADCRUM = [
    {link: "trabajador-add", name: "Gestion Predial"},
    {link: "list-trabajadores", name: "Listado de Trabajadores"}
]


export const LISTADO_FICHAS_RENIEC = [
    {link: "../predio-list", name: "Gestion Predial"},
    {link: "ciudadanos", name: "Listado de Fichas Reniec"}
]

export const REGISTRO_PARTIDA_BREADCRUM = [
    {link: "partidas", name: "Listado de Partidas"},
    {link: "partida-add", name: "Registrar Partida"}
    
    // {link: "partida-edit", name: "Editar Partida"}
]

export const LISTADO_PARTIDA_BREADCRUM = [
    {link: "partidas", name: "Listado de Partidas"},
    {link: "partida-add", name: "Registrar Partida"}
    
]

export const ACTUALIZAR_PARTIDA_BREADCRUM = [
    {link: "../../partida-add", name: "Registrar Partida"},
    {link: "../../partidas", name: "Listado de Partidas"},
    // {link: "partida-add", name: "Actualizar Partida"}
]

export const ELIMINAR_PARTIDA_BREADCRUM = [
    {link: "/partidas", name: "Registrar Partida"},
    {link: "../../partidas", name: "Listado de Partidas"},
    // {link: "#", name: "Eliminar Partidas Registrales"}
]

//region Gestion Predial

export const LISTAR_GESTIONPREDIAL_BREADCRUM = [
    {link: "../../", name: "Gestion Predial"},
    {link: "#", name: "Registro de proyecto"}
]


export const REGISTRO_GESTIONPREDIAL_BREADCRUM = [
    {link: "../../", name: "Gestion Predial"},
    {link: "gestionpredial", name: "Registro de proyecto"},
    {link: "#", name: "Registro Gestión Predial"}
]

export const ACTUALIZA_GESTIONPREDIAL_BREADCRUM = [
    {link: "../../", name: "Gestion Predial"},
    {link: "../../gestionpredial", name: "Registro de proyecto"},
    {link: "#", name: "Actualiza Gestión Predial"}
]

//endregion


export const LISTADO_EQUIPO_BREADCRUM = [
    {link: "equipo-add", name: "Agregar Equipos"},
    {link: "list-equipos2", name: "Listado de Equipos"}
]

export const REGISTRO_EQUIPO_BREADCRUM = [
    {link: "equipo-add", name: "Agregar Equipos"},
    {link: "list-equipos2", name: "Listado de Equipos"},
    {link: "#", name: "Agregar Equipos"}
]

export const ACTUALIZAR_EQUIPO_BREADCRUM = [
    {link: "../equipo-add", name: "Agregar Equipos"},
    {link: "../list-equipos2", name: "Listado de Equipos"},
    {link: "#", name: "Actualizar Equipo"}
]

export const ELIMINAR_EQUIPO_BREADCRUM = [
    {link: "../list-equipos2", name: "Listado de Equipos"},
    {link: "#", name: "Eliminar Equipo"}
]

export const LISTADO_AREA_BREADCRUM = [
    {link: "area-list", name: "Listado de areas"},
    {link: "area-add", name: "Agregar de areas"}
]

export const REGISTRO_AREA_BREADCRUM = [
    {link: "area-add", name: "Agregar de areas"},
    {link: "area-list", name: "Listado de areas"}
]

export const ELIMINAR_AREA_BREADCRUM = [
    {link: "../area-list", name: "Listado de Areas"},
    {link: "#", name: "Eliminar Area"}
]

export const ACTUALIZAR_AREA_BREADCRUM = [
    {link: "../area-add", name: "Agregar area"},
    {link: "../area-list", name: "Listado de area"},
    {link: "#", name: "Actualizar Area"}
]

export const REGISTRO_ACTA_BREADCRUM = [
    {link: "acta-add", name: "Agregar de acta"}
]

export const ELIMINAR_ACTA_BREADCRUM = [
    {link: "../acta-list", name: "Listado de actas"},
    {link: "#", name: "Eliminar Acta"}
]

export const ACTUALIZAR_ACTA_BREADCRUM = [
    {link: "../acta-list", name: "Listado de actas"},
    {link: "#", name: "Actualizar Acta"}
]

export const LISTADO_ACTA_BREADCRUM = [
    {link: "acta-list", name: "Listado de actas"},
    {link: "acta-add", name: "Agregar de actas"}
]

export const LISTADO_ACUERDO_BREADCRUM = [
    {link: "acuerdo-list", name: "Listado de acuerdos"},
    {link: "acta-list", name: "Listado de actas"}
]

export const REGISTRO_SOLICITUD_BREADCRUM = [
        {link: "solicitud-list", name: "Listado de Solicitudes"},
    {link: "#", name: "Agregar Solicitud"}
]

export const ACTUALIZAR_SOLICITUD_BREADCRUM = [
    {link: "solicitud-add", name: "Agregar Solicitud"},
    {link: "../solicitud-list", name: "Listado de Solicitudes"},
    {link: "#", name: "Actualizar Solicitudes"}
]

export const LISTADO_SOLICITUD_BREADCRUM = [
    {link: "solicitud-list", name: "Listado de Solicitudes"}
]

export const REGISTRO_PLANO_BREADCRUM = [
    {link: "planos", name: "Listado de Planos"},
    {link: "#", name: "Agregar Plano"}
]

export const ACTUALIZAR_PLANO_BREADCRUM = [
    {link: "../planos", name: "Listado de Planos"},
    {link: "#", name: "Actualizar Plano"}
]

export const CODIGOS_PLANO_BREADCRUM = [
    {link: "planos", name: "Listado de Planos"},
    {link: "#", name: "Generación de Códigos de Plano"}
]

export const LISTADO_PLANO_BREADCRUM = [
    {link: "#", name: "Listado de Planos"}
]

/*Region de Inicadores*/
export const LISTADO_INDICADERES_BREADCRUM = [
    {link: "/", name: "Gestión Predial"},
    {link: "indicadores", name: "Listado de Indicadores"}
]

export const REGISTRO_INDICADERES_BREADCRUM = [
    {link: "indicadores", name: "Gestión Predial"},
    {link: "../indicadores", name: "Listado de Indicadores"}
]

export const ACTUALIZAR_INDICADERES_BREADCRUM = [
    {link: "indicadores", name: "Gestión Predial"},
    {link: "indicadores", name: "Listado de Indicadores"}
]

/*Fin indicadores*/


export const LISTAR_GESTIONPREDIALPOLIGONO_BREADCRUM = [
    {link: "/gestionpredial", name: "Listado de Gestión Predial"},
    {link: "#", name: "Listado de Polígonos para La Gestión Predial"}
]


export const VALIDA_GESTIONPREDIALPOLIGONO_BREADCRUM = [
    {link: "/gestionpredial", name: "Listado de Gestión Predial"},
    {link: "#", name: "Registro de Polígono de Gestión Predial"}
]

export const LISTADO_DOCINTERNOS_BREADCRUM = [
    {link: "docinternos", name: "Listado de Documentos internos"},
    {link: "docinternos-add", name: "Agregar Documentos internos"}
]

export const REGISTRO_DOCINTERNOS_BREADCRUM = [
    {link: "docinternos", name: "Listado de Documentos internos"},
    {link: "docinternos-add", name: "Agregar Documentos internos"}
]

export const ACTUALIZAR_DOCINTERNOS_BREADCRUM = [
    {link: "../docinternos", name: "Listado de Documentos internos"},
    {link: "../docinternos-add", name: "Agregar Documentos internos"}
]

export const LISTADO_TRAMOS_BREADCRUM = [
    {link: "/gestionpredial", name: "Listado de Gestión Predial"},
    {link: "#", name: "Listado de Tramos - Sectores"}
]

export const REGISTRO_TRAMOS_BREADCRUM = [
    {link: "/gestionpredial", name: "Listado de Gestión Predial"},
    {link: "#", name: "Registro-Edición de Tramo"}
]

export const LISTADO_PREDIOS_BREADCRUM = [
    {link: "#", name: "Listado de Predios"}
]

export const REGISTRO_PREDIOS_BREADCRUM = [
    {link: "/predio-list", name: "Listado de Predios"},
    {link: "#", name: "Registro de Predio"}
]

export const EDICION_PREDIOS_BREADCRUM = [
    {link: "/predio-list", name: "Listado de Predios"},
    {link: "#", name: "Edición de Datos de Predio"}
]

export const LISTADO_BLOG_BREADCRUM = [
    {link: "/blog", name: "Blog de la DDP"},
    
]

export const LISTADO_REQOS_BREADCRUM = [
    {link: "#", name: "Listado de Requerimientos (Ordenes de Servicio)"}
]

export const REGISTRO_REQOS_BREADCRUM = [
    {link: "/orden-list", name: "Listado de Requerimientos (Ordenes de Servicio)"},
    {link: "#", name: "Registro de Requerimiento (Orden de Servicio)"}
]

export const EDICION_REQOS_BREADCRUM = [
    {link: "/orden-list", name: "Listado de Requerimientos (Ordenes de Servicio)"},
    {link: "#", name: "Edición de Datos de Requerimiento (Orden de Servicio)"}
]

export const EDICION_PLANTILLAIMP_BREADCRUM = [
    {link: "/printtemp-list", name: "Listado de Plantillas de Impresión"},
    {link: "#", name: "Edición de Datos de Plantilla"}
]

export const LISTADO_PLANTILLAIMP_BREADCRUM = [
    {link: "#", name: "Listado de Plantillas de Impresion"}   
]
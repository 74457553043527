import Indicadores from "../sigespred/_ddp_indicador/Indicadores";
import IndicadorAdd from "../sigespred/_ddp_indicador/IndicadorAdd";
import IndicadorEdit from "../sigespred/_ddp_indicador/IndicadorEdit";
import IndicadorDel from "../sigespred/_ddp_indicador/IndicadorDel";
import IndicadorVisor from "../sigespred/_ddp_indicador/IndicadorVisor";

const RouteModIndicadores = [
    {path: "/indicadores", element: <Indicadores/>},
    {path: "/indicador-add", element: <IndicadorAdd/>},
    {path: "/indicador-edit/:id", element: <IndicadorEdit/>},
    {path: "/indicador-del/:id", element: <IndicadorDel/>},
    {path: "/indicador-visor/:id", element: <IndicadorVisor/>},


]

export default RouteModIndicadores;
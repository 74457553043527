import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import {
    Select, Form, Input, FormGroup

} from "../../components/forms";
import SingleUpload from "../../components/uploader/SingleUpload";
import { FilesGestionPredial } from "../../config/parameters";
import ComboOptions from "../../components/helpers/ComboOptions";
import { list_uso_oc, list_plant_frutales, list_unidades_medida, list_material, list_estado_construccion_vivienda, list_estado_conservacion } from './DTValidadores'
import moment from 'moment';

const MPlantaciones = ({ object, closeventana, usevalue }) => {
    const [invitado, setInvitado] = useState({});
    const [lblaction, setlblaction] = useState("Agregar");

    useEffect(() => {
        function initialLoad () {
            var idDate = moment().format("HHmmss");
            if (object.id) {

                setInvitado({
                    ...object,
                    update: true
                });
                setlblaction('Actualizar')
                // setInvitado(object)

            } else {
                setInvitado({
                    ...invitado,
                    id: idDate,
                    update: false
                });
            }

        }
        initialLoad();
    }, []);

    const closeModal = () => {
        closeventana(false);
    }

    const addUpdateValor = (e) => {

        e.preventDefault();
        usevalue(invitado);

    }

    const handleInputChange = (e) => {

        setInvitado({
            ...invitado,
            [e.target.name]: e.target.value.toUpperCase()
        });
    }

    /*Permite Filtrar la infraestructura en relacion a una tipo de infraestrucutra*/
    const setNombreCientifico = (e, list_plant_frutales) => {

        let value = e.target.value

        let listInfraes = list_plant_frutales.data.find(row => {
            return row.nombre_comun_fo == value;
        });

        console.log(listInfraes)
        setInvitado({
            ...invitado,
            nombre_cientifico: listInfraes["Nombre_cientifico_fo"],
            nombre_comun: value
        });
    }

    return (
        <>
            <div className={"form-horizontal"}>
                <div>
                    <div id="lightCustomModal_background" className="popup_background backblq"></div>
                    <div id="lightCustomModal_wrapper" className="popup_wrapper bloqueador">
                        <div style={{ transform: 'scale(1)', alignContent: 'left' }}
                            className="custom-popup light  popup_content popup_content_visible bloqueador2"
                            id="lightCustomModal"
                            data-popup-initialized="true" aria-hidden="false" role="dialog" aria-labelledby="open_20531909"
                            tabIndex="-1">
                            <a onClick={closeModal} className="btn  m-right-sm lightCustomModal_close pull-right">
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </a>
                            <div className=" " style={{ width: '500px' }}>
                                <div className="modal-header">
                                    <h4>{lblaction}</h4>
                                </div>
                                <Form onSubmit={addUpdateValor}>


                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Nombre cómun
                                        </label>
                                        <div className="col-lg-8">
                                            <Select required={true} value={invitado?.nombre_comun || ""}
                                                onChange={(e) => { handleInputChange(e); setNombreCientifico(e, list_plant_frutales) }}
                                                name={"nombre_comun"}>
                                                <ComboOptions data={{ data: list_plant_frutales.data.filter(o => o.tipo === "FRUTALES") }} valorkey="id" valornombre="nombre_comun_fo" />
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Nombre Cientifico
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.nombre_cientifico || ""}
                                                onChange={handleInputChange}

                                                placeholder={""}
                                                type={"text"}
                                                readOnly={true}>

                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Variedad
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.variedad || ""}
                                                onChange={handleInputChange}
                                                name={"variedad"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Edad (Años)
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.edad || ""}
                                                onChange={handleInputChange}
                                                name={"edad"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Unidad Medida
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.unidad || "UND"}
                                                onChange={handleInputChange}
                                                name={"unidad"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Cantidad
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.cantidad || ""}
                                                onChange={handleInputChange}
                                                name={"cantidad"}
                                                placeholder={""}
                                                type={"number"}
                                                step={"any"}
                                            >
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio">* </span> Utilidad
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={true}
                                                value={invitado?.utilidad || ""}
                                                onChange={handleInputChange}
                                                name={"utilidad"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>




                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span> VUPP
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.vupp || ""}
                                                onChange={handleInputChange}
                                                name={"vupp"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <div className="form-group mtop-10">
                                        <label className="col-lg-4 control-label">
                                            <span className="obligatorio"> </span> Observación
                                        </label>
                                        <div className="col-lg-8">
                                            <Input
                                                required={false}
                                                value={invitado?.observacion || ""}
                                                onChange={handleInputChange}
                                                name={"observacion"}
                                                placeholder={""}
                                                type={"text"}>
                                            </Input>
                                        </div>
                                    </div>

                                    <FormGroup label={"Foto Plantación"}>
                                        <SingleUpload
                                            key="foto"
                                            accept={'.*'}
                                            folderSave={FilesGestionPredial.FilesPredios}
                                            form={invitado}
                                            setForm={setInvitado}
                                            nameUpload={"fotooc"}
                                        >
                                        </SingleUpload>
                                    </FormGroup>







                                    <div className="modal-footer">
                                        <button id="btnguardar" type="submit"
                                            className="btn btn-danger btn-sm btn-control">{lblaction}
                                        </button>

                                        <a onClick={closeModal} className="btn btn-default btn-sm btn-control">Cerrar</a>
                                    </div>

                                </Form>

                            </div>


                        </div>
                        <div className="popup_align bloqueador3">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MPlantaciones;
import React from 'react'


const DocInternoDel = () => {
    return (
        <div>
            Delete Doc interno
        </div>
    )
}

export default DocInternoDel;

import PlantillaList from "../sigespred/_ddp_plantillaimpresion/PlantillaImpList";
import PlantillaEdit from "../sigespred/_ddp_plantillaimpresion/PlantillaImpEdit";

const RouteModPlantillaImpresion = [
    {path: "/printtemp-list", element: <PlantillaList/>},
    {path: "/printtemp-edit/:id?", element: <PlantillaEdit/>}
]

export default RouteModPlantillaImpresion;


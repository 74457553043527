export const list_estado_predio = {
  estados: [
    { "id": "DESIGNADO", "descripcion": "DESIGNADO" },
    { "id": "POR MEDIR", "descripcion": "POR MEDIR" },
    { "id": "MEDIDO", "descripcion": "MEDIDO" },
    { "id": "NEGATIVA", "descripcion": "NEGATIVA" },
    { "id": "LISTO PARA TASAR", "descripcion": "LISTO PARA TASAR" },
    { "id": "CON TASACION", "descripcion": "CON TASACION" }
  ]
};

export const list_tipo_tasacion = {
  estados: [
    { "id": "COMERCIAL", "descripcion": "COMERCIAL" },
    { "id": "REGLAMENTARIA", "descripcion": "REGLAMENTARIA" },

  ]
};

export const list_periodo_tipo_tasacion = {
  estados: [
    { "id": "ACTUAL", "descripcion": "ACTUAL" },
    { "id": "RETROESPECTIVA", "descripcion": "RETROESPECTIVA" },

  ]
};


export const list_zonas_registrales = {
  zonasRegistrales: [
    { "id": "Zona Registral N° I - Sede Piura", "descripcion": "Zona Registral N° I - Sede Piura" },
    { "id": "Zona Registral N° II - Sede Chiclayo", "descripcion": "Zona Registral N° II - Sede Chiclayo" },
    { "id": "Zona Registral N° III - Sede Moyobamba", "descripcion": "Zona Registral N° III - Sede Moyobamba" },
    { "id": "Zona Registral N° IV - Sede Iquitos", "descripcion": "Zona Registral N° IV - Sede Iquitos" },
    { "id": "Zona Registral N° V - Sede Trujillo", "descripcion": "Zona Registral N° V - Sede Trujillo" },
    { "id": "Zona Registral N° VI - Sede Pucallpa", "descripcion": "Zona Registral N° VI - Sede Pucallpa" },
    { "id": "Zona Registral N° VII - Sede Huaraz", "descripcion": "Zona Registral N° VII - Sede Huaraz" },
    { "id": "Zona Registral N° VIII - Sede Huancayo", "descripcion": "Zona Registral N° VIII - Sede Huancayo" },
    { "id": "Zona Registral N° IX - Sede Lima", "descripcion": "Zona Registral N° IX - Sede Lima" },
    { "id": "Zona Registral N° X - Sede Cusco", "descripcion": "Zona Registral N° X - Sede Cusco" },
    { "id": "Zona Registral N° XI - Sede Ica", "descripcion": "Zona Registral N° XI - Sede Ica" },
    { "id": "Zona Registral N° XII - Sede Arequipa", "descripcion": "Zona Registral N° XII - Sede Arequipa" },
    { "id": "Zona Registral N° XIII - Sede Tacna", "descripcion": "Zona Registral N° XIII - Sede Tacna" },
    { "id": "Zona Registral N° XIV - Sede Ayacucho", "descripcion": "Zona Registral N° XIV - Sede Ayacucho" }
  ]
};



export const list_uso_predio = {
  usos: [
    { "id": "VIVIENDA", "descripcion": "VIVIENDA" },
    { "id": "VIVIENDA-COMERCIO", "descripcion": "VIVIENDA-COMERCIO" },
    { "id": "COMERCIO", "descripcion": "COMERCIO" },
    { "id": "TERRENO", "descripcion": "TERRENO" },
    { "id": "ALMACEN", "descripcion": "ALMACEN" },
    { "id": "AGRÍCOLA", "descripcion": "AGRÍCOLA" },
    { "id": "ERIAZO", "descripcion": "ERIAZO" },
    { "id": "EXPANSIÓN URBANA", "descripcion": "EXPANSIÓN URBANA" },
    { "id": "ISLA RUSTICA", "descripcion": "ISLA RUSTICA" }
  ]
};

export const list_tipo_via = {
  tipos: [
    { "id": "AV", "descripcion": "AVENIDA" },
    { "id": "CARRETERA NACIONAL", "descripcion": "CARRETERA NACIONAL" },
    { "id": "PJE", "descripcion": "PASAJE" },
    { "id": "JR", "descripcion": "JIRÓN" },
    { "id": "AL", "descripcion": "ALAMEDA" }
  ]
};


export const list_lado = {
  lados: [
    { "id": "DERECHO", "descripcion": "DERECHO" },
    { "id": "IZQUIERDO", "descripcion": "IZQUIERDO" },
    { "id": "DERECHO/IZQUIERDO", "descripcion": "DERECHO/IZQUIERDO" }
  ]
};



export const list_datum = {
  datums: [
    { "id": "PSAD56", "descripcion": "PSAD56" },
    { "id": "WGS84_ZONA_18S", "descripcion": "WGS84 - ZONA 18S" },
    { "id": "WGS84", "descripcion": "WGS84" }
  ]
};

export const list_lado_poli = {
  lados: [
    { "id": "NORTE", "descripcion": "NORTE" },
    { "id": "ESTE", "descripcion": "ESTE" },
    { "id": "SUR", "descripcion": "SUR" },
    { "id": "OESTE", "descripcion": "OESTE" },
    { "id": "TOTAL", "descripcion": "TOTAL" },
    { "id": "FRENTE", "descripcion": "FRENTE" },
    { "id": "DERECHA", "descripcion": "DERECHA" },
    { "id": "IZQUIERDA", "descripcion": "IZQUIERDA" },
    { "id": "FONDO", "descripcion": "FONDO" }
  ]
};

export const list_uso_dt = {
  usos: [
    { "id": "AFECTADA", "descripcion": "AFECTADA" },
    { "id": "MATRIZ", "descripcion": "MATRIZ" },
    { "id": "REMANENTE", "descripcion": "REMANENTE" }
  ]
};


export const list_tipo_pre = {
  tipos: [
    { "id": "RURAL", "descripcion": "RURAL" },
    { "id": "URBANO", "descripcion": "URBANO" }
  ]
};

export const list_cond_rustico = {
  tipos: [
    { "id": "AGRICOLA", "descripcion": "AGRICOLA" },
    { "id": "ERIAZO", "descripcion": "ERIAZO" },
    { "id": "EXPANSION URBANA", "descripcion": "EXPANSION URBANA" },
    { "id": "ISLA RUSTICA", "descripcion": "ISLA RUSTICA" },
  ]
};

export const list_topografia = {
  topografias: [
    { "id": "PLANA", "descripcion": "PLANA" },
    { "id": "INCLINADA", "descripcion": "INCLINADA" },
    { "id": "LIGERAMENTE INCLINADA", "descripcion": "LIGERAMENTE INCLINADA" },
    { "id": "RELIEVE ACCIDENTADO Y PENDIENTES PRONUNCIADAS", "descripcion": "RELIEVE ACCIDENTADO Y PENDIENTES PRONUNCIADAS" }

  ]
};

export const list_dano = {
  danos: [
    { "id": "SI", "descripcion": "SI" },
    { "id": "NO", "descripcion": "NO" },

  ]
};

export const list_poligono = {
  poligonos: [
    { "id": "AREA_AFECTADA_1", "descripcion": "ÁREA AFECTADA N° 1" },
    { "id": "AREA_AFECTADA_2", "descripcion": "ÁREA AFECTADA N° 2" },
    { "id": "AREA_AFECTADA_3", "descripcion": "ÁREA AFECTADA N° 3" },
    { "id": "AREA_AFECTADA_4", "descripcion": "ÁREA AFECTADA N° 4" },
    { "id": "AREA_AFECTADA_5", "descripcion": "ÁREA AFECTADA N° 5" },
    { "id": "AREA_AFECTADA_6", "descripcion": "ÁREA AFECTADA N° 6" },
    { "id": "AREA_AFECTADA_6", "descripcion": "ÁREA AFECTADA N° 6" }
  ]
};

export const list_modulos = {
  poligonos: [
    { "id": "1", "descripcion": "1" },
    { "id": "2", "descripcion": "2" },
    { "id": "3", "descripcion": "3" },
    { "id": "4", "descripcion": "4" },
    { "id": "5", "descripcion": "5" },
    { "id": "6", "descripcion": "6" },
    { "id": "7", "descripcion": "7" },
    { "id": "8", "descripcion": "8" },
    { "id": "9", "descripcion": "9" },
    { "id": "10", "descripcion": "10" },
    { "id": "11", "descripcion": "11" },
    { "id": "12", "descripcion": "12" },
    { "id": "13", "descripcion": "13" },
    { "id": "14", "descripcion": "14" },
    { "id": "15", "descripcion": "15" },
    { "id": "16", "descripcion": "16" },
    { "id": "17", "descripcion": "17" },
    { "id": "18", "descripcion": "18" },
    { "id": "19", "descripcion": "19" },
    { "id": "20", "descripcion": "20" }
  ]

};


export const list_pisos = {
  poligonos: [

    { "id": "1", "descripcion": "1" },
    { "id": "2", "descripcion": "2" },
    { "id": "3", "descripcion": "3" },
    { "id": "4", "descripcion": "4" },
    { "id": "5", "descripcion": "5" },
    { "id": "6", "descripcion": "6" },
    { "id": "7", "descripcion": "7" },
    { "id": "8", "descripcion": "8" },
    { "id": "9", "descripcion": "9" },
    { "id": "10", "descripcion": "10" },
    { "id": "11", "descripcion": "11" },
    { "id": "12", "descripcion": "12" },
    { "id": "13", "descripcion": "13" },
    { "id": "14", "descripcion": "14" },
    { "id": "15", "descripcion": "15" },
    { "id": "16", "descripcion": "16" },
    { "id": "17", "descripcion": "17" },
    { "id": "18", "descripcion": "18" },
    { "id": "19", "descripcion": "19" },
    { "id": "20", "descripcion": "20" },
    { "id": "-2", "descripcion": "-2" },
    { "id": "-1", "descripcion": "-1" }
  ]

};

export const list_material = {
  poligonos: [
    { "id": "ACERO", "descripcion": "ACERO" },
    { "id": "ADOBE", "descripcion": "ADOBE" },
    { "id": "ALUMINIO", "descripcion": "ALUMINIO" },
    { "id": "ALUZINC", "descripcion": "ALUZINC" },
    { "id": "ASFALTO", "descripcion": "ASFALTO" },
    { "id": "CALAMINA", "descripcion": "CALAMINA" },
    { "id": "CALAMINA METÁLICA", "descripcion": "CALAMINA METÁLICA" },
    { "id": "CALAMINA PLASTIFICADA", "descripcion": "CALAMINA PLASTIFICADA" },
    { "id": "CALAMINA TIPO ETERNIT", "descripcion": "CALAMINA TIPO ETERNIT" },
    { "id": "COBRE", "descripcion": "COBRE" },
    { "id": "CONCRETO", "descripcion": "CONCRETO" },
    { "id": "DRYWALL", "descripcion": "DRYWALL" },
    { "id": "FIBRA DE VIDRIO", "descripcion": "FIBRA DE VIDRIO" },
    { "id": "FIERRO", "descripcion": "FIERRO" },
    { "id": "FIERRO LISO", "descripcion": "FIERRO LISO" },
    { "id": "GRANITO", "descripcion": "GRANITO" },
    { "id": "LADRILLO", "descripcion": "LADRILLO" },
    { "id": "LONA", "descripcion": "LONA" },
    { "id": "LOSA", "descripcion": "LOSA" },
    { "id": "MADERA", "descripcion": "MADERA" },
    { "id": "MATERIAL LIGERO", "descripcion": "MATERIAL LIGERO" },
    { "id": "PIEDRA", "descripcion": "PIEDRA" },
    { "id": "POLIETILENO", "descripcion": "POLIETILENO" },
    { "id": "POLIPROPILENO", "descripcion": "POLIPROPILENO" },
    { "id": "TRIPLAY", "descripcion": "TRIPLAY" }
  ]
}


export const list_estado_construccion = {
  poligonos:
    [
      { "id": "BUENO", "descripcion": "BUENO" },
      { "id": "REGULAR", "descripcion": "REGULAR" },
      { "id": "MALO", "descripcion": "MALO" },
      { "id": "MUY BUENO", "descripcion": "MUY BUENO" },
      { "id": "MUY MALO", "descripcion": "MUY MALO" }
    ]
}

export const list_estado_conservacion = {
  poligonos:
    [
      { "id": "BUENO", "descripcion": "BUENO" },
      { "id": "REGULAR", "descripcion": "REGULAR" },
      { "id": "MALO", "descripcion": "MALO" },
      { "id": "MUY BUENO", "descripcion": "MUY BUENO" },
      { "id": "MUY MALO", "descripcion": "MUY MALO" }
    ]
}


export const list_estado_construccion_vivienda = {
  poligonos:
    [
      { "id": "TERMINADO", "descripcion": "TERMINADO" },
      { "id": "INCONCLUSO", "descripcion": "INCONCLUSO" },
      { "id": "EN CONSTRUCCIÓN", "descripcion": "EN CONSTRUCCIÓN" }
    ]
}


export const list_unidades_medida = {
  poligonos:
    [
      { "id": "m", "descripcion": "m" },
      { "id": "m²", "descripcion": "m²" },
      { "id": "m³", "descripcion": "m³" },
      { "id": "UND", "descripcion": "UND" },
    ]
}


export const list_uso_oc = {
  poligonos:
    [
      { "id": "ALMACEN", "descripcion": "ALMACEN" },
      { "id": "ALTILLO", "descripcion": "ALTILLO" },
      { "id": "AZOTEA", "descripcion": "AZOTEA" },
      { "id": "BAÑO", "descripcion": "BAÑO" },
      { "id": "CANAL", "descripcion": "CANAL" },
      { "id": "CERCO FRONTAL", "descripcion": "CERCO FRONTAL" },
      { "id": "CERCO PERIMÉTRICO", "descripcion": "CERCO PERIMÉTRICO" },
      { "id": "COBERTIZO", "descripcion": "COBERTIZO" },
      { "id": "COBERTURA", "descripcion": "COBERTURA" },
      { "id": "COCHERA", "descripcion": "COCHERA" },
      { "id": "COLUMNA", "descripcion": "COLUMNA" },
      { "id": "CRIANZA DE AVES", "descripcion": "CRIANZA DE AVES" },
      { "id": "CUBIERTA", "descripcion": "CUBIERTA" },
      { "id": "DEPÓSITO", "descripcion": "DEPÓSITO" },
      { "id": "ESCALERA", "descripcion": "ESCALERA" },
      { "id": "HORNO", "descripcion": "HORNO" },
      { "id": "LAVADERO", "descripcion": "LAVADERO" },
      { "id": "LOSA", "descripcion": "LOSA" },
      { "id": "MESADA", "descripcion": "MESADA" },
      { "id": "MURO", "descripcion": "MURO" },
      { "id": "MURO DE CONTENCION", "descripcion": "MURO DE CONTENCION" },
      { "id": "PARAPETO", "descripcion": "PARAPETO" },
      { "id": "PARRILLA", "descripcion": "PARRILLA" },
      { "id": "PASADIZO", "descripcion": "PASADIZO" },
      { "id": "PATIO", "descripcion": "PATIO" },
      { "id": "PIRCA", "descripcion": "PIRCA" },
      { "id": "PISO", "descripcion": "PISO" },
      { "id": "PÓRTICO", "descripcion": "PÓRTICO" },
      { "id": "PORTÓN", "descripcion": "PORTÓN" },
      { "id": "POZA", "descripcion": "POZA" },
      { "id": "PROTECCIÓN", "descripcion": "PROTECCIÓN" },
      { "id": "RAMAL", "descripcion": "RAMAL" },
      { "id": "RAMPA", "descripcion": "RAMPA" },
      { "id": "REJA", "descripcion": "REJA" },
      { "id": "RESERVORIO", "descripcion": "RESERVORIO" },
      { "id": "SARDINEL", "descripcion": "SARDINEL" },
      { "id": "TANQUE ELEVADO", "descripcion": "TANQUE ELEVADO" },
      { "id": "TECHO", "descripcion": "TECHO" },
      { "id": "TENDAL", "descripcion": "TENDAL" },
      { "id": "VEREDA", "descripcion": "VEREDA" }
    ]
}

export const list_plant_frutales = {
  data:
    [
      { "id": "ALCANFOR", "nombre_comun_fo": "ALCANFOR", "Nombre_cientifico_fo": "CINNAMOMUM CAMPHORA", tipo: "FORESTALES" },
      { "id": "ALFALFA", "nombre_comun_fo": "ALFALFA", "Nombre_cientifico_fo": "MEDICAGO SATIVA", tipo: "FRUTALES" },
      { "id": "ALISOS", "nombre_comun_fo": "ALISOS", "Nombre_cientifico_fo": "ALNUS GLUTINOSA", tipo: "FORESTALES" },
      { "id": "CABUYA", "nombre_comun_fo": "CABUYA", "Nombre_cientifico_fo": "AGAVE", tipo: "FORESTALES" },
      { "id": "CABUYA BLANCA", "nombre_comun_fo": "CABUYA BLANCA", "Nombre_cientifico_fo": "FURCRAEA ANDINA", tipo: "FORESTALES" },
      { "id": "CAÑA DE AZÚCAR", "nombre_comun_fo": "CAÑA DE AZÚCAR", "Nombre_cientifico_fo": "SACCHARUM OFFICINARUM", tipo: "TRANSITORIO" },
      { "id": "CAPULI", "nombre_comun_fo": "CAPULI", "Nombre_cientifico_fo": "PRUNUS SALICIFOLIA", tipo: "FRUTALES" },
      { "id": "CEBADA", "nombre_comun_fo": "CEBADA", "Nombre_cientifico_fo": "HORDEUM VULGARE", tipo: "TRANSITORIO" },
      { "id": "CEBOLLA", "nombre_comun_fo": "CEBOLLA", "Nombre_cientifico_fo": "ALLIUM CEPA", tipo: "TRANSITORIO" },
      { "id": "CHIRIMOYA", "nombre_comun_fo": "CHIRIMOYA", "Nombre_cientifico_fo": "ANNONA CHERIMOLA", tipo: "FRUTALES" },
      { "id": "CIPRES", "nombre_comun_fo": "CIPRES", "Nombre_cientifico_fo": "CUPRESUSS", tipo: "FORESTALES" },
      { "id": "COL", "nombre_comun_fo": "COL", "Nombre_cientifico_fo": "BRASSICA OLERACEA VAR. CAPITTA", tipo: "TRANSITORIO" },
      { "id": "COLIFLOR", "nombre_comun_fo": "COLIFLOR", "Nombre_cientifico_fo": "BRASSICA OLEACEA VAR", tipo: "FORESTALES" },
      { "id": "DURAZNO", "nombre_comun_fo": "DURAZNO", "Nombre_cientifico_fo": "PRUNUS PERSICA", tipo: "FRUTALES" },
      { "id": "EUCALIPTO", "nombre_comun_fo": "EUCALIPTO", "Nombre_cientifico_fo": "EUCALYPTUS GLOBULUS", tipo: "FORESTALES" },
      { "id": "GRANADILLA", "nombre_comun_fo": "GRANADILLA", "Nombre_cientifico_fo": "PUNICA GRANATUM", tipo: "FRUTALES" },
      { "id": "HABA", "nombre_comun_fo": "HABA", "Nombre_cientifico_fo": "VICIA FABA", tipo: "TRANSITORIO" },
      { "id": "HUARANGO", "nombre_comun_fo": "HUARANGO", "Nombre_cientifico_fo": "PROSOPIS PALLIDA", tipo: "FORESTALES" },
      { "id": "JASSI", "nombre_comun_fo": "JASSI", "Nombre_cientifico_fo": "HAPLORHUS PERUVIANA ENGL.", tipo: "FORESTALES" },
      { "id": "LECHUGA", "nombre_comun_fo": "LECHUGA", "Nombre_cientifico_fo": "LACTUCA SATIVA", tipo: "FORESTALES" },
      { "id": "LIMON", "nombre_comun_fo": "LIMON", "Nombre_cientifico_fo": "CITRUS LIMON", tipo: "FRUTALES" },
      { "id": "LUCUMA", "nombre_comun_fo": "LUCUMA", "Nombre_cientifico_fo": "POUTERIA LÚCUMA", tipo: "FRUTALES" },
      { "id": "MAIZ", "nombre_comun_fo": "MAIZ", "Nombre_cientifico_fo": "ZEA MAYS", tipo: "TRANSITORIO" },
      { "id": "MANDARINA", "nombre_comun_fo": "MANDARINA", "Nombre_cientifico_fo": "CITRUS MATRICULATA", tipo: "FRUTALES" },
      { "id": "MANZANA", "nombre_comun_fo": "MANZANA", "Nombre_cientifico_fo": "PYRUS MALUS", tipo: "FRUTALES" },
      { "id": "MARACUYA", "nombre_comun_fo": "MARACUYA", "Nombre_cientifico_fo": "PASSIFLORA EDULIS SIMS", tipo: "FRUTALES" },
      { "id": "MEMBRILLO", "nombre_comun_fo": "MEMBRILLO", "Nombre_cientifico_fo": "CYDONIA OBLONGA", tipo: "FRUTALES" },
      { "id": "MOLLE", "nombre_comun_fo": "MOLLE", "Nombre_cientifico_fo": "SHINUS MOLLE", tipo: "FORESTALES" },
      { "id": "MORA", "nombre_comun_fo": "MORA", "Nombre_cientifico_fo": "RUBUS ULMIFOLIUS", tipo: "FRUTALES" },
      { "id": "NARANJA", "nombre_comun_fo": "NARANJA", "Nombre_cientifico_fo": "CITRUS SINENSIS", tipo: "FRUTALES" },
      { "id": "NISPERO", "nombre_comun_fo": "NISPERO", "Nombre_cientifico_fo": "ERIOBOTRYA JAPONICA", tipo: "FRUTALES" },
      { "id": "OCA", "nombre_comun_fo": "OCA", "Nombre_cientifico_fo": "OXALIS TUBEROSA", tipo: "TRANSITORIO" },
      { "id": "PACAY", "nombre_comun_fo": "PACAY", "Nombre_cientifico_fo": "INGA FEUILLEEI", tipo: "FRUTALES" },
      { "id": "PALTA", "nombre_comun_fo": "PALTA", "Nombre_cientifico_fo": "PERSEA AMERICANA", tipo: "FRUTALES" },
      { "id": "PAPA", "nombre_comun_fo": "PAPA", "Nombre_cientifico_fo": "SOLANUM TUBEROSUM", tipo: "TRANSITORIO" },
      { "id": "PAPAYA", "nombre_comun_fo": "PAPAYA", "Nombre_cientifico_fo": "CARICA PAPAYA", tipo: "FRUTALES" },
      { "id": "PATI", "nombre_comun_fo": "PATI", "Nombre_cientifico_fo": "CARICA AUGUSTI HARMS", tipo: "FORESTALES" },
      { "id": "PINO", "nombre_comun_fo": "PINO", "Nombre_cientifico_fo": "PINUS SP", tipo: "FORESTALES" },
      { "id": "PLATANO", "nombre_comun_fo": "PLATANO", "Nombre_cientifico_fo": "MUSA PARADISIACA", tipo: "FRUTALES" },
      { "id": "SABILA", "nombre_comun_fo": "SABILA", "Nombre_cientifico_fo": "ALOE VERA", tipo: "FRUTALES" },
      { "id": "SANKY", "nombre_comun_fo": "SANKY", "Nombre_cientifico_fo": "CORRYOCACTUS BREVISTYLUS", tipo: "FRUTALES" },
      { "id": "TANGELO", "nombre_comun_fo": "TANGELO", "Nombre_cientifico_fo": "CITRUS TANGELO", tipo: "FRUTALES" },
      { "id": "TARA", "nombre_comun_fo": "TARA", "Nombre_cientifico_fo": "CAESALPINIA SPINOSA", tipo: "TRANSITORIO" },
      { "id": "TARA", "nombre_comun_fo": "TARA", "Nombre_cientifico_fo": "CAESALPINIA  SPINOSA", tipo: "FORESTALES" },
      { "id": "TRIGO", "nombre_comun_fo": "TRIGO", "Nombre_cientifico_fo": "TRITICUM", tipo: "TRANSITORIO" },
      { "id": "TUNA", "nombre_comun_fo": "TUNA", "Nombre_cientifico_fo": "PUNTIA FICUS INDICA", tipo: "FRUTALES" },
      { "id": "ZAPALLO", "nombre_comun_fo": "ZAPALLO", "Nombre_cientifico_fo": "CURCUBITA MAXIMA", tipo: "TRANSITORIO" }
    ]
}

export const list_tipo_persona = {
  data: [
    { "id": "NATURAL", "descripcion": "NATURAL" },
    { "id": "JURIDICA", "descripcion": "JURIDICA" },

  ]
};

export const list_condicion_juridica = {
  data: [
    { "id": "PROPIETARIO", "descripcion": "PROPIETARIO" },
    { "id": "PROPIETARIO NO INSCRITO", "descripcion": "PROPIETARIO NO INSCRITO" },
    { "id": "POSESIONARIO MAYOR A 10 AÑOS (6.3)", "descripcion": "POSESIONARIO MAYOR A 10 AÑOS (6.3)" },
    { "id": "OCUPANTE", "descripcion": "OCUPANTE" },
    { "id": "PROPIEDAD DEL ESTADO PERUANO", "descripcion": "PROPIEDAD DEL ESTADO PERUANO" },
    { "id": "TRANSFERENCIA INTERESTATAL", "descripcion": "TRANSFERENCIA INTERESTATAL" },
    { "id": "POSESIONARIO MAYOR A 10 AÑOS (7.2)", "descripcion": "POSESIONARIO MAYOR A 10 AÑOS (7.2)" },
    { "id": "COMUNERO", "descripcion": "COMUNERO" },

  ]
};

export const list_doc_sust_titularidad = {
  data: [
    { "id": "CERTIFICADO DE FORMALIZACIÓN DE LA PROPIEDAD RURALOTORGADO POR COFOPRI", "descripcion": "CERTIFICADO DE FORMALIZACIÓN DE LA PROPIEDAD RURALOTORGADO POR COFOPRI" },
    { "id": "CONSTANCIA DE POSESIÓN", "descripcion": "CONSTANCIA DE POSESIÓN" },
    { "id": "CONSTANCIA DE COMUNERO HÁBIL", "descripcion": "CONSTANCIA DE COMUNERO HÁBIL" },
    { "id": "CONTRATO DE TRANSFERENCIA ", "descripcion": "CONTRATO DE TRANSFERENCIA " },
    { "id": "CONTRATO PRIVADO DE COMPRAVENTE DE ACCIONES Y DERECHOS", "descripcion": "CONTRATO PRIVADO DE COMPRAVENTE DE ACCIONES Y DERECHOS" },
    { "id": "DECLARACIÓN JURADA DE 6 VECINOS", "descripcion": "DECLARACIÓN JURADA DE 6 VECINOS" },
    { "id": "DOCUMENTOS CON FECHA CIERTA", "descripcion": "DOCUMENTOS CON FECHA CIERTA" },
    { "id": "PARTIDA REGISTRAL", "descripcion": "PARTIDA REGISTRAL" },
    { "id": "SOLICITUD DE CONSTANCIA DE POSESION", "descripcion": "SOLICITUD DE CONSTANCIA DE POSESION" },


  ]
};


export const list_entidad_titularidad = {
  data: [
    { "id": "Zona Registral N° I - Sede Piura", "descripcion": "Zona Registral N° I - Sede Piura" },
    { "id": "Zona Registral N° II - Sede Chiclayo", "descripcion": "Zona Registral N° II - Sede Chiclayo" },
    { "id": "Zona Registral N° III - Sede Moyobamba", "descripcion": "Zona Registral N° III - Sede Moyobamba" },
    { "id": "Zona Registral N° IV - Sede Iquitos", "descripcion": "Zona Registral N° IV - Sede Iquitos" },
    { "id": "Zona Registral N° V - Sede Trujillo", "descripcion": "Zona Registral N° V - Sede Trujillo" },
    { "id": "Zona Registral N° VI - Sede Pucallpa", "descripcion": "Zona Registral N° VI - Sede Pucallpa" },
    { "id": "Zona Registral N° VII - Sede Huaraz", "descripcion": "Zona Registral N° VII - Sede Huaraz" },
    { "id": "Zona Registral N° VIII - Sede Huancayo", "descripcion": "Zona Registral N° VIII - Sede Huancayo" },
    { "id": "Zona Registral N° IX - Sede Lima", "descripcion": "Zona Registral N° IX - Sede Lima" },
    { "id": "Zona Registral N° X - Sede Cusco", "descripcion": "Zona Registral N° X - Sede Cusco" },
    { "id": "Zona Registral N° XI - Sede Ica", "descripcion": "Zona Registral N° XI - Sede Ica" },
    { "id": "Zona Registral N° XII - Sede Arequipa", "descripcion": "Zona Registral N° XII - Sede Arequipa" },
    { "id": "Zona Registral N° XIII - Sede Tacna", "descripcion": "Zona Registral N° XIII - Sede Tacna" },
    { "id": "Zona Registral N° XIV - Sede Ayacucho", "descripcion": "Zona Registral N° XIV - Sede Ayacucho" },
    { "id": "Gobierno regional", "descripcion": "Gobierno regional" },
    { "id": "Gobierno municipal", "descripcion": "Gobierno municipal" },
    { "id": "Otros", "descripcion": "Otros" },

  ]
};



export const list_und_doc_titularidad = {
  data: [
    { "id": "m2", "descripcion": "m2" },
    { "id": "ha", "descripcion": "ha" },
  ]
};




export const list_lucro_cesante = {
  danos: [
    { "id": "SI", "descripcion": "SI" },
    { "id": "NO", "descripcion": "NO" },

  ]
};



export const list_servidumbres = {
  danos: [
    { "id": "Ninguno", "descripcion": "Ninguno" },
    { "id": "Servidumbre de Paso", "descripcion": "Servidumbre de Paso" },

    { "id": "Servidumbres de Paso de Líneas Eléctricas, Aéreas o Enterradas. De alta Tensión", "descripcion": "Servidumbres de Paso de Líneas Eléctricas, Aéreas o Enterradas. De alta Tensión" },
    { "id": "Servidumbre de aguas", "descripcion": "Servidumbre de aguas" },
    { "id": "Servidumbre de desagüe", "descripcion": "Servidumbre de desagüe" },
    { "id": "Servidumbre de acueducto", "descripcion": "Servidumbre de acueducto" },
    { "id": "Servidumbres de Costas y Ríos", "descripcion": "Servidumbres de Costas y Ríos" },
    { "id": "Servidumbre de luces y vistas", "descripcion": "Servidumbre de luces y vistas" },
    { "id": "Servidumbre de medianería", "descripcion": "Servidumbre de medianería" },
    { "id": "Servidumbres Aeronáuticas", "descripcion": "Servidumbres Aeronáuticas" },
    { "id": "Servidumbres de Carreteras y Ferrocarriles", "descripcion": "Servidumbres de Carreteras y Ferrocarriles" },
    { "id": "Servidumbre de balcón", "descripcion": "Servidumbre de balcón" },
    { "id": "Servidumbre de palco o butaca", "descripcion": "Servidumbre de palco o butaca" },
    { "id": "Servidumbres de saca de leña", "descripcion": "Servidumbres de saca de leña" },
    { "id": "Servidumbre de comunidad de pastos", "descripcion": "Servidumbre de comunidad de pastos" }
  ]
};

export const list_forma_afectacion = {
  danos: [
    { "id": "NIGUNO", "descripcion": "NIGUNO" },
    { "id": "PARCIAL", "descripcion": "PARCIAL" },
    { "id": "TOTAL", "descripcion": "TOTAL" },

  ]
};

export const list_unidad_tiempo = {
  danos: [
    { "id": "MESES", "descripcion": "MESES" },
    { "id": "DIAS", "descripcion": "DIAS" },
    { "id": "SEMANAS", "descripcion": "SEMANAS" },

    { "id": "AÑOS", "descripcion": "AÑOS" },

  ]
};

export const list_cond_negocio = {
  danos: [
    { "id": "PERSONA NATURAL", "descripcion": "PERSONA NATURAL" },
    { "id": "PERSONA NATURAL C/ NEGOCIO", "descripcion": "PERSONA NATURAL C/ NEGOCIO" },
    { "id": "PERSONA JURIDICA", "descripcion": "PERSONA JURIDICA" },

  ]
};

export const list_regimen = {
  danos: [
    { "id": "ESPECIAL", "descripcion": "ESPECIAL" },
    { "id": "SIMPLIFICADO", "descripcion": "SIMPLIFICADO" },
    { "id": "GENERAL", "descripcion": "GENERAL" },

  ]
};

export const list_tipo_negocio = {
  danos: [
    { "id": "SOCIEDAD ANÓNIMA ABIERTA ( S.A.A. ) ", "descripcion": "SOCIEDAD ANÓNIMA ABIERTA ( S.A.A. ) " },
    { "id": "SOCIEDAD ANÓNIMA CERRADA ( S.A.C. )", "descripcion": "SOCIEDAD ANÓNIMA CERRADA ( S.A.C. )" },
    { "id": "SOCIEDAD ANÓNIMA ( S.A. )", "descripcion": "SOCIEDAD ANÓNIMA ( S.A. )" },
    { "id": "SOCIEDAD INDIVIDUAL DE RESPONSABILIDAD LIMITADA ( E.I.R.L. )", "descripcion": "SOCIEDAD INDIVIDUAL DE RESPONSABILIDAD LIMITADA ( E.I.R.L. )" },
    { "id": "SOCIEDAD COMERCIAL DE RESPONSABILIDAD LIMITADA ( S.R.L. )", "descripcion": "SOCIEDAD COMERCIAL DE RESPONSABILIDAD LIMITADA ( S.R.L. )" },

  ]
};

export const list_dl_actividad = {
  danos: [
    { "id": "INDUSTRIAL", "descripcion": "INDUSTRIAL" },
    { "id": "COMERCIAL", "descripcion": "COMERCIAL" },
    { "id": "SERVICIO", "descripcion": "SERVICIO" },
    { "id": "PUBLICAS", "descripcion": "PUBLICAS" },
    { "id": "PRIVADAS", "descripcion": "PRIVADAS" },


  ]
};

export const list_dl_tip_establecimiento = {
  danos: [
    { "id": "DOMICILIO FISCAL", "descripcion": "DOMICILIO FISCAL" },
    { "id": "SEDE PRODUCTIVA", "descripcion": "SEDE PRODUCTIVA" },
    { "id": "SUCURSAL", "descripcion": "SUCURSAL" },
    { "id": "DEPOSITO", "descripcion": "DEPOSITO" },
    { "id": "LOCAL COMERCIAL", "descripcion": "LOCAL COMERCIAL" },
    { "id": "OFICINA ADMINISTRA", "descripcion": "OFICINA ADMINISTRA" },


  ]
};




export const list_estado_civil = {
  data: [{ id: "SOLTERO", "descripcion": "SOLTERO" }, { id: "CASADO", "descripcion": "CASADO" }, { id: "VIUDO", "descripcion": "VIUDO" }, { id: "DIVORCIADO", "descripcion": "DIVORCIADO" }]
};



export const generarListaMeses = function () {

  const meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO",
    "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
  let listaMeses = [];
  const añoActual = new Date().getFullYear();
  // Comenzar desde junio del año actual
  for (let i = 0; i < meses.length; i++) {
    listaMeses.push({ id: `${meses[i]} ${añoActual - 1}`, descripcion: `${meses[i]} ${añoActual - 1}` });
  }

  // Comenzar desde junio del año actual
  for (let i = 0; i < meses.length; i++) {
    listaMeses.push({ id: `${meses[i]} ${añoActual}`, descripcion: `${meses[i]} ${añoActual}` });
  }

  // Continuar hasta mayo del año siguiente
  for (let i = 0; i < meses.length; i++) {
    listaMeses.push({ id: `${meses[i]} ${añoActual + 1}`, descripcion: `${meses[i]} ${añoActual + 1}` });
  }

  return { listaMeses };
}


export function isObjectValid (obj) {
  // Verificar si es null o undefined primero
  if (obj == null) {
    return true;
  }

  // Clonar el objeto para no modificar el original
  const clonedObj = { ...obj };
  delete clonedObj.predioid;

  // Verificar si el objeto clonado está vacío
  if (typeof clonedObj === 'object' && Object.keys(clonedObj).length === 0) {
    return true;
  }

  // Si no cumple con ninguna de las condiciones anteriores, retornar false
  return false;
}









